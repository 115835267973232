const protocolMixin = {
	data() {
		return {
			statusToSign: ['1', '4'],
			eicTypes: ['pokyn', 'ziadost'],
		}
	},
	computed: {
		protocolType() {
			if (this.eicTypes.includes(this.data.typ_dokumentu_id)) {
				return 'eic';
			}

			return 'protocol';
		}
	}

};

export default protocolMixin;