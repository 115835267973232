<template>
	<div class="partner-box">
		<router-link :to="`${$t('routes.partner.path')}/${data.code}`">
			<figure class="partner-logo fit-image contain">
				<img :src="getImage(data.logo.filename)">
			</figure>
		</router-link>
	</div>
</template>

<script>
import PageFunctionsMixin from "@/mixins/pageFunctionsMixin";

export default {
	name: "PartnerBox",
	props: ['data'],
	mixins: [PageFunctionsMixin]

}
</script>

<style scoped lang="scss">
.light-mode {
	.partner-box {
		background: #F0F0F2;
		img {
			filter: brightness(50%);
		}
	}
}

.partner-box {
	background: rgba(var(--white-color-rgb), .06);
	border-radius: 5px;
	position: relative;

	> a {
		display: block;
		padding: 10%;
		@media($tabletL){
			padding: 5%;
		}
		@media($mobileM){
			padding: 0;
		}
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: -6px;
		right: -6px;
		bottom: -6px;
		left: -6px;
		pointer-events: none;
		border: 2px solid var(--secondary-color, #f9a03a);
		border-radius: 8px;
		opacity: 0;
		transition: 0.3s all;
	}
	&:hover {
		&:before {
			opacity: 1;
		}
	}
}

.partner-logo {
	position: relative;
	max-width: 250px;
	margin: 0 auto;

	&:before {
		content: '';
		width: 100%;
		padding-bottom: 50%;
	}
}
</style>