<template>
	<div class="online-service-detail-box">
		<h1 class="online-service-detail-title">{{ data.online_service.name }}</h1>
		<figure class="featured-image" v-if="data.online_service.featured_image">
			<img :src="getImage(data.online_service.featured_image.filename)">
		</figure>
		<article class="online-service-detail-content" v-if="data.online_service.content"
		         v-html="data.online_service.content"></article>
		<dynamic-form :form="form" :store-url="storeUrl" :validation-url="validationUrl" v-if="form"
		              @submitted="handleResponse"/>
	</div>
</template>

<script>
import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
import DynamicForm from "@/components/forms/DynamicForm";

export default {
	name: "OnlineServiceDetailBox",
	props: ['data'],
	mixins: [pageFunctionsMixin],
	components: {
		DynamicForm
	},
	data() {
		return {
			form: this.data.form,
			storeUrl: `/api/online-services/${this.$route.params.slug}`,
			validationUrl: `/api/online-services/validate-step/${this.$route.params.slug}`
		}
	},
	methods: {
		handleResponse(data) {
			if (!data.form) {
				this.$swal.fire({
					icon: 'success',
					title: this.$t('general.thank_you'),
					text: this.$t('general.contact_soon')
				});

				if (data.redirect) {
					this.$router.push(data.redirect);
					return;
				}

				return;
			}

			this.form = data.form;
			this.storeUrl = data.store_url;
			this.validationUrl = data.validation_url;
		}
	},
}
</script>

<style lang="scss">
.light-mode {
	.online-service-detail-box {
		.online-service-detail-content {
			ol, ul, p {
				color: var(--secondary-font-color, $secondaryFontColor);
			}
		}
	}
}

.online-service-detail-box {
	.user-box,
	.online-service-detail-content,
	.online-service-detail-title,
	.date-category,
	.dynamic-form,
	.featured-image,
	.actions {
		max-width: 820px;
		margin-left: auto;
		margin-right: auto;
		//padding: 0 30px;
	}

	.online-service-detail-title {
		font-size: 46px;
		font-weight: 600;
	}

	iframe {
		width: 100%;
		max-width: 100%;
	}

	.date-category {
		margin-bottom: 50px;

		.date {
			margin-right: 40px;
			font-size: 14px;

			&:last-child {
				margin-right: 0;
			}
		}

		.category {
			font-weight: 600;
			text-transform: uppercase;

		}
	}

	.featured-image {
		margin-bottom: 35px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.online-service-detail-content {
		margin-bottom: 50px;

		&:last-child {
			margin-bottom: 0;
		}

		ol, ul, p {
			color: var(--white-color, $whiteColor);
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.actions {
		margin-top: 50px;
		display: flex;
		justify-content: space-between;

		.button {
			max-width: 320px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			&.next,
			&.submit {
				margin-left: auto;
			}
			&.full-size {
				max-width: 100%;
			}
		}
	}

	@media($tabletL) {
		.online-service-detail-title {
			font-size: 36px;
		}
		.featured-image {
			margin-left: -15px;
			margin-right: -15px;
			width: calc(100% + 30px);
		}
		.actions {
			.button {
				max-width: calc(50% - 10px);
			}
		}
	}
	@media($mobileM) {
		padding-bottom: 25px;
		.online-service-detail-title {
			font-size: 30px;
		}
		.date-category {
			margin-bottom: 35px;
		}
		.featured-image {
			margin-bottom: 35px;
		}
		.actions {
			flex-direction: column;

			.button {
				max-width: 100%;
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}


</style>