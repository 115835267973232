<template>
	<div class="change-password-field">
		<div class="form-row" v-if="!showFields">
			<button class="button style-1" @click.prevent="showFields = true">
				<span class="inner">{{ $t('forms.change_password') }}</span>
			</button>
		</div>
		<div class="form-row two-columns" v-else>
			<default-field-box type="password" name="password" :placeholder="$t('forms.password')"
			                   autocomplete="off" :required="true"/>
			<default-field-box type="password" name="password_confirmation" :placeholder="$t('forms.password_confirmation')"
			                   autocomplete="off" :required="true"/>
		</div>
	</div>
</template>

<script>
	import DefaultFieldBox from "./DefaultFieldBox";
	export default {
		name: "ChangePasswordField",
		components: {
			DefaultFieldBox
		},
		data() {
			return {
				showFields: false
			}
		},
	}
</script>

<style scoped>
	.button {
		width: 100%;
	}
</style>