<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<article>
					<online-service-detail-box :data="onlineServiceData"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import OnlineServiceDetailBox from "@/components/containers/OnlineServiceDetailBox";

export default {
	name: "OnlineServiceDetail",
	components: {
		OnlineServiceDetailBox,
	},
	data() {
		return {
			onlineServiceData: this.$store.state.views.onlineServiceDetail
		}
	},
}
</script>

<style scoped lang="scss">
.section-header {
	margin-bottom: 50px;
}

.dynamic-form {
	max-width: 820px;
	margin: 0 auto;
}
</style>