<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<article>
					<contact-detail-box :data="contactDetailData"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
	import ContactDetailBox from "../components/containers/ContactDetailBox";
	import PageFunctionsMixin from "@/mixins/pageFunctionsMixin";

	export default {
		name: "PartnerDetail",
		mixins: [PageFunctionsMixin],
		components: {
			ContactDetailBox,
		},
		data() {
			return {
				partner: false,
			}
		},
		computed: {
			valueBoxes() {
				let data = [];

				this.partner.info.forEach(item => {
					let target;
					let icon;
					let valueOverflow;
					let url;
					let value;

					switch (item.type){
						case 'email':
							icon = '<i class="fa-thin fa-envelope"></i>';
							url = `mailto:${item.value}`;
							value = item.value;
							break;
						case 'phone':
							icon = '<i class="fa-thin fa-phone"></i>';
							url = `tel:${item.value}`;
							value = item.value;
							break;
						case 'link':
							icon = '<i class="fa-thin fa-headset"></i>';
							url = item.value;
							value =  this.$t('contacts.visit_link')
							target = '_blank';
							valueOverflow = true;
							break;
					}

					data.push({
						icon: '<i class="fa-thin fa-headset"></i>',
						title: item.description,
						value: value,
						valueOverflow,
						url: url,
						target,
					});
				});

				return data;
			},
			contactDetailData() {
				let data = {
					title: this.partner.name,
					logo: this.getImage(this.partner.logo.filename),
					valueBoxes: this.valueBoxes,
					type: 'partner',
				};

				return data;
			}
		},

		created() {
			this.partner = this.$store.state.views.partners.find(item => {
				return item.code === this.$route.params.id;
			});
		}

	}
</script>

<style scoped>

</style>