<template>
    <button type="button" class="button style-1" :class="syncLoading ? 'loading' : ''" 
        @click.prevent="synchronizeIntranetId" v-if="!intranetId">
        <span class="inner">{{ $t('general.intranet_id_sync') }}</span>
    </button>
    <p v-else>{{ $t('general.intranet_id_synced')}}</p>
</template>

<script>
	export default {
		name: "SyncIntranetIdBox",
		data() {
            return {
                syncLoading: false,
            }
		},
        computed: {
            intranetId(){
                return this.$store.state.auth.user.intranet_id;
            }
        },
		methods: {
			synchronizeIntranetId(){
                if(this.syncLoading){
                    return;
                }

                this.syncLoading = true;
                this.axios.get('/api/user/synchronize/intranet_id').then((response) => {
                    this.syncLoading = false;

                    if(this.$store.state.app.native){
                        localStorage.setItem('syncIntranetId', true);
                    }

                    this.$store.commit('SET_INTRANET_ID', response.data.intranet_id);

                    this.$swal.fire({
                        icon: 'success',
                        title: this.$t('general.intranet_id_sync_success_title'),
                        text: this.$t('general.intranet_id_sync_success_text'),
                    }).then(() => {
                        window.location.reload();
                    });
                }).catch((error) => {
                    console.log(error);
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Oops',
                        text: error.response.data.message
                    });

                    this.syncLoading = false;
                });
            }
		},
	}
</script>

<style scoped lang="scss">
.light-mode {
    p {
        color: var(--background-color, $backgroundColor);
        background: #e2e3e6;
    }
}
p {
    color: var(--white-color);
    text-align: center;
    padding: 15px 35px;
    border: 2px solid transparent;
    background: transparentize($whiteColor, .94);
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    border-radius: 5px;
    line-height: 1;
}
</style>