<template>
    <div class="investment-info-boxes-container">
        <investment-info-box v-for="(item, index) in data" :key="index" :data="item"/>
    </div>
</template>

<script>
import InvestmentInfoBox from './InvestmentInfoBox';

export default {
    name: 'InvestmentBoxesContainer',
    props: ['data'],
    components: {
        InvestmentInfoBox
    }

}
</script>

<style lang="scss">
.investment-info-boxes-container {
    .investment-info-box {
        margin-bottom: 15px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>