<template>
	<section class="download-section">
		<article>
			<div class="content-wrap">
				<div class="container">
					<div class="text">
						<h3 class="public-pre-section-title">{{ $t('sections.download.pre_title') }}</h3>
						<h2 class="public-section-title" v-html="$t('sections.download.title')"></h2>
						<p v-html="$t('sections.download.text')"></p>
						<store-buttons-box/>
					</div>
					<figure class="image">
						<img :src="getLocalAsset(`img/download-section/hand-${$store.state.app.appType}.png`)">
					</figure>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	import StoreButtonsBox from "../containers/StoreButtonsBox";

	export default {
		name: "DownloadSection",
		mixins: [pageFunctionsMixin],
		components: {
			StoreButtonsBox
		}
	}
</script>

<style scoped lang="scss">
	.download-section {
		padding: 130px 0;
		background: var(--background-color, $backgroundColor);;
		position: relative;
	}

	.content-wrap {
		position: relative;
	}

	.image {
		position: absolute;
		right: 0;
		bottom: -130px;
		max-width: 50%;

		img {
			max-height: 750px;
		}
	}

	.text {
		width: 60%;
		max-width: 610px;

		p {
			font-size: 18px;
			max-width: 450px;
		}
	}

	.public-section-title {
		color: $whiteColor;
	}

	.store-buttons-box {
		margin-top: 50px;
	}

	.image-button {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 15px 25px;
		border-radius: 10px;
		box-shadow: 0 10px 20px transparentize($blackColor, .84);

		img {
			height: 35px;
		}

		&.apple-store {
			background: $blackColor;
		}

		&.google-play {
			background: $whiteColor;
		}

		&:hover {
			box-shadow: 0 10px 20px transparentize($blackColor, .7);
		}
	}

	@media($tabletM) {
		.download-section {
			padding-top: 50px;
			padding-bottom: 0;
		}
		.text {
			margin-bottom: 50px;
			width: 100%;
		}
		.container {
			flex-direction: column;
		}
		.image {
			position: relative;
			bottom: 0;
			text-align: center;
			margin: 0 auto;
		}
	}

	@media($mobileM) {
		.actions {
			flex-direction: column;
			margin-top: 15px;
			max-width: 310px;

			.image-button {
				margin-right: 0;
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
</style>