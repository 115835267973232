<template>
	<form class="services-options-form">
		<field-box name="client_id" type="select" :options="clientOptions" v-if="clientOptions.length"/>
	</form>
</template>

<script>
	import FieldBox from "../form-fields/FieldBox";

	export default {
		name: "ServicesOptionsForm",
		components: {
			FieldBox
		},
		data() {
			return {
				vehicle_id: '',
				client_id: '',
			}
		},
		computed: {
			carsOptions() {
				let options = [];
				if (!this.$store.state.auth.user || !this.$store.state.auth.user.cars || !this.$store.state.auth.user.cars.length) {
					return false;
				}

				let cars = this.$store.state.auth.user.cars;
				options.push({
					value: '',
					title: this.$t('online_services.choose_vehicle')
				});

				cars.forEach(item => {
					options.push({
						value: item.id,
						title: item.name
					});
				})

				return options;
			},
			clientOptions() {
				let options = [];
				if (!this.$store.state.auth.user || !this.$store.state.auth.user.relations || !this.$store.state.auth.user.relations.length) {
					return false;
				}

				let relations = this.$store.state.auth.user.relations;
				options.push({
					value: '',
					title: this.$t('online_services.choose_client')
				});

				if (this.$store.state.auth.user.intranet_id){
					options.push({
						value: this.$store.state.auth.user.intranet_id,
						title: this.$store.state.auth.user.full_name
					});
				}

				relations.forEach(item => {
					let name = [];
					if (item.first_name) {
						name.push(item.first_name);
					}
					if (item.last_name) {
						name.push(item.last_name);
					}
					name = name.join(' ');
					options.push({
						value: item.id,
						title: name,
					});
				});

				return options;
			},
		},
		methods: {
			processFieldChange(data) {
				switch (data.name) {
					case 'vehicle_id':
						this.vehicle_id = data.value;
						break;
					case 'client_id':
						this.client_id = data.value;
						break;
				}

				this.regenerateServices();
			},

			regenerateServices() {
				let data = {};
				if (this.vehicle_id) {
					data.vehicle_id = this.vehicle_id;
				}
				if (this.client_id) {
					data.client_id = this.client_id;
				}

				this.$store.commit('SET_APP_LOADING');
				this.axios.post('/api/services', data).then(response => {
					this.$store.dispatch('setServicesData', response.data.services);
					this.$store.commit('UNSET_APP_LOADING');
				}).catch((error) => {
					this.$store.commit('UNSET_APP_LOADING');
				})
			}
		},
		mounted() {
			this.$root.$on('field-changed', (data) => {
				this.processFieldChange(data);
			});
		},
		destroyed() {
			this.$root.$off('field-changed');
		}
	}
</script>

<style scoped lang="scss">
	.services-options-form {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}

		.field-box {
			width: calc(50% - 2px);
		}
	}
</style>