<template>
	<form class="user-deletion-form" @submit.prevent="submitForm">
		<errors-list :errors="errors"/>
		<default-field-box type="text" name="activation_code" :placeholder="$t('forms.activation_code')"
		                   v-model="deletionCode" event="deletion-code-changed" :required="true"/>
		<div class="form-actions">
			<button class="button style-4" :class="deleteUserLoading ? 'loading' : ''">
				<span class="inner">{{ $t('auth.delete_account') }}</span>
			</button>
			<button class="button style-1" :class="sendDeletionCodeLoading ? 'loading' : ''"
			        @click.prevent="sendDeletionCode">
				<span class="inner">{{ $t('auth.send_code_again') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";
	import ErrorsList from "../containers/ErrorsList";

	export default {
		name: "UserDeletionForm",
		components: {
			DefaultFieldBox,
			ErrorsList
		},
		data(){
			return {
				deletionCode: '',
				errors: false,
				deleteUserLoading: false,
				sendDeletionCodeLoading: false,
			}
		},
		methods: {
			submitForm(){
				if (!this.deletionCode) {
					return false;
				}

				this.deleteUserLoading = true;
				this.axios.post('/api/user', {
					_method: 'DELETE',
					deletion_code: this.deletionCode,
				}).then(() => {
					if (this.$store.state.app.native){
						this.$store.dispatch('removeDefaultUser');
					}
			
					this.$store.dispatch('processLogOut');
				}).catch((error) => {
					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					} else {
						if (error.response.data.message) {
							this.errors = {error: [error.response.data.message]};
						}
					}

					this.deleteUserLoading = false;
				});
			},
			sendDeletionCode(e, showErrorMessage = true){
				this.sendDeletionCodeLoading = true;
				this.axios.get('/api/user/send-deletion-code').then((response) => {
					this.sendDeletionCodeLoading = false;
				}).catch((error) => {
					this.sendDeletionCodeLoading = false;

					if (!showErrorMessage) {
						return false;
					}

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}
				});
			},
			processDeletionCodeChanged(data) {
				this.deletionCode = data.value;
			}
		},
		mounted() {
			this.$root.$on('deletion-code-changed', (data) => {
				this.processDeletionCodeChanged(data);
			});
			this.sendDeletionCode(null, false);
		},
		destroyed() {
			this.$root.$off('deletion-code-changed');
		}
	}
</script>

<style scoped lang="scss">
	.button {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
</style>