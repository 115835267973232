<template>
	<form class="portfolio-form" enctype="multipart/form-data" @submit.prevent>
		<div class="icon">
			<i class="fa-thin fa-chart-line"></i>
		</div>
		<div class="form-headline">
			<h3 class="form-title">{{ data.name }}</h3>
		</div>
		<div class="form-row" :class="data.shortcut ? 'two-columns' : ''">
			<field-box :label="$t('investments.shortcut')" type="text" :value="data.shortcut" :disabled="true" v-if="data.shortcut"/>
			<field-box :label="$t('investments.isin')" type="text" :value="data.isin" :disabled="true"/>
		</div>
		<div class="form-row two-columns">
			<field-box :label="$t('investments.amount')" type="text" :value="data.amount" :disabled="true"/>
			<field-box :label="$t('investments.average_rate')" type="text" :value="data.averageRate" :disabled="true"/>
		</div>
		<div class="form-row two-columns">
			<field-box :label="$t('investments.rate')" type="text" :value="data.rate" :disabled="true"/>
			<field-box :label="$t('investments.rate_date')" type="text" :value="data.rateDate" :disabled="true"/>
		</div>
		<div class="form-row two-columns">
			<field-box :label="$t('investments.total_amount')" type="text" :value="data.totalAmount" :disabled="true"/>
			<field-box :label="$t('investments.return')" type="text" :value="data.totalReturn" :disabled="true"/>
		</div>
		<div class="form-row two-columns">
			<field-box :label="$t('investments.last_purchase')" type="text" :value="data.lastPurchase" :disabled="true"/>
		</div>
		<div class="form-actions">
			<button class="button style-1" type="button" @click="$store.commit('HIDE_PORTFOLIO_DETAIL')">
				<span class="inner">{{ $t('forms.cancel') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import FieldBox from "../form-fields/FieldBox";

	export default {
		name: "PortfolioForm",
		props: ['data'],
		components: {
			FieldBox,
		},
		data() {
			return {

			}
		},
		computed: {
			currency(){
				let currency;
				switch (this.data.currencyVAL){
					case 'EUR':
						currency = '€';
						break;
					case 'USD':
						currency = '$';
						break;
				}

				return currency;
			}
		},
	}
</script>

<style scoped lang="scss">
	.portfolio-form {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.form-group {
		label {
			font-size: 12px;
			text-align: center;
			text-transform: uppercase;
			font-weight: 600;
		}
	}

	.form-headline {
		margin-bottom: 25px;
		text-align: center;
	}

	.form-actions {
		margin-top: auto;
	}

	.icon {
		text-align: center;
		font-size: 21px;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-row {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		&.two-columns {
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			.field-box {
				width: calc(50% - 8px);
			}
		}
	}
</style>