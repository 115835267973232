import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import VueQRCodeComponent from 'vue-qrcode-component'
import i18n from './i18n'
import jQuery from 'jquery';

window.$ = window.jQuery = jQuery;

Vue.config.productionTip = false;
Vue.component('qr-code', VueQRCodeComponent)

new Vue({
	router,
	store,
	i18n,
	render: h => h(App)
}).$mount('#app');