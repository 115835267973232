<template>
	<div class="field-box" @click="focusField" :class="classes">
		<label :for="fieldName" v-if="label">{{ label }}</label>
		<select :name="name" v-if="type === 'select'" :id="fieldName" ref="field" :disabled="disabled"
		        @change="valueChanged">
			<option v-for="(option, index) in options" :key="index" :value="option.value" :selected="option.selected">{{
				option.title }}
			</option>
		</select>
		<input :type="inputType" :name="name" :id="fieldName" :value="formattedValue" :disabled="disabled" :readonly="readonly"
		       @keyup="valueChanged" v-else ref="field">
	</div>
</template>

<script>
	import Choices from 'choices.js';
	import flatpickr from 'flatpickr';
	import {Slovak} from "flatpickr/dist/l10n/sk";

	export default {
		name: "FieldBox",
		props: ['label', 'name', 'type', 'options', 'value', 'prefix', 'disabled', 'readonly', 'dateFormat', 'event', 'extraClass'],
		data() {
			return {

			}
		},
		computed: {
			classes(){
				let classes = [];
				if (this.disabled){
					classes.push('disabled');
				}

				if (this.extraClass){
					classes.push(this.extraClass);
				}

				return classes.join(' ');
			},
			inputType() {
				let type = this.type;
				if (type === 'date') {
					type = 'text';
				}

				return type;
			},
			formattedValue() {
				if (this.value === undefined || this.value === null) {
					return '';
				}

				if (this.type === 'date' && this.dateFormat !== 'd.m') {
					return this.$moment(this.value).format('D.M.YYYY');
				}

				return this.value;
			},
			fieldName() {
				let name = `${this.name}-field`;
				if (this.prefix) {
					name = `${this.prefix}-${name}`;
				}

				return name
			},
		},
		methods: {
			valueChanged() {
				let data = {
					name: this.name,
					value: this.$refs.field.value
				};

				if (this.type === 'select') {
					let option = this.options.find(item => item.value === this.$refs.field.value);
					if (option) {
						data.title = option.title;
					}
				}

				if (this.event) {
					this.$root.$emit(this.event, data);
					return;
				}

				this.$root.$emit('field-changed', data);
			},
			focusField() {
				this.$refs.field.focus();
			},
			initSelect() {
				new Choices(this.$refs.field, {
					searchEnabled: false,
					itemSelectText: '',
					shouldSort: false,

				});
			},
			initDate() {
				let dateFormat = 'd.m.Y';
				if (this.dateFormat) {
					dateFormat = this.dateFormat;
				}

				flatpickr(this.$refs.field, {
					'locale': Slovak,
					disableMobile: true,
					dateFormat,
					onReady(_, __, fp) {
						if (dateFormat === 'd.m') {
							fp.calendarContainer.classList.add('hide-year');
						}
					}
				});
			},
		},
		mounted() {
			switch (this.type) {
				case 'select':
					this.initSelect();
					break;
				case 'date':
					this.initDate();
					break;
			}
		},
	}
</script>

<style lang="scss">
	.light-mode {
		.field-box {
			background: #e2e3e6;

			.choices {
				.choices__inner {
					background: transparent;
					color: var(--background-color, $backgroundColor);
				}
				.choices__list--dropdown,
				.choices__list[aria-expanded] {
					background: #e2e3e6;
				}
			}
		}
	}

	.field-box {
		//background: #1d2943;
		background: rgba(var(--white-color-rgb), .06);
		padding: 15px 15px 14px;
		border-radius: 5px;
		position: relative;
		&.with-outline {
			&:before {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				border: 2px solid var(--primary-color, $primaryColor);
				pointer-events: none;
				display: block;
				border-radius: inherit;
			}
		}

		&.disabled {
			label {
				pointer-events: none;
			}

		}


		.choices {
			color: var(--white-color, $whiteColor);
			line-height: 1;
			&.is-disabled {
				.choices__inner {
					background: transparent;
				}
			}
			.choices__inner {
				padding: 5px 15px 5px 0!important;
				border: none;
				background: transparent;
				color: inherit;
			}
			.choices__list--single {
				font-weight: 500;
				font-size: 14px;
			}

			.choices__list.choices__list--single {
				color: inherit;
				.choices__placeholder {
					color: inherit;
				}
			}

			&[data-type*=select-one]:after {
				border: none;
				content: "\f078";
				font-family: "Font Awesome 6 Pro";
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				display: var(--fa-display, inline-block);
				font-style: normal;
				font-variant: normal;
				line-height: 1;
				text-rendering: auto;
				font-weight: 400;
				position: absolute;
				top: calc(50% - 5px);
				right: 10px;
				font-size: 12px;
				color: inherit;
			}
			.choices__list--dropdown,
			.choices__list[aria-expanded] {
				background: #1d2943;
				border: none;
				left: -15px;
				right: -15px;
				width: calc(100% + 30px);
				top: calc(100% + 5px);
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				.choices__item {
					padding: 5px 15px;
					white-space: normal;
					&.is-selected,
					&.is-highlighted {
						background:  var(--primary-color, $primaryColor);
						color: var(--white-color, $whiteColor);
					}
				}
			}
		}



		input[type="text"], input[type="email"], input[type="password"], textarea, select {
			padding: 0;
			background: transparent;
			color: var(--white-color, $whiteColor);
			font-weight: 500;
			font-size: 14px;
			border: none;
			border-radius: 0;
		}

		label {
			font-size: 10px;
			font-weight: 400;
			margin: 0 0 5px;
			display: block;

			&:last-child {
				margin-bottom: 0;
			}
		}

		select {
			padding: 3px 0 4px;
		}
	}


</style>