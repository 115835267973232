<template>
    <main class="app-main">
        <div class="content-wrap">
            <section class="content-section">
                <article>
                    <profile-form/>
                </article>
            </section>
        </div>
    </main>
</template>

<script>
    import ProfileForm from "../components/forms/ProfileForm";

    export default {
        name: "Profile",
        components: {
            ProfileForm
        }
    }
</script>

<style scoped>

</style>