const pageFunctionsMixin = {
    data() {
        return {
            appUrl: `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST_NAME}`,
        }
    },
    methods: {
        formatNumber(number){
            number = parseFloat(number);
            return number.toLocaleString('sk-Sk', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
                useGrouping: true
            }).replace(',', '.');
        },
        getLocalAsset(path){
            return require(`@/assets/${path}`);
        },
        getImage(filename){
            return `${this.appUrl}/storage/${filename}`;
        },
        logActivity(type, value){
            this.axios.post('/api/activity-logs', {
                type,
                value
            });
        },
        assureAppLeave(link){
            this.$swal.fire({
                icon: 'question',
                title: this.$t('general.clicked_link'),
                text: this.$t('general.leave_confirmation'),
                showCancelButton: true,
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no')
            }).then((result) => {
                if(!result.isConfirmed){
                    return;
                }

                window.open(link, '_blank');
            });
        },
        getColorStyle(color){
            if (color.startsWith('#')) {
                color = color.slice(1);
            }
        
            let r1 = parseInt(color.substring(0, 2), 16);
            let g1 = parseInt(color.substring(2, 4), 16);
            let b1 = parseInt(color.substring(4, 6), 16);
        
            let brightness = ((r1 * 299) + (g1 * 587) + (b1 * 114)) / 1000;
        
            let style = (brightness > 180) ? 'light' : 'dark';
        
            return style;
        }
    },
};

export default pageFunctionsMixin;