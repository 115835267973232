<template>
	<div class="pin-field">
		<div class="pin-value">
			<div class="box" v-for="i in 4" :class="pinValues.length >= i ? 'filled' : ''"></div>
		</div>
		<div class="pin-keyboard" :class="loading ? 'loading' : ''">
			<div class="pin-keyboard-item" v-for="i in 9">
				<button class="pin-keyboard-number" @click.prevent="setValue(i)">{{ i }}</button>
			</div>
			<div class="pin-keyboard-item">
				<button class="pin-keyboard-fingerprint">
					<i class="fa-thin fa-fingerprint" v-if="fingerprint"></i>
				</button>
			</div>
			<div class="pin-keyboard-item">
				<button class="pin-keyboard-number" @click.prevent="setValue(0)">0</button>
			</div>
			<div class="pin-keyboard-item">
				<button class="pin-keyboard-remove" @click.prevent="removeValue">
					<i class="fa-thin fa-delete-left"></i>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "PinField",
		props: ['fingerprint', 'name', 'loading'],
		data() {
			return {
				pinValues: [],
				currentPinNumber: 1,
			}
		},
		methods: {
			setValue(item) {
				if (this.pinValues.length === 4) {
					return false;
				}

				this.pinValues.push(item);

				if (this.pinValues.length === 4) {
					this.$root.$emit('pin-value-changed', {
						name: this.name,
						value: this.pinValues.join('')
					});
				}
			},
			removeValue() {
				this.pinValues.pop();
			}
		},
		watch: {
			name() {
				this.pinValues = [];
			}
		},
		mounted() {
			this.$root.$on('pin-value-reset', () => {
				this.pinValues = [];
			});
		},
		destroyed() {
			this.$root.$off('pin-value-reset');
		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.pin-keyboard-number {
			background: #e2e3e6;
		}

		.pin-keyboard-number,
		.pin-keyboard-remove,
		.pin-keyboard-fingerprint {
			color: var(--background-color, $backgroundColor);
		}

		.pin-value {
			.box {
				&:after {
					background: var(--background-color, $backgroundColor);
				}
			}
		}
	}

	.pin-field {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.pin-value {
		display: flex;
		justify-content: center;
		margin-bottom: 40px;

		.box {
			width: 20px;
			height: 20px;
			border: 2px solid #1E2D4B;
			border-radius: 5px;
			margin-right: 14px;
			position: relative;

			&.active {

			}

			&:first-child:not(.filled),
			&.filled + .box:not(.filled) {
				border-color: transparent;

				&:before {
					opacity: 1;
				}
			}

			&.filled {
				&:after {
					opacity: 1;
				}
			}

			&:before {
				content: '';
				left: -4px;
				right: -4px;
				top: -4px;
				bottom: -4px;
				border: 2px solid var(--primary-color, $primaryColor);
				display: block;
				position: absolute;
				border-radius: 5px;
				opacity: 0;
			}

			&:after {
				content: '';
				position: absolute;
				left: 5px;
				right: 5px;
				top: 5px;
				bottom: 5px;
				background: var(--white-color, $whiteColor);
				border-radius: 1px;
				opacity: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.pin-keyboard {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 auto;
		position: relative;
		flex-grow: 1;
		width: 100%;

		&:before {
			position: absolute;
			color: var(--white-color, $whiteColor);
			font-size: 35px;
			content: "\f3f4";
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			font-style: normal;
			font-variant: normal;
			line-height: 1;
			text-rendering: auto;
			font-family: "Font Awesome 6 Pro";
			font-weight: 100;
			top: 121px;
			animation: spinning_animation 1s infinite;
			animation-timing-function: linear;
			pointer-events: none;
			opacity: 0;
			transition: $animationDefaultDuration;
		}

		&.loading {
			&:before {
				opacity: 1;
			}

			.pin-keyboard-item {
				opacity: 0;
				pointer-events: none;
			}
		}
	}

	.pin-keyboard-number,
	.pin-keyboard-remove,
	.pin-keyboard-fingerprint {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--white-color, $whiteColor);
		border: none;
		cursor: pointer;
	}

	.pin-keyboard-number {
		//background: transparentize($whiteColor, .94);
		background: rgba(var(--white-color-rgb), .06);
		border-radius: 5px;
		font-size: 7vw;
	}

	.pin-keyboard-fingerprint,
	.pin-keyboard-remove {
		background: transparent;
		font-size: 28px;
	}

	.pin-keyboard-item {
		line-height: 1;
		width: 31%;
		height: 22.375%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 3.5%;
		transition: $animationDefaultDuration;

		&:nth-child(3n + 3) {
			margin-right: 0;
		}

		&:nth-child(n + 4) {
			margin-top: 3.5%;
		}

	}

	@media(min-width: 500px){
		.pin-keyboard-item {
			height: 80px;
		}
		.pin-keyboard-number {
			font-size: 20px;
		}
	}

	@media($mobileS) {
		.pin-value {
			margin-bottom: 25px;
		}
		.pin-keyboard {
			margin: 0 auto;

			&:before {
				font-size: 25px;
				top: 87px;
			}
		}
		.pin-keyboard-item {
			width: calc(33.333%  - 20px / 3);
			height: calc(25% - 30px / 4);
			margin-right: 10px;
			&:nth-child(n + 4) {
				margin-top: 10px;
			}
		}
		.pin-keyboard-fingerprint,
		.pin-keyboard-remove {
			background: transparent;
			font-size: 24px;
		}
	}
</style>