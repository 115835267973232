import {Geolocation} from "@capacitor/geolocation";

const branchesMixin = {
	data() {
		return {
			currentPosition: false,
		}
	},
	methods: {
		getDistance(lat1, lng1, lat2, lng2) {
			let R = 6371;
			let dLat = this.toRad(lat2 - lat1);
			let dLon = this.toRad(lng2 - lng1);
			lat1 = this.toRad(lat1);
			lat2 = this.toRad(lat2);

			let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
				Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
			let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
			let d = R * c;

			return d;
		},

		toRad(value) {
			return value * Math.PI / 180;
		},

		getCurrentPosition() {
			if (this.$store.state.app.native) {
				this.getCurrentPositionNative();
			} else {
				this.getCurrentPositionWeb();
			}
		},

		getCurrentPositionNative() {
			this.$store.commit('SET_STOP_AUTOMATIC_LOGOUT');
			Geolocation.getCurrentPosition().then(response => {
				this.currentPosition = {
					lat: response.coords.latitude,
					lng: response.coords.longitude
				}
				this.$store.commit('UNSET_STOP_AUTOMATIC_LOGOUT');
			}).catch(() => {
				this.$store.commit('UNSET_STOP_AUTOMATIC_LOGOUT');
			});
		},

		getCurrentPositionWeb() {
			if (!navigator || !navigator.geolocation) {
				return false;
			}

			navigator.geolocation.getCurrentPosition((position) => {
				this.currentPosition = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};
			});
		}
	},
	mounted() {
		this.getCurrentPosition();
	}
};

export default branchesMixin;