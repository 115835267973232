<template>
	<auth-popup v-if="$store.state.popups.showRegistrationPopup" :extra-class="extraClass">
		<div class="registration-popup-box">
			<header class="popup-header">
				<h3 class="popup-title">{{ $t('auth.create_account') }}</h3>
				<p>{{ $t('auth.registration_popup_text') }}</p>
			</header>
			<article>
				<social-auth-box type="register"/>
				<p class="divider"><span class="divider-inner">{{ $t('general.or') }}</span></p>
				<registration-form/>
				<div class="actions">
					<p class="already-registered">
						{{ $t('auth.already_have_account') }}
						<a href="#" @click.prevent="openLoginPopup">{{ $t('auth.log_in') }}</a>
					</p>
				</div>
			</article>
		</div>
	</auth-popup>
</template>

<script>
	import AuthPopup from "./AuthPopup";
	import SocialAuthBox from "../containers/SocialAuthBox";
	import RegistrationForm from "../forms/RegistrationForm";

	export default {
		name: "RegistrationPopup",
		components: {
			AuthPopup,
			SocialAuthBox,
			RegistrationForm,
		},
		data() {
			return {
				extraClass: 'registration-popup'
			}
		},
		methods: {
			openLoginPopup() {
				this.$store.dispatch('openLoginPopup')
			}
		}
	}
</script>

<style scoped lang="scss">
	.registration-popup-box {
		.actions {
			margin-top: 20px;
			text-align: center;

			.already-registered {
				font-weight: 600;
				font-size: 12px;
				text-transform: uppercase;

				a {
					color: var(--white-color, $whiteColor);

					&:hover {
						color: var(--primary-color, $primaryColor);
					}
				}
			}
		}
	}
</style>