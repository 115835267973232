<template>
	<div class="value-boxes-container">
		<value-box v-for="(item, index) in data" :data="item" :key="index"/>
	</div>
</template>

<script>
	import ValueBox from "./ValueBox";
	export default {
		name: "ValueBoxesContainer",
		props: ['data'],
		components: {
			ValueBox
		}
	}
</script>

<style scoped lang="scss">
	.value-boxes-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.value-box {
		width: calc(50% - 10px);
		&:nth-child(n + 3){
			margin-top: 20px;
		}
	}
	@media($tabletM){
		.value-box {
			width: 100%;
			&:nth-child(n + 2){
				margin-top: 20px;
			}
		}
	}
</style>