<template>
	<div class="chart-panels-group">
		<chart-boxes-container :data="chartsData"/>
		<panel-boxes-container :data="panelsData"/>
	</div>
</template>

<script>
import ChartBoxesContainer from "@/components/containers/ChartBoxesContainer";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";
export default {
	name: "ChartPanelsGroup",
	props: ['panelsData', 'chartsData'],
	components: {
		ChartBoxesContainer,
		PanelBoxesContainer
	}
}
</script>

<style scoped lang="scss">
	.chart-panels-group {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
		.chart-boxes-container {
			width: 40%;
		}
		.panel-boxes-container {
			width: calc(60% - 15px);
		}

		@media($desktopM){
			flex-direction: column;
			.chart-boxes-container {
				width: 100%;
				margin-bottom: 15px;
			}
			.panel-boxes-container {
				width: 100%;
			}
		}
	}
</style>