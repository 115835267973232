<template>
	<router-view/>
</template>

<script>
export default {
	name: "Investments",
	errorCaptured(err, vm, info) {
		let component = vm.$vnode.tag;
		component = component.toLowerCase();
		let type = '';
		if (component.includes('eic')){
			type = 'EIC';
		} else if(component.includes('iad')){
			type = 'IAD';
		} else if (component.includes('conseq')){
			type = 'Conseq';
		} else if(component.includes('investika')){
			type = 'Investika';
		}

		this.$swal.fire({
			icon: 'error',
			title: 'Oops',
			text: this.$t('investments.investment_error', {type: type})
		});
	}
}
</script>

<style scoped>

</style>