<template>
    <label class="checkbox-switcher" :class="extraClass">
        <div class="text">
            <span class="icon" v-if="icon" v-html="icon"></span>
            <span class="field-title">{{ label }}</span>
        </div>
        <input type="checkbox" :name="name" @change="changeSwitcher" :checked="checked" :disabled="disabled" @click="clickCheckbox">
        <div class="status" :class="loading ? 'loading' : ''">
            <div class="loading-icon" v-if="loading">
                <i class="fa-thin fa-spinner-third"></i>
            </div>
        </div>
    </label>
</template>

<script>
    export default {
        name: "CheckboxSwitcher",
        props: ['name', 'checked','label', 'icon', 'disabled', 'preventDefaultEvent', 'loading', 'event', 'extraClass'],
        methods: {
            clickCheckbox(e){
                if (this.preventDefaultEvent){
                    e.preventDefault();
                    e.stopPropagation();

                    let event = 'checkbox-switcher-change';
                    if (this.event) {
                        event = this.event;
                    }

                    this.$root.$emit(event, {
                        name: this.name,
                        value: e.target.checked
                    });
                }
            },
            changeSwitcher(e) {
                if(this.preventDefaultEvent){
                    return false;
                }

                let event = 'checkbox-switcher-change';
                if (this.event) {
                    event = this.event;
                }

				this.$emit('change', {
					name: this.name,
					value: e.target.checked
				});

                this.$root.$emit(event, {
                    name: this.name,
                    value: e.target.checked
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .light-mode {
        .checkbox-switcher {
            color: var(--background-color, $backgroundColor);
            .icon {
                opacity: .5;
            }
            .field-title {
                color: var(--background-color, $backgroundColor);
            }
            .status {
                border: 2px solid rgba(var(--background-color-rgb), 0.1);
                &:after {
                    background: rgba(var(--background-color-rgb), .75);
                }
                &.loading {
                    color: var(--background-color, $backgroundColor);
                }
            }
        }
    }
    .checkbox-switcher {
        display: flex;
        cursor: pointer;
        align-items: center;
        justify-content: space-between;
        width: 100%;

	    &.column-style {
		    flex-direction: column;
		    align-items: flex-start;
		    .text {
			    margin-bottom: 10px;
		    }
		    .status {
			    margin-left: 0;
		    }
	    }

        .text {
            display: flex;
            align-items: center;
        }

        .icon {
            margin-right: 14px;
            font-size: 26px;
            position: relative;
            top: 1px;
            width: 30px;
        }

        .field-title {
            color: var(--white-color, $whiteColor);
            font-weight: 600;
            font-size: 16px;
            line-height: 1.2;
        }

        .loading-icon {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            text-align: center;
            opacity: 0;
            pointer-events: none;
            transition: $animationDefaultDuration;
            animation: spinning_animation 1s infinite;
            animation-timing-function: linear;
        }

        .status {
            min-width: 45px;
            width: 45px;
            height: 24px;
            border-radius: 50px;
            //border: 2px solid transparentize($whiteColor, .9);
            border: 2px solid rgba(var(--white-color-rgb), .1);
            margin-left: 14px;
            position: relative;

            &:before {
                content: '';
                left: -2px;
                right: -2px;
                top: -2px;
                bottom: -2px;
                display: block;
                position: absolute;
                border-radius: inherit;
                background: var(--primary-gradient, $primaryGradient);
                opacity: 0;
                transition: $animationDefaultDuration;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                top: 0;
                width: 20px;
                display: block;
                //background: transparentize($whiteColor, .25);
                background: rgba(var(--white-color-rgb), .75);
                border-radius: inherit;
                transition: $animationDefaultDuration;
            }
            &.loading {
                border-color: transparent;
                background: transparent;

                color: var(--white-color, $whiteColor);
                &:before,
                &:after {
                    opacity: 0;
                }

                .loading-icon {
                    opacity: 1;
                }
            }
        }

        input {
            display: none;

            &:checked {
                & + .status {
                    &:before {
                        opacity: 1;
                    }

                    &:after {
                        left: calc(100% - 20px);
                        background: var(--white-color, $whiteColor);
                    }
                }
                & + .status.loading {
                    &:before {
                        opacity: 0;
                    }
                }
            }
        }

    }
</style>