const helpers = {
    checkTimestamp(timestamp) {
        if (!timestamp) {
            return true;
        }

        let currentTimestamp = +new Date();
        let duration = 300000; //5 minutes

        if ((currentTimestamp - timestamp) < duration) {
            return false;
        }

        return true;
    }
};

export default helpers;