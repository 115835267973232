<template>
	<default-popup extra-class="user-deletion-popup no-transparent">
		<div class="content">
			<h2 class="popup-title">{{ $t('auth.for_account_deletion') }}</h2>
			<p>{{ $t('auth.enter_sms_code') }} {{ phone }}</p>
			<user-deletion-form/>
		</div>
	</default-popup>
</template>

<script>
	import DefaultPopup from "./DefaultPopup";
	import UserDeletionForm from "../forms/UserDeletionForm";

	export default {
		name: "UserDeletionPopup",
		components: {
			DefaultPopup,
			UserDeletionForm
		},
		data() {
			return {
				phone: this.$store.state.auth.user.phone,
			}
		},
	}
</script>

<style scoped lang="scss">
	.popup-inner {
		.user-deletion-form {
			margin-top: 50px;
		}
	}
</style>