<template>
	<div class="card-box" @click.stop="openDetail">
		<figure class="fit-image card" v-if="data.card_category.code !== 'custom'">
			<img :src="categoryImage">
		</figure>
		<div class="card-preview" v-else :style="`background: ${data.color};`">
			<div class="inner">
				<div class="icon">
					<i class="fa-solid fa-credit-card-blank"></i>
				</div>
				<h3 class="card-preview-title">{{ data.name }}</h3>
			</div>
		</div>
		<div class="actions" v-if="!disableActions">
			<button type="button" class="remove-item" @click.stop.prevent="removeItem">
				<i class="fa-regular fa-trash"></i>
			</button>
		</div>
	</div>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";

	export default {
		name: "CardBox",
		mixins: [pageFunctionsMixin],
		props: ['data', 'disableActions'],
		computed: {
			categoryImage() {
				return this.getImage(this.data.card_category.logo.filename);
			}
		},
		methods: {
			removeItem() {
				this.$swal.fire({
					icon: 'question',
					title: this.$t('general.delete_confirm_title'),
					text: this.$t('general.delete_confirm_text_card'),
					showDenyButton: true,
					confirmButtonText: this.$t('general.yes'),
					denyButtonText: this.$t('general.no'),
				}).then((result) => {
					if (result.isConfirmed) {
						this.axios.post(`/api/cards/${this.data.id}`, {
							_method: 'DELETE'
						}).then(() => {
							this.$store.commit('REMOVE_CARD', {
								id: this.data.id
							});
						});
					}
				});
			},
			openDetail() {
				if(this.$store.state.sidebars.showCardsEditForm){
					this.$store.commit('HIDE_CARDS_EDIT_FORM');
					setTimeout(() => {
						this.$store.commit('SHOW_CARDS_EDIT_FORM', this.data);
					}, 400);
					return;
				}

				this.$store.commit('SHOW_CARDS_EDIT_FORM', this.data);
			}
		},
		mounted() {

		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.card-box {
			.actions {
				.remove-item {
					border-color: var(--white-color, $whiteColor);
					background: var(--background-color, $backgroundColor);
					color: var(--white-color, $whiteColor);
				}
			}
			.card-preview-title {
				color: var(--white-color, $whiteColor);
			}
		}
	}
	.four-columns {
		@media(min-width: 992px){
			.card-box {
				.icon {
					font-size: 21px;
				}
				.card-preview-title {
					font-size: 14px;
				}
			}
		}

	}
	.card-box {
		cursor: pointer;
		position: relative;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -6px;
			right: -6px;
			bottom: -6px;
			left: -6px;
			pointer-events: none;
			border: 2px solid var(--secondary-color, $primaryColor);
			border-radius: 8px;
			opacity: 0;
			transition: $animationDefaultDuration;
		}

		&:hover {
			z-index: 10;
			&:before {
				opacity: 1;
			}

			.actions {
				opacity: 1;
			}
		}

		.actions {
			position: absolute;
			right: -19px;
			top: -19px;
			opacity: 0;
			transition: $animationDefaultDuration;

			.remove-item {
				background: var(--white-color, $whiteColor);
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100%;
				width: 38px;
				height: 38px;
				font-size: 14px;
				position: relative;
				border: 5px solid var(--background-color, $backgroundColor);
				color: var(--primary-color, $primaryColor);
				cursor: pointer;
				transition: $animationDefaultDuration;

				&:hover {
					color: var(--white-color, $whiteColor);
					background: var(--primary-color, $primaryColor);
				}
			}
		}
	}

	.card,
	.card-preview {
		border-radius: 5px;
		overflow: hidden;
		position: relative;

		&:before {
			padding-bottom: 75%;
		}
	}

	.card-preview {
		&:before {
			content: '';
			display: block;
		}

		.icon {
			font-size: 30px;
			margin-bottom: 5px;
		}

		.card-preview-title {
			font-size: 26px;
		}

		.inner {
			color: var(--white-color, $whiteColor);
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}


	}
	@media($mobileM){
		.with-scroll {
			.card-preview {
				.icon {
					font-size: 18px;
				}
				.card-preview-title {
					font-size: 16px;
				}
			}
		}

		.card-preview {
			.card-preview-title {
				font-size: 16px;
			}
		}
	}
	@media($mobileS){
		.card-preview {
			.card-preview-title {
				font-size: 26px;
			}
		}
	}
</style>