<template>
	<div class="quagga-field">
		<input type="file" @change="handleFile" ref="fileInput">
		<button class="button style-1" @click.prevent="openFileInput">
			<span class="inner">{{ $t('forms.import_screenshot') }}</span>
		</button>
	</div>
</template>

<script>
	import Quagga from '@ericblade/quagga2';

	export default {
		name: "QuaggaField",
		data() {
			return {
				loading: false,
			}
		},
		methods: {
			openFileInput() {
				this.$refs.fileInput.click();
			},
			handleFile(e) {
				if (!e.target.files.length) {
					return false;
				}

				let file = e.target.files[0];

				let reader = new FileReader();
				reader.onload = (e) => {
					this.readBarcode(e.target.result);
				}

				reader.readAsDataURL(file);
			},
			readBarcode(src) {
				Quagga.decodeSingle({
					decoder: {
						readers: ['code_128_reader', 'ean_reader']
					},
					locate: false,
					src: src,
				}, (result) => {
					if (!result.codeResult) {
						this.$swal.fire({
							icon: 'error',
							title: 'Oops',
							text: this.$t('cards.failed_read_barcode')
						});

						return false;
					}

					this.$swal.fire({
						icon: 'success',
						title: this.$t('cards.card'),
						html: `${this.$t('cards.successfully_imported')}<br>${result.codeResult.code}`
					});

					this.$root.$emit('screenshot-imported', result.codeResult.code);
				});
			}
		}
	}
</script>

<style scoped lang="scss">
	input {
		display: none;
	}

	.button {
		width: 100%;
	}
</style>