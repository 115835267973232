<template>
	<div class="chart-box">
		<div class="inner" ref="inner">
			<apexchart :options="data.chartOptions" :series="data.series"/>
			<p class="total-deposits" v-if="data.totalDeposits !== undefined">{{ $t('investments.total_deposits') }} {{ totalDeposits }}</p>
			<p class="total-investments" v-if="data.totalInvestments !== undefined">{{ $t('investments.total_investments') }} {{ totalInvestments }}</p>
			<p class="total-withdrawals" v-if="data.totalWithdrawals !== undefined">{{ $t('investments.total_withdrawals') }} {{ totalWithdrawals }}</p>
			<p class="total-fees" v-if="data.totalFees">{{ $t('investments.total_fees') }} {{ totalFees }}</p>
			<p class="total-return" v-if="data.totalReturn">{{ $t('investments.total_return') }}
				<span :class="totalReturnClass" >{{ totalReturn }}</span>
			</p>
			<p class="total-balance" v-if="data.totalBalance">{{ $t('investments.total_balance') }} {{ totalBalance }}</p>
		</div>
	</div>
</template>

<script>
import pageFunctionsMixin from "@/mixins/pageFunctionsMixin";

export default {
	name: "ChartBox",
	props: ['data'],
	mixins: [pageFunctionsMixin],
	data() {
		return {
			renderChart: false,
			width: 0,
			height: 0
		}
	},
	computed: {
		totalBalance() {
			return `${this.formatNumber(this.data.totalBalance)} ${process.env.VUE_APP_CURRENCY}`;
		},
		totalFees() {
			return `${this.formatNumber(this.data.totalFees)} ${process.env.VUE_APP_CURRENCY}`;
		},
		totalDeposits() {
			return `${this.formatNumber(this.data.totalDeposits)} ${process.env.VUE_APP_CURRENCY}`;
		},
		totalInvestments() {
			return `${this.formatNumber(this.data.totalInvestments)} ${process.env.VUE_APP_CURRENCY}`;
		},
		totalWithdrawals() {
			return `${this.formatNumber(this.data.totalWithdrawals)} ${process.env.VUE_APP_CURRENCY}`;
		},
		totalReturn(){
			let totalReturn = this.data.totalReturn;
			if (totalReturn > 0){
				totalReturn = `+${this.formatNumber(totalReturn.toFixed(2))} ${process.env.VUE_APP_CURRENCY}`;
			} else {
				totalReturn = `${this.formatNumber(totalReturn.toFixed(2))} ${process.env.VUE_APP_CURRENCY}`;
			}
			return totalReturn;
		},
		totalReturnClass(){
			return this.data.totalReturn > 0 ? 'up': 'down';
		}
	},
	mounted() {
		this.width = this.$refs.inner.offsetWidth;
		this.height = this.$refs.inner.offsetHeight;
	}
}
</script>

<style scoped lang="scss">
.light-mode {
	.chart-box {
		background: #F0F0F2;
		color: var(--background-color, $backgroundColor);
		fill: var(--background-color, $backgroundColor);
	}
}

.chart-box {
	padding: 25px;
	background: rgba(var(--white-color-rgb), .06);
	border-radius: 5px;
	color: var(--white-color, $whiteColor);
	fill: var(--white-color, $whiteColor);

	.inner {
		height: 100%;
		> div {
			margin-bottom: 25px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	p {
		margin-bottom: 5px;
		font-size: 14px;
		&.total-balance {
			font-size: 16px;
		}
		.up {
			color: var(--success-color, $successColor);
		}
		.down {
			color: var(--error-color, $errorColor);
		}
	}

	@media($mobileM) {
		padding: 20px;
	}
}
</style>