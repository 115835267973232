<template>
	<ul class="settings-list">
		<li>
			<checkbox-switcher name="dark_mode" :label="$t('settings.dark_mode')"
			                   icon='<i class="fa-thin fa-moon"></i>'
			                   :checked="$store.state.auth.user.settings ? $store.state.auth.user.settings.dark_mode : false"
			                   event="setting-checkbox-switcher-change"/>
		</li>
		<li v-if="biometricAvailable && $store.state.app.native">
			<checkbox-switcher name="biometric_login"
			                   :label="$t('settings.biometric_login')"
			                   icon='<i class="fa-thin fa-fingerprint"></i>'
			                   :checked="biometricEnabled && hasBiometricCredentials"
			                   event="setting-checkbox-switcher-change"/>
		</li>
		<li v-if="$store.state.app.native">
			<checkbox-switcher name="pin_login"
			                   :label="$t('settings.pin_login')"
			                   icon='<i class="fa-thin fa-mobile-button"></i>'
			                   :checked="pinEnabled && hasPin"
			                   :prevent-default-event="true"
			                   :loading="pinLoading"
			                   event="setting-checkbox-switcher-change"/>
		</li>
		<li>
			<checkbox-switcher name="push_notifications"
			                   :label="$t('settings.push_notifications')"
			                   icon='<i class="fa-thin fa-bell"></i>'
			                   :checked="pushNotificationEnabled"
			                   :prevent-default-event="true"
			                   :loading="pushNotificationLoading"
			                   event="setting-checkbox-switcher-change"/>
		</li>
		<!--<li>
			<a href="#" class="settings-list-item">
				<span class="icon"><i class="fa-thin fa-house-blank"></i></span> Domovská obrazovka
			</a>
		</li>-->
		<!--<li>
			<a href="#" class="settings-list-item">
				<span class="icon"><i class="fa-thin fa-square-info"></i></span> O aplikácií
			</a>
		</li>-->
	</ul>
</template>

<script>
	import CheckboxSwitcher from "../form-fields/CheckboxSwitcher";
	import pushSubscriptionMixin from "../../mixins/pushSubscriptionMixin";
	import biometricMixin from "../../mixins/biometricMixin";
	import pinMixin from "../../mixins/pinMixin";

	export default {
		name: "SettingsList",
		mixins: [pushSubscriptionMixin, biometricMixin, pinMixin],
		components: {
			CheckboxSwitcher
		},
		methods: {
			updateSettings(data) {
				data._method = 'PUT';
				this.axios.post('/api/user/settings', data);
			},
			toggleDarkMode(value) {
				this.$store.commit('SET_DARK_MODE', value);
			}
		},
		mounted() {
			this.registerServiceWorker();
			this.checkPushNotificationSetting();
			this.listenRegisterEvent();

			this.$root.$on('setting-checkbox-switcher-change', (data) => {
				switch (data.name) {
					case 'push_notifications':
						this.togglePushNotifications(data.value);
						break;
					case 'biometric_login':
						this.toggleBiometricLogin(data.value);
						break;
					case 'pin_login':
						this.togglePinLogin(data.value);
						break;
					case 'dark_mode':
						this.toggleDarkMode(data.value);
						this.updateSettings(data);
						break;
					default:
						this.updateSettings(data);
						break;
				}
			});
		},
		beforeDestroy() {
			this.removePushNotificationListeners();
			this.$root.$off('setting-checkbox-switcher-change');
		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.settings-list {
			li {
				border-bottom: 1px solid rgba(var(--background-color-rgb), 0.15);
			}
		}

		.settings-list-item {
			color: var(--background-color, $backgroundColor);
		}
	}

	.settings-list {
		li {
			padding: 15px 0;
			//border-bottom: 1px solid transparentize($whiteColor, .85);
			border-bottom: 1px solid rgba(var(--white-color-rgb), .15);

			&:first-child {
				padding-top: 0;
			}

			&:last-child {
				border-bottom: none;
			}
		}
	}

	.settings-list-item {
		font-weight: 600;
		font-size: 16px;
		color: var(--white-color, $whiteColor);
		line-height: 1.2;
		display: flex;
		align-items: center;

		.icon {
			display: inline-block;
			font-size: 26px;
			top: 1px;
			position: relative;
			width: 30px;
			margin-right: 14px;
			opacity: .5;
		}

		&:hover {
			color: var(--primary-color, $primaryColor);
		}
	}
</style>