<template>
	<figure class="broker-logo">
		<img :src="brokerLogo">
	</figure>
</template>

<script>
import pageFunctionsMixin from '../../mixins/pageFunctionsMixin';

	export default {
		name: "BrokerLogo",
		mixins: [pageFunctionsMixin],
		computed: {
			brokerLogo(){
				if (this.$store.state.app.appType === 'pluska'){
					return 'https://pluska.invelity.com/img/pluska-logo.svg';
				}

				let url = 'https://isanon.invelity.com/img/universal-logo.png';
				if (this.$store.state.auth.user.agent && this.$store.state.auth.user.agent.broker_data){
					url = this.$store.state.auth.user.agent.broker_data.broker_logo;
					if(this.$store.state.auth.user.agent.broker_data?.colors_ufo?.third){
						let colorStyle = this.getColorStyle(this.$store.state.auth.user.agent.broker_data.colors_ufo.third);
						if(colorStyle === 'light' && this.$store.state.auth.user.agent.broker_data.broker_logo2){
							url = this.$store.state.auth.user.agent.broker_data.broker_logo2;
						}
					}

					if (!this.$store.state.app.darkMode && this.$store.state.auth.user.agent.broker_data.broker_logo2){
						url = this.$store.state.auth.user.agent.broker_data.broker_logo2;
					}
				}

				return url;
			}
		}
	}
</script>

<style scoped lang="scss">
	.broker-logo {
		img {
			max-height: 50px;
		}
	}
</style>