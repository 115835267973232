<template>
	<form class="user-change-phone-form" @submit.prevent="submitForm">
		<errors-list :errors="errors"/>
		<default-field-box type="tel" name="phone" :placeholder="$t('forms.phone')" :required="true" 
			v-model="phone" event="phone-field-changed"/>
		<div class="form-actions">
			<button class="button style-4" :class="loading ? 'loading' : ''">
				<span class="inner">{{ $t('forms.change') }}</span>
			</button>
			<a href="#" class="link" @click.prevent="handleBackButton">{{ $t('general.back')}}</a>
		</div>
	</form>
</template>

<script>
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";
	import ErrorsList from "../containers/ErrorsList";

	export default {
		name: "UserChangePhoneForm",
		components: {
			DefaultFieldBox,
			ErrorsList
		},
		data() {
			return {
				loading: false,
				errors: [],
				phone: '',
			}
		},
		methods: {
			submitForm(){
				if(!this.phone || this.loading){
					return;
				}

				this.loading = true;
				this.axios.post('/api/user', {
					_method: 'PUT',
					phone: this.phone,
				}).then((response) => {
					this.loading = false;
					this.$emit('phone-changed', this.phone);
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}
					this.loading = false;
				});
			},
			handleBackButton(){
				this.$emit('show-activation-form');
			},
			processPhoneFieldChanged(data){
				this.phone = data.value;
			}
		},
		mounted() {
			this.$root.$on('phone-field-changed', (data) => {
				this.processPhoneFieldChanged(data);
			});
		},
		destroyed() {
			this.$root.$off('phone-field-changed');
		}
	}
</script>

<style scoped lang="scss">
	.link {
		color: var(--font-color);
		&:hover {
			color: var(--white-color);
		}
	}
	.button {
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}
</style>