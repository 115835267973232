<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<article>
					<div id="Finvia-widgets" class="widget-container"></div>
				</article>
			</section>
		</div>
	</main>
</template>

<script>

export default {
	name: "OnlineServicePensionCalculator",
	components: {
		
	},
	data() {
		return {
			
		}
	},
	mounted(){

		$("#Finvia-widgets").load('https://finvia.sk/js-widgets/dochodkova-kalkulacka/dochodkova-kalkulacka.php?calcform=no',function() {
			const widgetContainer = document.getElementById('Finvia-widgets');
			let grveContent = document.getElementById('grve-content');
			let styles = widgetContainer.querySelectorAll('link[rel="stylesheet"]');

			grveContent.removeChild(styles[1]);
			grveContent.removeChild(styles[2]);

			const inputs = widgetContainer.querySelectorAll('input');
			inputs.forEach(item => {
				item.classList.remove('dk-input');
			});
			const formRows = widgetContainer.querySelectorAll('.dk-form-row');
			formRows.forEach(item => {
				item.classList.remove('dk-form-row');
				item.classList.add('form-row');
			});
	

			const changeLink = widgetContainer.querySelector('.zmenit');
			changeLink.classList.remove('zmenit');

			const formCheckElements = document.querySelectorAll('.form-check');

			// Loop through each form-check element
			formCheckElements.forEach(oldElement => {
				// Find the input and label within the current .form-check
				const oldInput = oldElement.querySelector('.form-check-input');
				const oldLabel = oldElement.querySelector('.form-check-label');

				// Create the new elements dynamically
				const newLabel = document.createElement('label');
				newLabel.classList.add('radio-box');
				
				const newInput = document.createElement('input');
				newInput.type = 'radio';
				newInput.name = oldInput.name;        // Keep the name attribute
				newInput.value = oldInput.value;      // Keep the value attribute
				newInput.checked = oldInput.checked;  // Preserve the checked state
				newInput.id = oldInput.id;            // Keep the id attribute if needed

				const newStatus = document.createElement('span');
				newStatus.classList.add('status');
				
				const newText = document.createElement('span');
				newText.classList.add('field-title');
				newText.textContent = oldLabel.textContent;  // Use the original label text

				// Append the input, status, and text to the new label
				newLabel.appendChild(newInput);
				newLabel.appendChild(newStatus);
				newLabel.appendChild(newText);

				// Replace the old element with the new one
				oldElement.replaceWith(newLabel);
			});

			const chartContainer = document.getElementById('chart');

			// Options for the observer (which mutations to observe)
			const config = { childList: true, subtree: true };

			// Callback function to execute when mutations are observed
			const callback = (mutationsList) => {
				mutationsList.forEach(mutation => {
					if (mutation.type === 'childList') {
						let svg = chartContainer.querySelector('svg');
						if(svg){
							svg.setAttribute('viewBox', '0 0 300 300');
							svg.scrollIntoView({
								behavior: 'smooth'
							});
						}
					}
				});
			};

			// Create an observer instance linked to the callback function
			const observer = new MutationObserver(callback);

			// Start observing the target node for configured mutations
			observer.observe(chartContainer, config);
        });
	}
}
</script>

<style lang="scss">
.section-header {
	margin-bottom: 50px;
}

.light-mode .widget-container {
	.dk-column-wrapper.dk-col-4,
	.dk-column-wrapper.dk-col-8 {
		background: #f0f0f2!important;
	}
	.tip-pre-vas p.tip-title,
	.dk-row h2,
	.accordion-item-heading-titles h3 {
		color: var(--background-color);
	}
	span.result-red, span.result-red span {
		color: var(--background-color)!important;
	}
	p.result-number {
		font-size: 24px;
		color: var(--background-color);
	}
	text.TotalTextBottom,
	text.TotalTextTop,
	text.totalpercent {
		fill: var(--background-color)!important;
	}
	img {
		filter: brightness(0);
	}
	.accordion-item-heading-titles p {
		color: var(--secondary-font-color);
	}
}

.widget-container {
	font-family: $primaryFont!important;
	.dk-row h1,
	.accordion-item-heading-titles h3,
	.dk-form-row .col-md-7 label,
	.dk-form-row .input-group-text,
	.accordion-header .btn-secondary,
	.accordion-item-heading-titles p,
	.results-content h3,
	p.result-text,
	p.result-number,
	.link-blue a, .zmenit,
	text.TotalTextBottom,
	text.TotalTextTop,
	text.totalpercent,
	text.text-top,
	.plain-info-btn,
	.dk-form-radio label,
	.bs-tooltip-top,
	.tooltip-inner,
	.dk-row h2,
	#zhrnutie-penzii p,
	.dk-row h4,
	#zhrnutie-penzii p strong,
	.ukaz-vysledok {
		font-family: $primaryFont!important;
	}
	.input-group > .form-control, .input-group > .form-select {
		width: 100%!important;
	}

	.empty-space.s-50 {
		display: none;
	}

	.dk-column-wrapper.dk-col-8 {
		background: rgba(var(--white-color-rgb), .06)!important;
		border-radius: 5px;
	}

	.accordion-header a,
	.link-blue a, .zmenit {
		color: var(--primary-color);
		font-size: 14px;
		&:hover {
			text-decoration: none;
			color: var(--white-color);
		}
	}
	text.TotalTextBottom,
	text.TotalTextTop,
	text.totalpercent {
		fill: var(--white-color)!important;
	}

	.dk-column-wrapper.dk-col-4 {
		background: rgba(var(--white-color-rgb), .06);
		border-radius: 5px;
		margin-right: 15px;
		padding: 35px;
	}
	.accordion-item-heading:first-child {
		padding-top: 0;
	}
	.dk-column-wrapper.dk-col-8 {
		padding: 35px;
	}
	.plain-content {
		padding: 0;
	}
	.plain-info-btn {
		background: transparent;
		border: none;
	}

	.dk-row h2 {
		font-size: 26px;
	}

	button.ukaz-vysledok {
		border: 2px solid transparent;
		text-align: center;
		transition: $animationDefaultDuration;
		border-radius: 5px;
		font-weight: 600;
		font-size: 15px!important;
		line-height: 1!important;
		padding: 15px 35px!important;
		text-transform: uppercase;
		background: var(--primary-color, $primaryColor);
		color: var(--button-color, $whiteColor);
		margin-top: 25px!important;
		cursor: pointer;

		&:hover {
			background: var(--button-color, $whiteColor);
			color: var(--background-color, $backgroundColor);
		}
	}
	.tip-pre-vas p.tip-title,
	.dk-row h2,
	.accordion-item-heading-titles h3 {
		color: var(--white-color);
	}
	.accordion-item-heading-titles p {
		color: var(--font-color);
	}
	label {
		font-weight: 500;
		margin-bottom: 10px;
		font-size: 14px;
	}
	.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
		border-radius: 5px!important;
	}
	label img {
		margin-left: 5px;
	}
	img {
		filter: brightness(0) invert(1);
		position: relative;
		bottom: 1px;
	}
	.input-group {
		position: relative;
	}
	.input-group-append {
		position: absolute;
		right: 15px;
		top: 10px;
	}
	div#results {
		padding: 0;
	}
	p.result-text {
		font-size: 14px;
		max-width: 100%;
	}
	.result-text.padd_t40 {
		padding: 0;
	}
	.analyza {
		p {
			margin:  0 0 25px!important;
			&:last-child {
				margin-bottom: 0!important;
			}
		}
	}
	.accordion-item {
		margin-bottom: 25px;
		padding: 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.accordion-item-heading {
		padding: 0;
	}
	.accordion-item-heading-titles h3 {
		margin-bottom: 10px;
		font-size: 18px;
	}
	#no-results {
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.plain-info-btn {
		position: static;
		height: auto;
		width: auto;
		font-size: 15px!important;
		margin-bottom: 50px;
	}
	input[type=text], input[type=email], input[type=password], input[type=phone], input[type=tel], input[type=date], input[type=number], textarea, select {
		padding-right: 70px;
	}
	.accordion-header label.btn.btn-secondary.first-btn {
		margin-bottom: 0!important;
	}
	.accordion-header label{
		margin-bottom: 0;
	}
	.accordion-header .btn-secondary {
		font-size: 15px;
		height: 40px;
		display: flex!important;
		align-items: center;
		justify-content: center;
		line-height: 1.2;
		box-shadow: none;
	}
	.accordion-header .btn-secondary {
		border-radius: 5px;
		color: var(--background-color)!important;
		border: 1px solid transparent!important;
		cursor: pointer;
	}
	.btn-check:checked+.btn-secondary {
		border: 1px solid var(--primary-color)!important;
		background: var(--primary-color)!important;
		box-shadow: none!important;
		color: var(--white-color)!important;
	}
	span.result-red, span.result-red span {
		color: var(--white-color)!important;
	}
	p.result-number {
		font-size: 24px;
		color: var(--white-color);
	}
	.result-blue-small {
		font-size:  16px;
	}
	.analyza h3,
	.graf-title {
		font-size: 21px;
	}
	.dk-row h1 {
		font-size: 26px!important;
	}
	.dk-column-wrapper.dk-col-4,
	.dk-column-wrapper.dk-col-8 {
		flex: initial;
		max-width: initial;
	}

	.dk-column-wrapper.dk-col-4 {
		width: 330px;
	}
	.dk-column-wrapper.dk-col-8 {
		width: calc(100% - 330px - 15px);
	}
	.dk-column-wrapper.dk-col-12 {
		padding: 0;
		h1 {
			text-align: left;
		}
	}
	.chart-container svg {
		max-width: 300px;
	}

	.tip-pre-vas {
		background: transparent;
		padding: 0;
		border: none;
	}
	.dk-row h4 {
		margin-bottom: 15px;
	}

	#zhrnutie-penzii p {
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	#zhrnutie-penzii .tip-pre-vas p.tip-title {
		font-weight: 600;
		font-size: 16px;
	}

	#zhrnutie-penzii {
		padding: 0;
		margin-top: 50px;
	}

	.empty-space.s-30 {
		display: none;
	}

	#pilier4zhrnutie {
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
	}

	@media(max-width: 1220px){
		.dk-column-wrapper.dk-col-4 {
			width: 300px;
		}
		.dk-column-wrapper.dk-col-8 {
			width: calc(100% - 300px - 15px);
		}
	}

	@media(max-width: 1000px){
		.dk-column-wrapper.dk-col-4 {
			width: 100%;
			margin-right: 0;
			margin-bottom: 15px;
		}
		.dk-column-wrapper.dk-col-8 {
			width: 100%;
		}
		.dk-column-wrapper.dk-col-8.calc-right-result {
			margin: 0;
		}
	}
}

.accordion-collapse.collapse {
	display: none;
	margin-top: 15px;
	&.show {
		display: block;
	}
}
</style>