<template>
	<default-popup extra-class="ocr-popup">
		<div class="content">
			<div class="icon">
				<i class="fa-thin fa-id-card"></i>
			</div>
			<h2 class="popup-title">{{ $t('forms.require_camera_device') }}</h2>
			<div class="qr-code-box" v-if="link">
				<qr-code :text="link"/>
			</div>
			<h3>{{ $t('forms.or_download_app') }}</h3>
			<store-buttons-box/>
			<div class="actions">
				<button class="button style-3" @click.prevent="closePopup">
					<span class="inner">{{ $t('forms.close') }}</span>
				</button>
			</div>
		</div>
	</default-popup>
</template>

<script>
	import DefaultPopup from "./DefaultPopup";
	import StoreButtonsBox from "../containers/StoreButtonsBox";

	export default {
		name: "SetupPinPopup",
		components: {
			DefaultPopup,
			StoreButtonsBox,
		},
		props: ['link'],
		data() {
			return {}
		},
		methods: {
			closePopup() {
				this.$store.commit('CLOSE_OCR_POPUP');
			}
		},
	}
</script>

<style scoped lang="scss">


	h3 {
		font-size: 21px;
	}

	.store-buttons-box {
		margin-bottom: 50px;
		justify-content: center;
	}

	.qr-code-box {
		max-width: 175px;
		margin: 50px auto;
	}


</style>