<template>
    <div class="article-boxes-container" :class="extraClasses">
        <article-box v-for="item in articles" :key="item.id" :data="item"/>
    </div>
</template>

<script>
    import ArticleBox from "./ArticleBox";

    export default {
        name: "ArticleBoxesContainer",
        props: ['articles', 'columns'],
        components: {
            ArticleBox
        },
        computed: {
            extraClasses(){
                let extraClasses = [];

                if (this.$store.state.app.native){
                    extraClasses.push('with-scroll');
                }

                if (this.columns === 4){
                    extraClasses.push('four-columns');
                }

                return extraClasses;
            },
        },
    }
</script>

<style scoped lang="scss">
    .article-boxes-container.four-columns {
        @media(min-width: 992px){
            justify-content: flex-start;
            .article-box {
                width: calc(25% - (60px / 4));
                margin-right: 20px;
                &:nth-child(4n + 4){
                    margin-right: 0;
                }
                &:nth-child(n + 3){
                    margin-top: 0;
                }
                &:nth-child(n + 5){
                    margin-top: 20px;
                }
            }
        }
    }

    .article-boxes-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .article-box {
            width: calc(50% - 10px);
            &:nth-child(n + 3){
                margin-top: 20px;
            }
        }
        @media($mobileM) {
            .article-box {
                width: 100%;

                &:nth-child(n +2) {
                    margin-top: 20px;
                }
            }
        }

        &.with-scroll {
            @media($mobileM){
                padding: 0 15px;
                margin-left: -15px;
                margin-right: -15px;
                overflow-x: auto;
                flex-wrap: nowrap;
                .article-box {
                    width: 80%;
                    min-width: 80%;
                    margin-right: 20px;
                    margin-top: 0;
                    &:last-child {
                        margin-top: 0;
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>