<template>
	<nav class="main-nav" :class="active" @click.stop>
		<ul class="pages-list">
			<li v-for="(item, index) in pages" :key="index">
				<router-link :to="item.path"
				             v-html="`${item.icon} <span class='page-list-title'>${item.title}</span>`"/>
			</li>
		</ul>
		<div class="actions">
			<broker-logo/>
			<button class="button style-1 logout-button" @click="logout">
                <span class="inner"><i class="fa-thin fa-right-from-bracket"></i><span
		                class="button-title">{{ $t('auth.log_out') }}</span></span>
			</button>
		</div>
	</nav>
</template>

<script>
	import BrokerLogo from "./BrokerLogo";

	export default {
		name: "MainNav",
		components: {
			BrokerLogo
		},
		data() {
			return {
				pages: [
					{
						icon: '<i class="fa-thin fa-house-blank"></i>',
						title: this.$t('routes.home.title'),
						path: this.$t('routes.home.path')
					},
					{
						icon: '<i class="fa-thin fa-chart-line-up"></i>',
						title: this.$t('routes.investments.title'),
						path: this.$t('routes.investments.path')
					},
					{
						icon: '<i class="fa-thin fa-layer-group"></i>',
						title: this.$t('routes.online_services.title'),
						path: this.$t('routes.online_services.path')
					},
					{
						icon: '<i class="fa-thin fa-file-contract"></i>',
						title: this.$t('routes.documents.title'),
						path: this.$t('routes.documents.path')
					},
					{
						icon: '<i class="fa-thin fa-hand-holding-seedling"></i>',
						title: this.$t('routes.products.title'),
						path: this.$t('routes.products.path')
					},
					{
						icon: '<i class="fa-thin fa-address-book"></i>',
						title: this.$t('routes.contacts.title'),
						path: this.$t('routes.contacts.path')
					},
					{
						icon: '<i class="fa-thin fa-line-columns"></i>',
						title: this.$t('routes.articles.title'),
						path: this.$t('routes.articles.path')
					},
					{
						icon: '<i class="fa-thin fa-id-card"></i>',
						title: this.$t('routes.cards.title'),
						path: this.$t('routes.cards.path')
					},
				]
			}
		},

		computed: {
			active() {
				if (!this.$store.state.app.showNav) {
					return '';
				}

				return 'active';
			}
		},

		methods: {
			logout() {
				this.$store.dispatch('logout');
			},
			hideNav() {
				this.$store.commit('UNSET_SHOW_NAV');
			}
		},

		mounted() {
			document.addEventListener('click', this.hideNav);
		},

		destroyed() {
			document.removeEventListener('click', this.hideNav);
		}
	}
</script>

<style lang="scss">
	.light-mode .main-nav {
		background: #F0F0F2;

		.pages-list {
			li {
				border-bottom: 1px solid rgba(var(--background-color-rgb), .05);
			}

			a {
				color: rgba(var(--background-color-rgb), .3);

				i {
					color: rgba(var(--background-color-rgb), .5);
				}

				&:hover {
					color: var(--background-color, $backgroundColor)
				}

				&.router-link-active:not([href="/"]),
				&.router-link-exact-active {
					color: var(--background-color, $backgroundColor);
				}
			}
		}

		@media($mobileM) {
			background: var(--white-color, $whiteColor);
			.pages-list {
				li:last-child {
					border-bottom: 1px solid rgba(var(--background-color-rgb), .05);
				}

				a {
					color: var(--background-color);

					i {
						color: var(--primary-color, $primaryColor);
					}
				}

			}
			.logout-button.style-1 {
				background: transparent;

				i {
					color: var(--primary-color, $primaryColor);
				}
			}
		}
	}

	.main-nav {
		//background: transparentize($blackColor, .5);
		background: rgba(var(--black-color-rgb), .5);
		padding: 50px 30px;
		//height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: 11;

		.actions {
			.broker-logo {
				display: block;
				text-align: center;
				max-width: 175px;
				margin: 0 auto 25px;
				@media($tabletM) {
					display: none;
				}
				@media($mobileM) {
					display: block;
					margin-bottom: 0;
				}
			}
		}

		.pages-list {
			li {
				padding: 16px 0;
				//border-bottom: 1px solid transparentize($whiteColor, .9);
				border-bottom: 1px solid rgba(var(--white-color-rgb), .1);

				&:first-child {
					padding-top: 0;
				}

				&:last-child {
					border-bottom: 0;
					padding-bottom: 0;
				}
			}

			a {
				display: flex;
				align-items: center;
				font-weight: 600;
				font-size: 18px;
				color: var(--font-color, $fontColor);

				i {
					font-size: 21px;
					display: inline-block;
					width: 22px;
					text-align: center;
					margin-right: 20px;
				}

				&:hover {
					color: var(--white-color, $whiteColor);
				}

				&.router-link-active:not([href="/"]),
				&.router-link-exact-active {
					color: var(--white-color, $whiteColor);

					i {
						color: var(--secondary-color, $primaryColor);
					}
				}
			}
		}

		.actions {
			.logout-button {
				i {
					display: none;
				}
			}

			.button {
				width: 100%;
			}
		}

		@media($desktopM) {
			padding: 50px 20px;
			.pages-list {
				a {
					font-size: 16px;

					i {
						font-size: 18px;
						width: 18px;
						margin-right: 10px;
					}
				}
			}
			.actions {
				.button {
					padding: 15px;
				}
			}
		}
		@media($tabletM) and (min-width: 481px) {
			.pages-list {
				a {
					justify-content: center;

					i {
						margin-right: 0;
						width: initial;
						font-size: 21px;
					}

					.page-list-title {
						display: none;
					}
				}
			}
			.actions {
				text-align: center;

				.logout-button {
					width: initial;
					background: transparent;
					padding: 0;
					border: none;

					i {
						display: inline-block;
						font-size: 21px;
						color: var(--font-color, $fontColor);
					}

					.button-title {
						display: none;
					}

					&:hover {
						i {
							color: var(--white-color, $whiteColor);
						}
					}
				}
			}
		}
		@media ($mobileM) {
			padding: 25px 20px 93px;
			background: var(--background-color, $backgroundColor);
			top: auto;
			transition: $animationDefaultDuration;
			transform: translateY(120%);
			.broker-logo {
				display: block;
				position: absolute;
				bottom: calc(100% + 25px);
				z-index: 3;
				left: 15px;
				right: 15px;
				text-align: center;
				pointer-events: none;
			}
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 100%;
				top: -1000px;
				background: var(--background-color, $backgroundColor);
				z-index: 1;
				pointer-events: none;
				opacity: 0;
				transition: $animationDefaultDuration;
			}
			&:after {
				content: '';
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				display: block;
				position: absolute;
				pointer-events: none;
				box-shadow: 0 -40px 80px black;
				z-index: 2;
			}
			&.active {
				transform: none;

				&:before {
					opacity: .89;
				}
			}
			.pages-list {
				margin-bottom: 0;
				position: relative;
				z-index: 2;

				a {
					display: flex;
					align-items: center;
					font-size: 18px;
					text-align: left;
					justify-content: flex-start;

					.page-list-title {
						display: block;
					}

					i {
						font-size: 21px;
						width: 22px;
						text-align: center;
						margin-right: 20px;
						color: var(--secondary-color, $primaryColor);
					}
				}

				li {
					padding: 14px 0;

					&:last-child {
						//border-bottom: 1px solid transparentize($whiteColor, .9);
						border-bottom: 1px solid rgba(var(--white-color-rgb), .1);
						padding-bottom: 14px;
					}
				}
			}
			.actions {
				.logout-button {
					font-size: 18px;
					text-align: left;
					padding: 14px 0;
					color: var(--font-color, $fontColor);
					background: transparent;
					text-transform: none;

					.inner {
						align-items: center;
						display: flex;
					}

					&:hover {
						color: var(--white-color, $whiteColor);
					}

					i {
						display: flex;
						width: 22px;
						margin-right: 20px;
						font-size: 21px;
						text-align: center;
						color: var(--secondary-color, $primaryColor);
					}

					.button-title {
						display: block;
					}

					&:hover {
						background: transparent;
					}
				}

				.button {
					width: 100%;
				}
			}
		}
	}
</style>