<template>
	<section class="public-content-section" v-if="$t('partners').length">
		<article>
			<div class="content-wrap">
				<div class="partner-boxes-container">
					<div class="partner-box" v-for="(item, index) in $t('partners')">
						<figure>
							<img :src="getLocalAsset(`img/partner-box/${item}`)">
						</figure>
					</div>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
	import pageFunctionsMixin from "@/mixins/pageFunctionsMixin";
	export default {
		name: "PartnersSection",
		mixins: [pageFunctionsMixin],
	}
</script>

<style scoped lang="scss">
	.partner-boxes-container {
		display: flex;
		justify-content: center;

		.partner-box {
			width: 16.666%;
			border-right: 1px solid transparentize($backgroundColor, .9);
			display: flex;
			align-content: center;
			justify-content: center;
			flex-direction: column;
			text-align: center;
			padding: 0 15px;

			&:last-child {
				border-right: 0;
			}
			figure {
				max-width: 225px;
				margin: 0 auto;
			}
			img {
				max-height: 70px;
			}
		}

		@media($tabletL){
			flex-wrap: wrap;
			.partner-box {
				width: calc(33.333%);
				@media(min-width: 651px){
					&:nth-child(3n+3){
						border-right: none;
					}
				}
			}
		}

		@media($tabletS) {
			.partner-box {
				width: 50%;

				&:nth-child(2n + 2) {
					border-right: none;
				}
			}
		}
	}
</style>