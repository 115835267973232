<template>
	<div class="icon-link-box" :class="extraClass">
		<a :href="data.url" v-if="data.anchor" :target="data.target" @click="log">
			<div class="inner">
				<div class="actions" v-if="data.editLink">
					<router-link class="edit-link" :to="data.editLink.url" v-if="!data.editLink.anchor"><i
							class="fa-thin fa-pencil"></i></router-link>
					<a class="edit-link" :href="data.editLink.url" :target="data.editLink.target" v-else><i
							class="fa-thin fa-pencil"></i></a>
				</div>
				<small class="count" v-if="data.count">{{ data.count }}</small>
				<div class="icon" v-html="data.icon"></div>
				<h3 class="icon-link-title">{{ data.name }}</h3>
			</div>
		</a>
		<a href="#" v-else-if="data.action || data.mutation" @click.stop.prevent="processAction">
			<div class="inner">
				<div class="actions" v-if="data.editLink">
					<router-link :to="data.editLink.url" v-if="!data.editLink.anchor"><i class="fa-thin fa-pencil"></i>
					</router-link>
					<a :href="data.editLink.url" :target="data.editLink.target" v-else><i class="fa-thin fa-pencil"></i></a>
				</div>
				<small class="count" v-if="data.count">{{ data.count }}</small>
				<div class="icon" v-html="data.icon"></div>
				<h3 class="icon-link-title">{{ data.name }}</h3>
			</div>
		</a>
		<router-link :to="data.url" :target="data.target" v-else>
			<div class="inner">
				<div class="actions" v-if="data.editLink">
					<router-link :to="data.editLink.url" v-if="!data.editLink.anchor"><i class="fa-thin fa-pencil"></i>
					</router-link>
					<a :href="data.editLink.url" :target="data.editLink.target" v-else><i class="fa-thin fa-pencil"></i></a>
				</div>
				<small class="count" v-if="data.count">{{ data.count }}</small>
				<div class="icon" v-html="data.icon"></div>
				<h3 class="icon-link-title">{{ data.name }}</h3>
			</div>
		</router-link>

	</div>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";

	export default {
		name: "IconLinkBox",
		props: ['data', 'extraClass'],
		mixins: [pageFunctionsMixin],
		methods: {
			processAction() {
				if (!this.data.action && !this.data.mutation) {
					return false;
				}

				if (this.data.action){
					this.$store.dispatch(this.data.action, this.data);
				}

				if (this.data.mutation){
					this.$store.commit(this.data.mutation, this.data);
				}
			},
			log() {
				if (!this.data.log) {
					return false;
				}

				this.logActivity(this.data.type, this.data.logValue);
			}
		}
	}
</script>

<style scoped lang="scss">
	.default-sidebar {
		.icon-link-box {
			.inner {
				padding: 25px 20px;
			}

			.icon {
				font-size: 24px;
				color: var(--primary-color, $primaryColor);
				transition: $animationDefaultDuration;
			}

			&:hover {
				.icon {
					color: var(--white-color, $whiteColor);
				}
			}
		}
	}

	.light-mode {
		.icon-link-box {
			.inner {
				background: #F0F0F2;

				.icon {
					color: var(--background-color, $backgroundColor);
				}
			}

			.edit-link {
				background: var(--background-color, $backgroundColor);
				color: $whiteColor;

				&:hover {
					background: var(--primary-color, $primaryColor);
				}
			}
		}

		.default-sidebar-inner {
			.icon-link-box {
				.inner {
					background: #e2e3e6;
				}
			}
		}

		.icon-link-box.color-icon {
			.icon {
				color: var(--primary-color, $primaryColor);
			}

			&:hover {
				.icon {
					color: var(--background-color, $backgroundColor);
				}
			}
		}
	}

	.sections-container .content-section .icon-link-box .inner {
		padding: 26px;
	}

	.icon-link-box.color-icon {
		.icon {
			color: var(--primary-color, $primaryColor);
			text-shadow: 0 3px 8px var(--primary-color, $primaryColor);
			transition: $animationDefaultDuration;
		}

		&:hover {
			.icon {
				color: var(--white-color, $whiteColor);
				text-shadow: none;
			}
		}
	}


	.icon-link-box {
		> a {
			display: block;
			height: 100%;
		}

		.inner {
			color: var(--white-color, $whiteColor);
			padding: 50px 35px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			text-align: center;
			//background: transparentize($whiteColor, .94);
			background: rgba(var(--white-color-rgb), .06);
			height: 100%;
			border-radius: 5px;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				background: var(--primary-gradient, $primaryGradient);
				border-radius: 5px;
				opacity: 0;
				z-index: 1;
				transition: $animationDefaultDuration;
			}

			* {
				position: relative;
				z-index: 2;
			}

			.count {
				position: absolute;
				top: -6px;

				background: var(--white-color, $whiteColor);
				color: var(--background-color, $backgroundColor);
				font-size: 17px;
				font-weight: 700;
				padding: 7px;
				border-radius: 4px;
				line-height: 1;
				//box-shadow: 0 7px 5px transparentize($blackColor, .9);
				box-shadow: 0 7px 5px rgba(var(--black-color-rgb), .1);
				opacity: 1;
				transition: $animationDefaultDuration;

				&:after {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 4px 4px 0 4px;
					border-color: var(--white-color, $whiteColor) transparent transparent transparent;
					bottom: -4px;
					left: 0;
					right: 0;
					margin: 0 auto;
				}
			}
		}

		.actions {
			position: static;
		}

		.edit-link {
			position: absolute;
			background: $whiteColor;
			width: 25px;
			height: 25px;
			border-radius: 100%;
			color: var(--primary-color, $primaryColor);
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 1;
			top: 7px;
			right: 7px;
			font-size: 12px;

			&:hover {
				background: var(--background-color, $backgroundColor);
				color: $whiteColor;
			}
		}

		.icon {
			font-size: 32px;
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.icon-link-title {
			font-size: 14px;
			text-transform: uppercase;
			font-weight: 600;
			line-height: 1.28;
			//max-width: 125px;
			flex-grow: 1;
		}

		&:hover {
			.count {
				opacity: 1;

			}

			.inner:before {
				opacity: 1;
			}
		}
	}

	@media($mobileM) {
		.icon-link-box {
			.inner {
				padding: 25px;
			}
		}
	}
</style>