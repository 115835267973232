<template>
	<field-box v-if="type === 'monthly'" name="notification" type="select" :options="options" :label="$t('forms.day_of_notification')"
	           :key="key" :disabled="disabled" :value="value" :prefix="prefix"/>
	<field-box v-else-if="type === 'quarterly'" name="notification" type="select" :options="options"
	           :label="$t('forms.day_of_notification')" :key="key" :disabled="disabled" :value="value" :prefix="prefix"/>
	<field-box v-else type="date" :label="$t('forms.day_of_notification')" name="notification" :key="key" date-format="d.m"
	           :disabled="disabled" :value="value" :prefix="prefix"/>
</template>

<script>
	import FieldBox from "./FieldBox";

	export default {
		name: "NotificationField",
		components: {
			FieldBox
		},
		props: ['type', 'disabled', 'value', 'prefix'],
		data() {
			return {
				key: 0,
			}
		},
		watch: {
			type() {
				this.key++;
			},
		},
		computed: {
			options() {
				if (this.type === 'yearly') {
					return false;
				}

				let options = [];
				let days = 30;
				if (this.type === 'quarterly') {
					days = 90;
				}

				for (let i = 1; i <= days; i++) {
					let data = {
						title: `${i}.`,
						value: i
					};

					if (i === parseInt(this.value)){
						data.selected = true;
					}

					options.push(data);
				}

				return options;
			}
		},
		methods: {},
		mounted() {

		}
	}
</script>

<style scoped>

</style>