<template>
	<form class="investments-options-form" v-if="clientOptions.length">
		<field-box name="intranet_id" type="select" :options="clientOptions"/>
	</form>
</template>

<script>
	import FieldBox from "../form-fields/FieldBox";

	export default {
		name: "InvestmentsOptionsForm",
		props: ['type'],
		components: {
			FieldBox
		},
		data() {
			return {
				intranet_id: '',
				selectedClient: this.$store.state.views.selectedInvestmentsClient,
			}
		},
		computed: {
			clientOptions() {
				let options = [];
				if (!this.$store.state.auth.user || !this.$store.state.auth.user.relations || !this.$store.state.auth.user.relations.length) {
					return false;
				}

				let relations = this.$store.state.auth.user.relations;
				options.push({
					value: '',
					title: this.$t('online_services.choose_client'),
					placeholder: true,
				});

				if (this.$store.state.auth.user.intranet_id){
					options.push({
						value: this.$store.state.auth.user.intranet_id,
						title: this.$store.state.auth.user.full_name
					});
				}

				relations.forEach(item => {
					let name = [];
					if (item.first_name) {
						name.push(item.first_name);
					}
					if (item.last_name) {
						name.push(item.last_name);
					}
					name = name.join(' ');
					let selected = false;
					if(item.id === this.selectedClient){
						selected = true;
					}
					options.push({
						value: item.id,
						title: name,
						selected: selected,
					});
				});

				return options;
			},
		},
		methods: {
			processFieldChange(data) {
				if (data.value === this.$store.state.auth.user.intranet_id){
					this.intranet_id = '';
				} else {
					this.intranet_id = data.value;
				}

				this.regenerateInvestments();
			},

			regenerateInvestments() {
				this.$store.commit('SET_SELECTED_INVESTMENTS_CLIENT', this.intranet_id);
				this.$store.dispatch('getInvestmentsData', true);
			}
		},
		mounted() {
			this.$root.$on('field-changed', (data) => {
				this.processFieldChange(data);
			});
		},
		destroyed() {
			this.$root.$off('field-changed');
		}
	}
</script>

<style scoped lang="scss">
	.investments-options-form {
		display: flex;
		justify-content: space-between;

		.field-box {
			width: calc(50% - 2px);
		}
	}
</style>