<template>
	<div class="barcode-overlay" @click.stop>
		<h2 class="title">{{ $t('forms.scan_barcode') }}</h2>
		<div class="inner">
			<div class="line"></div>
		</div>
		<div class="actions">

		</div>
		<div class="actions">
			<button class="button style-1" @click.prevent="closeBarcodeScanner">
				{{ $t('forms.manual_enter') }}
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CameraOverlay",
		methods: {
			closeBarcodeScanner() {
				this.$root.$emit('close-barcode-scanner');
			}
		}
	}
</script>

<style scoped lang="scss">
	.barcode-overlay {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 10000;
		overflow: hidden;

		&:before,
		&:after {
			content: '';
			left: 0;
			right: 0;
			background: var(--background-color, $backgroundColor);
			height: calc(50% - 85px);
			display: block;
			position: absolute;
		}

		&:before {
			top: 0;
		}

		&:after {
			bottom: 0;
		}

		.line {
			position: absolute;
			left: -5px;
			right: -5px;
			height: 2px;
			top: calc(50% - 1px);
			background: var(--primary-gradient, $primaryGradient);
			z-index: 2;
		}

		.inner {
			position: absolute;
			left: 5px;
			right: 5px;
			top: calc(50% - 85px);
			bottom: calc(50% - 85px);
			border-radius: 10px;

			&:before,
			&:after {
				content: '';
				position: absolute;
				bottom: -8px;
				top: -8px;
				left: -8px;
				right: -8px;
				border: 8px solid var(--background-color, $backgroundColor);
				border-radius: 13px;
				display: block;
			}

			&:before {
				left: -7px;

			}

			&:after {
				right: -7px;
			}

		}

		.actions {
			position: absolute;
			left: 15px;
			right: 15px;
			bottom: 50px;
			z-index: 2;

			.button {
				width: 100%;
			}
		}

		.title {
			position: absolute;
			left: 15px;
			right: 15px;
			text-align: center;
			top: 10%;
			font-size: 30px;
			max-width: 300px;
			margin: 0 auto;
		}
	}
</style>