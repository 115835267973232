<template>
	<default-sidebar :title="sidebar.title" :active="$store.state.sidebars.showOnlineServicesSidebar">
		<div class="image-link-boxes-container">
			<div class="image-link-box" v-for="(item, index) in data.group" :key="index" v-if="data.group && data.group.length">
				<a href="#" @click.prevent="processActions(item)">
					<div class="inner">
						<figure class="image" v-if="item.boxImage">
							<img :src="getImage(item.boxImage.filename)">
						</figure>
						<h4 class="image-link-title">{{ item.name }}</h4>
					</div>
				</a>
			</div>
		</div>
	</default-sidebar>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	import DefaultSidebar from "./DefaultSidebar";

	export default {
		name: "OnlineServicesSidebar",
		mixins: [pageFunctionsMixin],
		components: {
			DefaultSidebar,
		},
		computed: {
			data(){
				return this.$store.state.sidebars.showOnlineServicesSidebar;
			},
			sidebar(){
				return {
					title: this.data?.name,
				}
			}
		},
		methods: {
			processActions(item) {
				this.$store.dispatch('closeSidebars');
				if (item.action){
					this.$store.dispatch(item.action, item);
					return;
				}

				if (item.mutation){
					this.$store.commit(item.mutation, item);
					return;
				}

				this.redirectToService(item.url);
			},
			redirectToService(url) {
				if (this.$route.path !== url) {
					this.$router.push(url);
				}
			},
		},
	}
</script>

<style scoped lang="scss">
	.light-mode {
		p {
			color: var(--background-color, $backgroundColor);
		}
	}

	p {
		text-align: center;
		color: $whiteColor;
		margin-bottom: 50px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.image-link-boxes-container {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.image-link-box {
			width: calc(50% - 8px);

			&:nth-of-type(n + 3) {
				margin-top: 16px;
			}
		}

		@media($mobileS) {
			flex-direction: column;
			.image-link-box {
				width: 100%;
				margin-bottom: 16px;

				&:nth-of-type(n + 3) {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.image-link-box {
		text-align: center;

		.image {
			background: $whiteColor;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 120px;
			margin-bottom: 12px;
			img {
				max-width: 130px;
			}
		}

		.image-link-title {
			font-size: 16px;
			transition: $animationDefaultDuration;
		}

		&:hover {
			.image-link-title {
				color: var(--primary-color, $primaryColor);
			}
		}
	}
</style>