<template>
	<component class="value-box" :is="componentType" :href="data.url" :target="data.target">
		<figure class="icon" v-if="data.icon" v-html="data.icon"></figure>
		<div class="text">
			<h4 class="title">{{ data.title }}</h4>
			<p class="value" :class="data.valueOverflow ? 'overflow': ''">{{ data.value }}</p>
		</div>
	</component>
</template>

<script>
	export default {
		name: "ValueBox",
		props: ['data'],
		computed: {
			componentType(){
				if (this.data.url){
					return 'a';
				}

				return 'div';
			}
		},
		mounted() {

		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.value-box {
			background: #F0F0F2;
			.icon {
				color: var(--background-color, $backgroundColor);
			}
			.title {
				color: var(--secondary-font-color, $secondaryFontColor);
			}
			.value {
				color: var(--background-color, $backgroundColor);
			}
		}
	}
	a.value-box {
		position: relative;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: -4px;
			right: -4px;
			bottom: -4px;
			left: -4px;
			pointer-events: none;
			border: 2px solid var(--secondary-color, $primaryColor);
			border-radius: 8px;
			opacity: 0;
			transition: $animationDefaultDuration;
		}
		&:hover {
			&:before {
				opacity: 1;
			}
		}
	}
	.text {
		overflow: hidden;
	}
	.value-box {
		border-radius: 5px;
		background: rgba(var(--white-color-rgb), .06);
		display: flex;
		align-items: center;
		padding: 15px 20px;

		.icon {
			width: 40px;
			height: 40px;
			min-width: 40px;
			border-radius: 3px;
			margin-right: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			color: var(--white-color);
			background: var(--primary-gradient);
			font-size: 19px;

		}
		.title {
			font-size: 10px;
			font-weight: 400;
			margin-bottom: 3px;
			color: var(--font-color);

		}
		.value {
			font-size: 13px;
			font-weight: 600;
			color: var(--white-color);
			&.overflow {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
			}
		}
	}
</style>