<template>
	<auth-popup v-if="$store.state.popups.showLoginPopup" :extra-class="extraClass">
		<div class="login-popup-box">
			<header class="popup-header">
				<h3 class="popup-title">{{ greeting }}</h3>
				<p>{{ $t('auth.login_text') }}</p>
			</header>
			<article>
				<social-auth-box type="login"/>
				<p class="divider"><span class="divider-inner">{{ $t('general.or') }}</span></p>
				<login-form/>
				<div class="actions">
					<button class="forgot-password" @click.prevent="openResetPasswordPopup">{{ $t('auth.forgot_password') }}</button>
					<button class="button style-1" @click.prevent="openRegistrationPopup" v-if="!$store.state.auth.userDefaultId">
						<span class="inner">{{ $t('auth.create_account') }}</span>
					</button>
				</div>
			</article>
		</div>
	</auth-popup>
</template>

<script>
	import AuthPopup from "./AuthPopup";
	import SocialAuthBox from "../containers/SocialAuthBox";
	import LoginForm from "../forms/LoginForm";

	export default {
		name: "LoginPopup",
		components: {
			AuthPopup,
			SocialAuthBox,
			LoginForm
		},
		data() {
			return {
				extraClass: 'login-popup'
			}
		},
		computed: {
			greeting() {
				let hours = new Date().getHours();
				if (hours <= 7 && hours > 4) {
					return this.$t('greetings.morning');
				} else if (hours <= 16) {
					return this.$t('greetings.day');
				} else {
					return this.$t('greetings.evening');
				}
			},
		},
		methods: {
			openRegistrationPopup() {
				this.$store.dispatch('openRegistrationPopup');
			},
			openResetPasswordPopup() {
				this.$store.dispatch('openResetPasswordPopup');
			}
		}
	}
</script>

<style scoped lang="scss">

	.actions {
		margin-top: 20px;
		text-align: center;
		display: flex;
		flex-direction: column;
	}

	.forgot-password {
		font-size: 12px;
		font-weight: 600;
		text-transform: uppercase;
		//color: transparentize($whiteColor, .5);
		color: rgba(var(--white-color-rgb), .5);
		display: inline-block;
		background: transparent;
		border: none;
		cursor: pointer;
		transition: $animationDefaultDuration;
		margin-bottom: 35px;

		&:hover {
			color: var(--white-color, $whiteColor);
		}
	}
</style>