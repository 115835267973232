<template>
	<div class="contact-info-box">
		<icon-link-boxes-container :data="iconLinkBoxes"/>
		<div class="agent-container" v-if="agentData">
			<user-box :data="agentData"/>
		</div>
		<nearest-branch-box/>
	</div>
</template>

<script>
	import IconLinkBoxesContainer from "./IconLinkBoxesContainer";
	import UserBox from "./UserBox";
	import NearestBranchBox from "./NearestBranchBox";

	export default {
		name: "ContactInfoBox",
		components: {
			IconLinkBoxesContainer,
			UserBox,
			NearestBranchBox,
		},
		data() {
			return {
				iconLinkBoxes: [
					{
						icon: '<i class="fa-thin fa-chart-user"></i>',
						name: this.$t('contacts.agents'),
						url: this.$t('routes.contacts.path')
					},
					{
						icon: '<i class="fa-thin fa-building"></i>',
						name: this.$t('contacts.branches'),
						url: `${this.$t('routes.contacts.path')}?tab=1`
					},
					{
						icon: '<i class="fa-thin fa-hospital"></i>',
						name: this.$t('contacts.partners'),
						url: `${this.$t('routes.contacts.path')}?tab=2`
					}
				]
			}
		},
		computed: {
			agentData() {
				if (!this.$store.state.auth.user.agent) {
					return false;
				}

				let agent = this.$store.state.auth.user.agent;

				return {
					avatar: agent.avatar,
					full_name: agent.fullName,
					description: this.$t('contacts.your_agent')
				};
			},
		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.agent-container {
			background: #F0F0F2;
		}
	}

	.agent-container {
		background: rgba(var(--white-color-rgb), .06);
		border-radius: 5px;
		padding: 15px;
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.icon-link-boxes-container {
		margin-bottom: 4px;

		&:last-child {
			margin-bottom: 0;
		}
	}
</style>