<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments_investika.title') }}</h2>
				</header>
				<article>
					<actions-panel>
						<router-link class="button style-1" :to="$t('routes.investments.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
					</actions-panel>
					<panel-boxes-container :data="accountsData"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import ActionsPanel from "@/components/panels/ActionsPanel";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";

export default {
	name: "InvestmentsInvestika",
	components: {
		ActionsPanel,
		PanelBoxesContainer
	},
	computed: {
		investmentsData() {
			return this.$store.state.views.investments;
		},
		accountsData() {
			let data = [];
			if (!this.investmentsData) {
				return data;
			}

			this.investmentsData.accounts_investika.forEach(item => {
				if (!item.asset) {
					return;
				}

				data.push({
					id: item.IDShareholderAccount,
					name: item.type,
					description: this.$t('investments.account_balance'),
					category: 'Investika',
					price: item.asset,
					detailUrl: `${this.$t('routes.investments_investika.path')}/${item.IDShareholderAccount}`,
					redirectDetail: true,
				});
			});

			return data;
		}
	}
}
</script>

<style scoped>

</style>