<template>
	<div class="chart-investment-info-group">
		<chart-boxes-container :data="chartsData"/>
		<investment-info-boxes-container :data="investmentInfoData"/>
	</div>
</template>

<script>
import ChartBoxesContainer from "@/components/containers/ChartBoxesContainer";
import InvestmentInfoBoxesContainer from "./InvestmentInfoBoxesContainer";

export default {
	name: "ChartPanelsGroup",
	props: ['investmentInfoData', 'chartsData'],
	components: {
		ChartBoxesContainer,
		InvestmentInfoBoxesContainer
	}
}
</script>

<style scoped lang="scss">
	.chart-investment-info-group {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 15px;
		&:last-child {
			margin-bottom: 0;
		}
		.chart-boxes-container {
			width: 40%;
		}
		.investment-info-boxes-container {
			width: calc(60% - 15px);
		}

		@media($desktopM){
			flex-direction: column;
			.chart-boxes-container {
				width: 100%;
				margin-bottom: 15px;
			}
			.investment-info-boxes-container {
				width: 100%;
			}
		}
	}
</style>