<template>
	<form class="documents-form" enctype="multipart/form-data" @submit.prevent="submitForm">
		<input type="hidden" name="_method" value="PUT">
		<div class="icon">
			<i class="fa-thin fa-file-contract"></i>
		</div>
		<errors-list :errors="errors"/>
		<div class="form-row">
			<default-field-box type="text" name="name" :label="$t('forms.document_title')"
			                   :required="true"
			                   :value="data.name"
			                   :disabled="!isEditing"/>
		</div>
		<div class="form-row">
			<field-box name="document_category_id" :label="$t('forms.category')" type="select" prefix="edit"
			           :value="data.document_category_id"
			           :options="documentCategoriesOptions" :disabled="!isEditing"/>
		</div>
		<div class="form-row">
			<field-box name="company_code" :label="$t('forms.company')" type="select" :options="companiesOptions"
			           :key="companyKey" :disabled="!isEditing"/>
		</div>
		<div class="form-row">
			<field-box name="number" :label="$t('forms.document_number')" type="text" :value="data.number"
			           :disabled="!isEditing"/>
		</div>
		<div class="form-row two-columns">
			<field-box name="total_amount" :label="$t('forms.total_amount')" type="text" prefix="edit"
			           :value="data.total_amount"
			           :disabled="!isEditing"/>
			<field-box name="signature_date" :label="$t('forms.signature_date')" type="date" prefix="edit"
			           :value="data.signature_date" :disabled="!isEditing"/>
		</div>
		<div class="form-row">
			<field-box name="payment_frequency" :label="$t('forms.payment_frequency')" type="select"
			           :options="paymentsFrequencyOptions" :disabled="!isEditing"/>
		</div>
		<div class="form-row two-columns" v-if="paymentFrequency !== 'once'">
			<field-box name="recurring_amount" :label="$t('forms.recurring_amount')" type="text" prefix="edit"
			           :value="data.recurring_amount" :disabled="!isEditing"/>
			<notification-field :type="paymentFrequency" :value="data.notification" :disabled="!isEditing"
			                    prefix="edit"/>
		</div>
		<div class="form-row two-columns">
			<field-box name="valid_from" :label="$t('forms.valid_from')" type="date" prefix="edit"
			           :value="data.valid_from"
			           :disabled="!isEditing"/>
			<field-box name="valid_to" :label="$t('forms.valid_to')" type="date" prefix="edit" :value="data.valid_to"
			           :disabled="!isEditing"/>
		</div>
		<div class="attachments-container" v-if="data.attachments.length">
			<attachment-preview-field v-for="attachment in data.attachments"
			                          :data="attachment"
			                          :allow-remove="isEditing"
			                          name="remove_attachments[]"
			                          :key="attachment.id"/>
		</div>
		<dropzone-box name="attachments[]" :label="$t('forms.upload_documents')" url="api/documents/attachment"
		              v-if="isEditing"/>
		<div class="form-row">
			<checkbox-switcher name="agent_visibility" :label="$t('forms.agent_visibility')"
			                   :checked="data.agent_visibility"
			                   icon='<i class="fa-thin fa-circle-check"></i>' :disabled="!isEditing"/>
		</div>
		<div class="form-actions">
			<button class="button style-4" type="button" v-if="!isEditing" @click.prevent="toggleEdit">
				<span class="inner">{{ $t('forms.edit') }}</span>
			</button>
			<button class="button style-4" type="submit" :class="loading ? 'loading' : ''" v-else>
				<span class="inner">{{ $t('forms.save') }}</span>
			</button>
			<button class="button style-1" type="button" @click="$store.commit('HIDE_DOCUMENTS_EDIT_FORM')">
				<span class="inner">{{ $t('forms.cancel') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import ErrorsList from "../containers/ErrorsList";
	import EditableValueField from "../form-fields/EditableValueField";
	import FieldBox from "../form-fields/FieldBox";
	import NotificationField from "../form-fields/NotificationField";
	import DropzoneBox from "../form-fields/DropzoneBox";
	import CheckboxSwitcher from "../form-fields/CheckboxSwitcher";
	import AttachmentPreviewField from "../form-fields/AttachmentPreviewField";
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";

	export default {
		name: "DocumentsEditForm",
		props: ['data'],
		components: {
			ErrorsList,
			EditableValueField,
			FieldBox,
			NotificationField,
			DropzoneBox,
			CheckboxSwitcher,
			AttachmentPreviewField,
			DefaultFieldBox
		},
		data() {
			return {
				isEditing: false,
				loading: false,
				errors: false,
				paymentFrequency: this.data.payment_frequency,
				document_category_id: this.data.document_category_id,
				company_code: this.data.company_code,
				company_name: this.data.company_name,
				companies: [],
				companyKey: 0,
			}
		},
		watch: {
			companiesOptions() {
				this.companyKey++;
			}
		},
		computed: {
			companiesOptions() {
				let companies = [];
				if (!this.document_category_id) {
					companies.push({
						value: '',
						title: this.$t('forms.choose_category_first')
					});

					return companies;
				}

				companies.push({
					value: '',
					title: this.$t('forms.choose_option')
				});

				if (!this.companies.length) {
					if (this.company_name && this.company_code) {
						companies.push({
							title: this.company_name,
							value: this.company_code,
							selected: true,
						});
					}

					return companies;
				}

				this.companies.forEach(item => {
					companies.push({
						title: item.title,
						value: item.value,
						selected: item.value === this.company_code,
					})
				});

				return companies;
			},
			paymentsFrequencyOptions() {
				let options = [
					{
						value: 'once',
						title: this.$t('forms.once')
					},
					{
						value: 'yearly',
						title: this.$t('forms.yearly')
					},
					{
						value: 'quarterly',
						title: this.$t('forms.quarterly')
					},
					{
						value: 'monthly',
						title: this.$t('forms.monthly')
					},
				];

				options.forEach(item => {
					if (item.value === this.data.payment_frequency) {
						item.selected = true;
					}
				})

				return options;
			},

			notificationOptions() {
				let options = [];
				for (let i = 1; i <= 31; i++) {
					options.push({
						value: i,
						title: i
					});
				}

				options.forEach(item => {
					if (item.value === this.data.notification) {
						item.selected = true;
					}
				});

				return options;
			},
			documentCategoriesOptions() {
				let categories = [];
				categories.push({
					value: '',
					title: this.$t('forms.choose_option')
				});
				this.$store.state.views.document_categories.forEach(item => {
					categories.push({
						value: item.id,
						title: item.name
					});

				});

				categories.forEach(item => {
					if (item.value === this.data.document_category_id) {
						item.selected = true;
					}
				});

				return categories;
			},
		},
		methods: {
			submitForm(e) {
				let form = e.target;
				let formData = new FormData(form);
				formData.append('company_name', this.company_name);

				this.loading = true;
				this.axios.post(`/api/documents/${this.data.id}`, formData).then(response => {
					this.loading = false;
					this.$store.dispatch('updateDocumentCategoryCounts', {
						old: this.data.document_category_id,
						new: response.data.document.document_category_id
					});
					this.$store.dispatch('updateDocument', {
						document: response.data.document,
						category_id: this.data.document_category_id
					});
					this.$store.commit('HIDE_DOCUMENTS_EDIT_FORM');

					if (parseInt(this.data.document_category_id) !== parseInt(response.data.document.document_category_id)) {
						this.$router.push(`${this.$t('routes.documents.path')}/${response.data.document.document_category_id}`);
					}
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}

					this.loading = false;
				});
			},
			resetForm(form) {
				form.reset();

				let attachmentInputs = form.querySelectorAll('input[name="attachments[]"]');
				if (attachmentInputs.length) {
					for (let attachmentInput of attachmentInputs) {
						attachmentInput.parentNode.removeChild(attachmentInput);
					}
				}

				let dzPreviews = form.querySelectorAll('.dz-file-preview');
				if (dzPreviews.length) {
					for (let dzPreview of dzPreviews) {
						dzPreview.parentNode.removeChild(dzPreview);
					}
				}

				let dropzone = form.querySelector('.dropzone');
				if (dropzone) {
					dropzone.classList.remove('dz-started');
				}
			},
			processPaymentFrequency(data) {
				this.paymentFrequency = data.value;
			},
			processDocumentCategory(data = false) {
				if (data) {
					this.document_category_id = data.value;
					this.company_name = '';
					this.company_code = '';
				}

				this.companies = [];

				if (this.document_category_id === '') {
					return false;
				}

				this.axios.post('/api/document-categories/get-companies', {
					document_category_id: this.document_category_id,
				}).then(response => {
					if (!response.data.companies) {
						return false;
					}

					Object.keys(response.data.companies).forEach(key => {
						if (key === '0') {
							return;
						}

						this.companies.push({
							value: key,
							title: response.data.companies[key].title,
						});
					});

					this.companies.push({
						value: 'other',
						title: this.$t('forms.other'),
					});
				}).catch(() => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: this.$t('general.default_error')
					});
				});
			},
			processCompany(data) {
				if (!data.value || !data.title) {
					this.company_name = '';
					this.company_code = '';
					return false;
				}

				this.company_name = data.title;
				this.company_code = data.value;
			},
			toggleEdit() {
				this.processDocumentCategory();
				this.isEditing = true;
			}
		},
		mounted() {
			this.$root.$on('field-changed', (data) => {
				switch (data.name) {
					case 'payment_frequency':
						this.processPaymentFrequency(data);
						break;
					case 'document_category_id':
						this.processDocumentCategory(data);
						break;
					case 'company_code':
						this.processCompany(data);
						break;
				}
			});
		},
		destroyed() {
			this.$root.$off('field-changed');
		}
	}
</script>

<style scoped lang="scss">
	.documents-form {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.form-group {
		label {
			font-size: 12px;
			text-align: center;
			text-transform: uppercase;
			font-weight: 600;
		}
	}


	.icon {
		text-align: center;
		font-size: 21px;
		margin-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.form-row {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		&.two-columns {
			display: flex;
			justify-content: space-between;
			flex-direction: row;

			.field-box {
				width: calc(50% - 8px);
			}
		}
	}

	.form-actions {
		margin-top: auto;
		display: flex;
		flex-direction: column;

		.button {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	@media($mobileS) {
		.form-actions {
			margin-top: 25px;
		}
		.form-row {
			margin-bottom: 10px;

			&.two-columns {
				flex-direction: column;

				.field-box {
					width: 100%;
					margin-bottom: 10px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
</style>