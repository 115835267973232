<template>
    <default-sidebar :active="$store.state.sidebars.showCardsCreateForm">
        <cards-create-form v-if="$store.state.views.card_categories.length && $store.state.sidebars.showCardsCreateForm"/>
    </default-sidebar>
</template>

<script>
    import DefaultSidebar from "./DefaultSidebar";
    import CardsCreateForm from "../forms/CardsCreateForm";

    export default {
        name: "CardsCreateFormSidebar",
        components: {
            DefaultSidebar,
            CardsCreateForm
        },
        data() {
            return {

            }
        },
    }
</script>

<style scoped lang="scss">

</style>