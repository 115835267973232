<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<article>
					<tab-boxes-container>
						<tab-box :title="$t('contacts.agents')">
							<agent-detail-box :data="agentData"/>
							<icon-link-boxes-container :data="agentLinks" extra-class="flex-grow-boxes"
							                           v-if="agentLinks"/>
						</tab-box>
						<tab-box :title="$t('contacts.branches')">
							<panel-boxes-container :data="branches"/>
						</tab-box>
						<tab-box :title="$t('contacts.partners')">
							<partner-boxes-container :data="partners"/>
						</tab-box>
					</tab-boxes-container>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
	import TabBoxesContainer from "../components/containers/TabBoxesContainer";
	import TabBox from "../components/containers/TabBox";
	import IconLinkBoxesContainer from "../components/containers/IconLinkBoxesContainer";
	import AgentDetailBox from "../components/containers/AgentDetailBox";
	import PanelBoxesContainer from "../components/containers/PanelBoxesContainer";
	import PartnerBoxesContainer from "@/components/containers/PartnerBoxesContainer";
	import branchesMixin from "../mixins/branchesMixin";

	export default {
		name: "Contacts",
		components: {
			TabBoxesContainer,
			TabBox,
			IconLinkBoxesContainer,
			AgentDetailBox,
			PanelBoxesContainer,
			PartnerBoxesContainer,
		},
		mixins: [branchesMixin],
		data() {
			return {

			}
		},
		computed: {
			defaultImage(){
				if (this.$store.state.app.appType === 'pluska'){
					return 'https://pluska.invelity.com/img/pluska-logo.svg';
				}
				return 'https://portos-test.universal.sk/universal/files/show-private-file/646266/20220425/b28080d49efb33d5e110bbad9d8fcffb900921ea79568fe59ce68257c293df0a';
			},
			branches() {
				let branches = [];
				if (!this.$store.state.views.branches.length) {
					return branches;
				}

				let image = this.defaultImage;
				if (this.$store.state.auth.user.agent) {
					image = this.$store.state.auth.user.agent.broker_data.broker_logo;
				}

				this.$store.state.views.branches.forEach(item => {
					branches.push({
						id: item.id_satelit,
						name: item.address_city,
						description: item.address_street,
						image: image,
						detailUrl: item.url,
						redirectDetail: true,
						imageObjectFit: 'contain',
						distance: this.currentPosition ? this.getDistance(this.currentPosition.lat, this.currentPosition.lng, item.lat, item.lng) : 0
					});
				});

				if (this.currentPosition){
					branches = this._.sortBy(branches, (item) => item.distance);
				} else {
					branches = this._.sortBy(branches, (item) => item.name);
				}

				return branches;
			},

			partners() {
				return this.$store.state.views.partners;

				let partners = [];
				if (!this.$store.state.views.partners.length) {
					return partners;
				}

				this.$store.state.views.partners.forEach(item => {
					partners.push({
						id: item.id,
						name: item.title,
						description: '',
						image: item.info.logo_link,
						detailUrl: item.url,
						redirectDetail: true,
						imageObjectFit: 'contain'
					});
				});

				partners = partners.sort((a, b) => a.name.localeCompare(b.name, 'sk', { numeric: true, sensitivity: 'base' }));

				return partners;
			},

			agentData() {
				let agent = this.$store.state.auth.user.agent;
				let data;
				if (agent) {
					data = {
						agent: true,
						full_name: agent.fullName,
						avatar: agent.avatar,
						description: this.$t('contacts.your_agent'),
						company: {
							name: agent.broker_data.broker_title,
							description: agent.broker_data.broker_url,
							image: agent.broker_data.broker_logo
						}
					};
				} else {
					data = {
						agent: false,
						company: {
							name: this.$t('contacts.default_company_name'),
							description: this.$t('contacts.default_company_link'),
							image: this.defaultImage
						}
					}
				}

				return data;
			},

			agentLinks() {
				if (!this.$store.state.auth.user.agent) {
					return false;
				}

				let agent = this.$store.state.auth.user.agent;

				let links = [];
				if (agent.broker_data.address) {
					links.push({
						id: 1,
						name: this.$t('contacts.show_map'),
						icon: '<i class="fa-thin fa-location-dot"></i>',
						url: 'https://www.google.sk/maps/place/Universal+makl%C3%A9rsky+dom+a.s./@48.1484797,17.0937666,17z/data=!3m1!4b1!4m5!3m4!1s0x476c8bf69b18b30b:0x773cf1275852a3e7!8m2!3d48.1484909!4d17.0959804?hl=sk',
						target: '_blank',
						anchor: true,
					});
				}
				if (agent.email) {
					links.push({
						id: 2,
						name: this.$t('contacts.contact_agent'),
						icon: '<i class="fa-thin fa-envelope-open"></i>',
						url: `mailto:${agent.email}`,
						anchor: true,
					});
				}
				if (agent.mobile) {
					links.push({
						id: 3,
						name: this.$t('contacts.call_agent'),
						icon: '<i class="fa-thin fa-mobile-button"></i>',
						url: `tel:${agent.mobile}`,
						anchor: true,
					});
				}

				return links;
			}
		}
	}
</script>

<style scoped lang="scss">
</style>