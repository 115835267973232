<template>
	<form class="dynamic-form" @submit.prevent="handleSubmit" ref="form">
		<ul class="form-page-list" v-if="form.length > 1">
			<li v-for="(i, index) in form.length" :key="index">
				<span class="form-page-item"
				      :class="currentPage === i ? 'active' : currentPage > i ? 'prev' : ''">{{ i }}</span>
			</li>
		</ul>
		<errors-list :errors="errors" ref="errorsList"/>
		<div class="page-content" v-for="(page, index) in form" :key="index" v-show="index + 1 === currentPage">
			<h2 class="page-content-title">{{ page.title }}</h2>
			<template v-if="page.type === 'repeater'">
				<div class="page-repeater-row" v-for="(repeaterRow, repeaterIndex) in getRepeaterRows(page)"
				     :key="repeaterRow">
					<dynamic-form-fieldset v-for="(section, sectionIndex) in page.sections"
					                       :key="sectionIndex"
					                       :section="section"
					                       :error-fields="errorFields"
					                       :page="page"
					                       :repeater-index="repeaterIndex"
					                       :show-hidden-sections="showHiddenSections"
					                       :show-hidden-fields="showHiddenFields"
					                       :enable-readonly-fields="enableReadonlyFields"
					                       @toggle-dynamic-item="processDynamicItem"/>
					<div class="remove-actions">
						<button class="button style-6 small" type="button"
						        @click.prevent="removeRepeaterRow(page, repeaterRow)">
							<span class="inner"><i class="fa-solid fa-minus"></i></span>
						</button>
					</div>
				</div>
				<div class="repeater-actions" v-if="page.max_repeats > getRepeaterRows(page).length">
					<button class="button style-7 small" type="button" @click.prevent="addRepeaterRow(page)">
						<span class="inner"><i class="fa-solid fa-plus"></i></span>
					</button>
				</div>
			</template>
			<template v-else>
				<dynamic-form-fieldset v-for="(section, sectionIndex) in page.sections"
				                       :key="sectionIndex"
				                       :section="section"
				                       :error-fields="errorFields"
				                       :page="page"
				                       :show-hidden-sections="showHiddenSections"
				                       :show-hidden-fields="showHiddenFields"
				                       :enable-readonly-fields="enableReadonlyFields"
				                       @toggle-dynamic-item="processDynamicItem"/>
			</template>
			<div class="actions" v-if="form.length > 1">
				<button class="button style-1 prev" v-if="currentPage > 1"
				        @click.prevent="currentPage--">
					<span class="inner">{{ $t('general.back') }}</span>
				</button>
				<button class="button style-3 next" v-if="currentPage < form.length" :class="loading ? 'loading' : ''"
				        @click.prevent="nextStep">
					<span class="inner">{{ $t('general.continue') }}</span>
				</button>
				<button class="button style-4 submit" v-else :class="loading ? 'loading' : ''">
					<span class="inner">{{ $t('forms.submit') }}</span>
				</button>
			</div>
			<div class="actions" v-else>
				<button class="button style-4 submit full-size" :class="loading ? 'loading' : ''">
					<span class="inner">{{ $t('forms.submit') }}</span>
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import DefaultFieldBox from "@/components/form-fields/DefaultFieldBox";
import CheckboxSwitcher from "@/components/form-fields/CheckboxSwitcher";
import ChoiceField from "@/components/form-fields/ChoiceField";
import FilePreview from '@/components/form-fields/FilePreview';
import ErrorsList from "@/components/containers/ErrorsList";
import DynamicFormFieldset from "@/components/form-fields/DynamicFormFieldset";

export default {
	name: "DynamicForm",
	props: ['form', 'storeUrl', 'validationUrl'],
	components: {
		DefaultFieldBox,
		CheckboxSwitcher,
		ChoiceField,
		FilePreview,
		ErrorsList,
		DynamicFormFieldset
	},
	data() {
		return {
			currentPage: 1,
			loading: false,
			errors: false,
			errorFields: [],
			repeaterRows: [],
			showHiddenSections: [],
			showHiddenFields: [],
			enableReadonlyFields: [],
		}
	},
	methods: {
		processDynamicItem(data) {
			let dynamicItem;
			switch (data.type){
				case 'section':
					dynamicItem = 'showHiddenSections';
					break;
				case 'field':
					dynamicItem = 'showHiddenFields';
					break;
				case 'readonly':
					dynamicItem = 'enableReadonlyFields';
					break;
			}

			if (!dynamicItem){
				return;
			}

			if (data.method === 'add'){
				this[dynamicItem].push(data.value);
			} else {
				this[dynamicItem] = this[dynamicItem].filter((item) => item !== data.value)
			}
		},
		removeRepeaterRow(page, index) {
			let rowIndex = this.repeaterRows.findIndex(item => item.title === page.title);
			this.repeaterRows[rowIndex].rows = this.repeaterRows[rowIndex].rows.filter(item => item !== index);
		},
		addRepeaterRow(page) {
			let rowIndex = this.repeaterRows.findIndex(item => item.title === page.title);
			if (page.max_repeats && page.max_repeats <= this.repeaterRows[rowIndex].rows.length) {
				return;
			}

			if (this.repeaterRows[rowIndex].rows.length) {
				let lastRow = this.repeaterRows[rowIndex].rows[this.repeaterRows[rowIndex].rows.length - 1];
				this.repeaterRows[rowIndex].rows.push(lastRow + 1);
			} else {
				this.repeaterRows[rowIndex].rows.push(0);
			}
		},
		getRepeaterRows(page) {
			if (!page.type || page.type !== 'repeater') {
				return null;
			}

			let item = this.repeaterRows.find(item => item.title === page.title);

			return item.rows;
		},
		nextStep() {
			let form = this.$refs.form;
			let formData = new FormData(form);

			if (this.loading) {
				return;
			}

			this.loading = true;
			this.axios.post(`${this.validationUrl}/${this.currentPage}`, formData).then((response) => {
				this.errorFields = [];
				this.loading = false;
				this.currentPage++;
				this.errors = false;
			}).catch(error => {
				if (error.response.data.errors) {
					this.errors = error.response.data.errors;
					this.handleErrors(error.response.data.errors);
					if (this.$refs.errorsList) {
						this.$nextTick(() => {
							this.$refs.errorsList.$el.scrollIntoView({
								behavior: 'smooth'
							});
						});
					}
				} else if (error.response.data.message) {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});
				}

				this.loading = false;
			});
		},

		handleErrors(errors) {
			this.errorFields = Object.keys(errors).map((item) => {
				let names = item.split('.');
				if (names.length === 1) {
					return names[0];
				}

				names = names.map((name, index) => {
					if (index === 0) {
						return name;
					}

					return `[${name}]`;
				});

				return names.join('');
			});
		},

		handleSubmit() {
			let form = this.$refs.form;
			let formData = new FormData(form);

			if (this.loading) {
				return;
			}

			this.loading = true;
			this.axios.post(this.storeUrl, formData).then((response) => {
				this.errorFields = [];
				this.$emit('submitted', response.data);
				this.loading = false;
				this.currentPage = 1;
				this.errors = false;
				this.$refs.form.reset();
			}).catch(error => {
				if (error.response.data.errors) {
					this.errors = error.response.data.errors;
					this.handleErrors(error.response.data.errors);
					if (this.$refs.errorsList) {
						this.$nextTick(() => {
							this.$refs.errorsList.$el.scrollIntoView({
								behavior: 'smooth'
							});
						});
					}
				} else if (error.response.data.message) {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});
				}

				this.loading = false;
			});
		}
	},
	created() {
		this.form.forEach(page => {
			if (page.type === 'repeater') {
				this.repeaterRows.push({
					title: page.title,
					rows: [],
				});
			}
		});
	},
}
</script>

<style scoped lang="scss">

.light-mode {
	.form-page-item {
		background: #e2e3e6;
		color: var(--background-color, $backgroundColor);
	}
}

.page-content-title {
	font-size: 26px;
	//text-align: center;
	font-weight: 600;
	margin-bottom: 20px;
}

.form-page-list {
	display: flex;
	justify-content: center;

	li + li {
		margin-left: 25px;
	}

	@media($mobileM){
		li + li {
			margin-left: 20px;
		}
	}
	@media($mobileS){
		li + li {
			margin-left: 15px;
		}
	}
}

.form-page-item {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--white-color, $whiteColor);
	font-weight: 600;
	font-size: 14px;
	border-radius: 5px;
	width: 50px;
	height: 50px;
	background: transparentize($whiteColor, .94);
	@media($mobileM){
		width: 45px;
		height: 45px;
	}

	&.prev {
		background: var(--primary-color, $primaryColor);
		color: var(--white-color, $whiteColor);
	}

	&.active {
		background: var(--primary-gradient, $primaryGradient);
		color: var(--white-color, $whiteColor);
	}
}

.page-repeater-row {
	margin-bottom: 25px;

	&:last-child {
		margin-bottom: 0;
	}
}

.remove-actions,
.repeater-actions {
	.button {
		width: 100%;
		padding: 10px 20px;
	}
}

</style>