<template>
	<div class="chart-boxes-container">
		<chart-box v-for="(item, index) in data" :key="index" :data="item" v-if="item.series.length"/>
	</div>
</template>

<script>
import ChartBox from "@/components/containers/ChartBox";

export default {
	name: "ChartBoxesContainer",
	props: ['data'],
	components: {
		ChartBox,
	}
}
</script>

<style scoped lang="scss">
.chart-boxes-container {
	@media($desktopM) and (min-width: 992px){
		display: flex;
		justify-content: space-between;
		.chart-box {
			width: calc(50% - (15px / 2));
			margin-bottom: 0;
			&:nth-child(n + 3 ){
				margin-top: 15px;
			}
		}
	}
}
.chart-box {
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
}
</style>