<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<article>
					<tab-boxes-container>
						<tab-box :title="$t('cards.loyalty_cards')">
							<actions-panel>
								<button class="button style-3" @click.stop="$store.commit('SHOW_CARDS_CREATE_FORM')">
									<span class="inner">{{ $t('cards.add_card') }}</span>
								</button>
							</actions-panel>
							<card-boxes-container :data="$store.state.views.cards"
							                      v-if="$store.state.views.cards.length"/>
							<p v-else>{{ $t('cards.no_cards') }}</p>
						</tab-box>
						<tab-box :title="$t('cards.personal_documents')">
							<actions-panel>
								<button class="button style-3"
								        @click.stop="$store.commit('SHOW_PERSONAL_DOCUMENTS_CREATE_FORM')">
									<span class="inner">{{ $t('cards.add_personal_document') }}</span>
								</button>
							</actions-panel>
							<panel-containers-group :data="personalDocuments"
							                        v-if="personalDocuments[0].data.length || personalDocuments[1].data.length"/>
							<p v-else>{{ $t('cards.no_personal_documents') }}</p>
						</tab-box>
					</tab-boxes-container>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
	import TabBoxesContainer from "../components/containers/TabBoxesContainer";
	import TabBox from "../components/containers/TabBox";
	import CardBoxesContainer from "../components/containers/CardBoxesContainer";
	import ActionsPanel from "../components/panels/ActionsPanel";
	import PanelContainersGroup from "../components/containers/PanelContainersGroup";
	import pageFunctionsMixin from "../mixins/pageFunctionsMixin";

	export default {
		name: "Cards",
		mixins: [pageFunctionsMixin],
		components: {
			TabBoxesContainer,
			TabBox,
			CardBoxesContainer,
			ActionsPanel,
			PanelContainersGroup,
		},
		data() {
			return {}
		},
		computed: {
			personalDocuments() {
				let personalDocuments = [
					{
						title: this.$t('cards.my_personal_documents'),
						data: this.myPersonalDocuments
					},
					{
						title: this.$t('cards.child_personal_documents'),
						data: this.childrenPersonalDocuments
					}
				];

				return personalDocuments;
			},
			myPersonalDocuments() {
				let documents = [];
				this.$store.state.views.personal_documents.forEach(item => {
					if (!item.child) {
						documents.push({
							id: item.id,
							name: item.name,
							description: item.valid_to ? `${this.$t('cards.validity')} ${this.$moment(item.valid_to).format('D.M.YYYY')}` : '',
							image: this.getLocalAsset(`img/panel-box/personal-document.png`),
							detail: true,
							remove: true,
							type: 'personal-document'
						});
					}
				});

				return documents;
			},
			childrenPersonalDocuments() {
				let documents = [];
				this.$store.state.views.personal_documents.forEach(item => {
					if (item.child) {
						documents.push({
							id: item.id,
							name: item.name,
							description: item.valid_to ? `${this.$t('cards.validity')} ${this.$moment(item.valid_to).format('D.M.YYYY')}` : '',
							image: this.getLocalAsset(`img/panel-box/personal-document.png`),
							detail: true,
							remove: true,
							type: 'personal-document'
						});
					}
				});

				return documents;
			}
		}
	}
</script>

<style scoped lang="scss">

</style>