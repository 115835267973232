<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.online_services.title') }}</h2>
				</header>
				<article>
					<services-options-form v-if="$store.state.views.onlineServices.length"/>
					<icon-link-boxes-container :data="$store.state.views.onlineServices" box-extra-class="color-icon"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
	import ProductBoxesContainer from "../components/containers/ProductBoxesContainer";
	import ArticleBoxesContainer from "../components/containers/ArticleBoxesContainer";
	import IconLinkBoxesContainer from "../components/containers/IconLinkBoxesContainer";
	import ServicesOptionsForm from "../components/forms/ServicesOptionsForm";

	export default {
		name: "OnlineServicesHome",
		components: {
			ProductBoxesContainer,
			ArticleBoxesContainer,
			IconLinkBoxesContainer,
			ServicesOptionsForm,
		},
		data() {
			return {}
		},
		created() {

		}
	}
</script>

<style scoped lang="scss">
	.services-options-form {
		max-width: 600px;
	}
</style>