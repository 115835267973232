import { Device } from "@capacitor/device";
import { NativeBiometric } from 'capacitor-native-biometric';

const biometricMixin = {
	data() {
		return {
			hasBiometricCredentials: false,
			biometricLoading: false,
			biometricAvailable: false,
		}
	},

	computed:{
		async deviceId(){
			return await Device.getId();
		},
		async biometricEnabled(){
			NativeBiometric.getCredentials({
				server: process.env.VUE_APP_CAP_ID_ANDROID,
			}).then(credentials => {
				this.hasBiometricCredentials = true;
				return true;
			}).catch(() => {
				return false;
			});
		},
	},

	methods: {
		async checkBiometricAvailability(){
			if(!this.$store.state.app.native){
				this.biometricAvailable = false;
				return false;
			}

			await NativeBiometric.isAvailable().then((result) => {
				this.biometricAvailable = result.isAvailable;
				return result.isAvailable;
			});
		},
		async setupBiometricLogin(){
			this.biometricLoading = true;
			const deviceId = await Device.getId();

			this.axios.post('/api/user/store-native-auth', {
				device_id: deviceId.identifier,
				type: 'biometric',
			}).then((response) => {
				NativeBiometric.setCredentials({
					username: deviceId.identifier,
					password: response.data.hash,
					server: process.env.VUE_APP_CAP_ID_ANDROID,
				});
				this.biometricLoading = false;
			}).catch((error) => {
				this.biometricLoading = false;
			});
		},

		async removeBiometricLogin(){
			this.biometricLoading = true;
			const deviceId = await Device.getId();

			this.axios.post('/api/user/delete-native-auth', {
				device_id: deviceId.identifier,
				type: 'biometric',
			}).then(() => {
				NativeBiometric.deleteCredentials({
					server: process.env.VUE_APP_CAP_ID_ANDROID
				});
				this.biometricLoading = false;
			}).catch(() => {
				this.biometricLoading = false;
			});
		},

		toggleBiometricLogin(value){
			if (value){
				this.setupBiometricLogin();
			} else {
				this.removeBiometricLogin();
			}
		},
	},
	mounted() {
		this.checkBiometricAvailability();
	}
};

export default biometricMixin;