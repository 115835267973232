<template>
	<svg version="1.1" id="Vrstva_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	     viewBox="0 0 373.3 161.3" style="enable-background:new 0 0 373.3 161.3;" xml:space="preserve">
			<g>
		<g>
			<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="174.5847" y1="0.8137" x2="174.5847" y2="141.0792">
				<stop  offset="0" style="stop-color:#FABE49"/>
				<stop  offset="0.28" style="stop-color:#FFD71A"/>
				<stop  offset="0.56" style="stop-color:#FFCD2D"/>
				<stop  offset="0.78" style="stop-color:#FFCB1E"/>
				<stop  offset="1" style="stop-color:#FBB320"/>
			</linearGradient>
			<path class="st0" d="M177,94.2c0-2-0.1-5.1-0.1-9.3c0-4.4,0-9.8,0-15.5l0-2.3h2.3h11.6V0.8h-32.5v15.7c0,0,0,0,0,0.1
				c1.9,2.4,3.7,5,5.2,7.8c4.3,7.8,6.5,16.7,6.5,25.7c0,9-2.3,17.9-6.5,25.7c-1.5,2.7-3.2,5.3-5.1,7.7c0,29.6-0.1,57.7-0.1,57.7h32.5
				v-10.7C182.2,121.1,177.2,108.1,177,94.2z"/>
		</g>
				<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="106.2831" y1="-0.276" x2="106.2831" y2="142.0411">
			<stop  offset="0" style="stop-color:#FABE49"/>
					<stop  offset="0.28" style="stop-color:#FFD71A"/>
					<stop  offset="0.56" style="stop-color:#FFCD2D"/>
					<stop  offset="0.78" style="stop-color:#FFCB1E"/>
					<stop  offset="1" style="stop-color:#FBB320"/>
		</linearGradient>
				<path class="st1" d="M161.5,25.5c-1.6-3-3.5-5.7-5.6-8.3C147,6.9,133,0.3,117.1,0.1c-2.1,0-5.4-0.1-9.3-0.1
			c-21.3-0.1-63,0.1-63,0.1v86.8c5.1-12.3,17.5-21,31.8-21c0.1,0,0.1,0,0.2,0V50.6v-0.9V31.9c0,0,37.7-0.8,43.9,1.1
			c7.5,2.3,12.1,8.7,12.8,15.7c0,0.3,0.1,1,0.1,1.5c0,0.5-0.1,1.2-0.1,1.5c-0.7,7-5.4,13.4-12.8,15.7c-3.5,1.1-14,1.2-24.1,1.1l0,0
			c-7-0.1-16.4-0.2-19.8-0.2v0c-0.1,0-0.1,0-0.2,0c-16.6,0-30.4,12.5-31.8,28.4v45.5h32v-20.7v-1.2v-20c5.7,0,15,0,20.5,0l0,0
			c3.8,0,7.4,0,10.5,0c3.9,0,7.1,0,9.3-0.1C133,99.9,147,93.3,155.9,83c2.1-2.6,4-5.3,5.6-8.3c4-7.3,6.2-15.7,6.2-24.6
			S165.5,32.8,161.5,25.5z"/>
				<linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="-1.1321" y1="84.3072" x2="109.6129" y2="84.3072">
			<stop  offset="0" style="stop-color:#FABE49"/>
					<stop  offset="0.28" style="stop-color:#FFD71A"/>
					<stop  offset="0.56" style="stop-color:#FFCD2D"/>
					<stop  offset="0.78" style="stop-color:#FFCB1E"/>
					<stop  offset="1" style="stop-color:#FBB320"/>
		</linearGradient>
				<path class="st2" d="M0,68.4l13.2,16.3L0,100.2h31c6.3,0,11.4-5.1,11.4-11.4V68.4H0z"/>
				<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="313.2678" y1="68.9125" x2="313.2678" y2="162.6689">
			<stop  offset="0" style="stop-color:#FABE49"/>
					<stop  offset="0.28" style="stop-color:#FFD71A"/>
					<stop  offset="0.56" style="stop-color:#FFCD2D"/>
					<stop  offset="0.78" style="stop-color:#FFCB1E"/>
					<stop  offset="1" style="stop-color:#FBB320"/>
		</linearGradient>
				<path class="st3" d="M307.3,96.3c0,0,24.2-0.3,26.7,0.5c7.9,2.3,12.7,9,13.5,16.3c0,0.3,0.1,1,0.1,1.6c0,0.5-0.1,1.3-0.1,1.6
			c-0.7,7.3-5.6,13.6-13.3,16.6c-6.4,2.5-21,3.9-35.9-11.6l-10.3,22.2c11.9,14.9,32.8,22.6,54.4,15.1c13.3-4.6,23.6-15.1,28.1-28.5
			c8.9-26.7-5.1-51.8-27.4-60.7h-54.9h-1c-8.9,19.2-22.4,48.3-34,73.1H286L307.3,96.3z"/>
				<linearGradient id="SVGID_5_" gradientUnits="userSpaceOnUse" x1="229.3505" y1="69.3441" x2="229.3505" y2="143.7817">
			<stop  offset="0" style="stop-color:#FABE49"/>
					<stop  offset="0.28" style="stop-color:#FFD71A"/>
					<stop  offset="0.56" style="stop-color:#FFCD2D"/>
					<stop  offset="0.78" style="stop-color:#FFCB1E"/>
					<stop  offset="1" style="stop-color:#FBB320"/>
		</linearGradient>
				<path class="st4" d="M279.5,69.4h-32c0.2,11.9,0.4,27.3-0.9,31.6c-2.3,7.5-8.7,12.1-15.7,12.8c-0.3,0-1,0.1-1.5,0.1
			c-0.5,0-1.2-0.1-1.5-0.1c-7-0.7-13.4-5.4-15.7-12.8c-1.3-4.4-1.1-19.7-0.9-31.6h-32c0,5.7,0,11,0,15.4c0,3.9,0,7.1,0.1,9.3
			c0.2,15.8,6.9,29.8,17.1,38.7c2.6,2.1,5.3,4,8.3,5.6c2.9,1.6,6,2.9,9.1,3.9l27.4,0c0,0,9.6,0,9.6,0c0,0,20.2-43.5,28.5-61.4V69.4z"
				/>
				<g>
			<path class="st5" d="M228.2,61.7c-1.9,0-3.4-0.7-4.5-2.1h-0.2c0.2,1.4,0.2,2.1,0.2,2.4v6.6h-4.4V45.2h3.6l0.6,2.1h0.2
				c1-1.6,2.6-2.4,4.6-2.4c1.9,0,3.4,0.7,4.5,2.2c1.1,1.5,1.6,3.5,1.6,6.2c0,1.7-0.3,3.2-0.8,4.5c-0.5,1.3-1.2,2.3-2.2,2.9
				C230.6,61.4,229.5,61.7,228.2,61.7z M226.9,48.4c-1.1,0-1.9,0.3-2.4,1c-0.5,0.7-0.8,1.8-0.8,3.3v0.5c0,1.7,0.3,3,0.8,3.8
				c0.5,0.8,1.3,1.1,2.5,1.1c2,0,3-1.6,3-4.9c0-1.6-0.2-2.8-0.7-3.6C228.7,48.8,227.9,48.4,226.9,48.4z"/>
					<path class="st5" d="M247.2,44.9c0.6,0,1.1,0,1.5,0.1l-0.3,4.2c-0.4-0.1-0.8-0.1-1.3-0.1c-1.4,0-2.5,0.4-3.3,1.1
				c-0.8,0.7-1.2,1.7-1.2,3.1v8.3h-4.4V45.2h3.4l0.7,2.7h0.2c0.5-0.9,1.2-1.6,2-2.2C245.3,45.1,246.2,44.9,247.2,44.9z"/>
					<path class="st5" d="M266.5,53.3c0,2.7-0.7,4.7-2.1,6.2c-1.4,1.5-3.3,2.2-5.8,2.2c-1.6,0-2.9-0.3-4.1-1c-1.2-0.7-2.1-1.7-2.8-3
				c-0.6-1.3-1-2.8-1-4.5c0-2.7,0.7-4.7,2.1-6.2c1.4-1.5,3.3-2.2,5.9-2.2c1.6,0,2.9,0.3,4.1,1c1.2,0.7,2.1,1.7,2.8,2.9
				C266.2,50.1,266.5,51.6,266.5,53.3z M255.2,53.3c0,1.6,0.3,2.8,0.8,3.7s1.4,1.2,2.6,1.2c1.2,0,2-0.4,2.6-1.2
				c0.5-0.8,0.8-2,0.8-3.7c0-1.6-0.3-2.8-0.8-3.6c-0.5-0.8-1.4-1.2-2.6-1.2c-1.2,0-2,0.4-2.6,1.2C255.5,50.4,255.2,51.6,255.2,53.3z"
					/>
					<path class="st5" d="M279.2,48.5h-3.8v13h-4.4v-13h-2.4v-2.1l2.4-1.2V44c0-1.9,0.5-3.2,1.4-4.1c0.9-0.9,2.4-1.3,4.4-1.3
				c1.5,0,2.9,0.2,4.1,0.7l-1.1,3.3c-0.9-0.3-1.7-0.4-2.5-0.4c-0.6,0-1.1,0.2-1.4,0.6c-0.3,0.4-0.4,0.9-0.4,1.4v1h3.8V48.5z
				 M281.5,40.9c0-1.4,0.8-2.2,2.4-2.2s2.4,0.7,2.4,2.2c0,0.7-0.2,1.2-0.6,1.6c-0.4,0.4-1,0.6-1.8,0.6
				C282.3,43.1,281.5,42.4,281.5,40.9z M286.1,61.4h-4.4V45.2h4.4V61.4z"/>
		</g>
	</g>
	</svg>
</template>

<script>
	export default {
		name: "PluskaLogo"
	}
</script>

<style scoped>
	.st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);}
	.st1{fill:url(#SVGID_2_);}
	.st2{fill:url(#SVGID_3_);}
	.st3{fill:url(#SVGID_4_);}
	.st4{fill:url(#SVGID_5_);}
	.st5{fill:#6D6E70;}
</style>