<template>
	<default-sidebar :title="sidebar.title" :icon="sidebar.icon" :active="$store.state.sidebars.showSettings">
		<settings-list v-if="$store.state.auth.isLoggedIn"/>
		<app-version-box/>
	</default-sidebar>
</template>

<script>
	import DefaultSidebar from "./DefaultSidebar";
	import SettingsList from "../containers/SettingsList";
	import AppVersionBox from "@/components/containers/AppVersionBox";

	export default {
		name: "SettingsSidebar",
		components: {
			DefaultSidebar,
			SettingsList,
			AppVersionBox,
		},
		data() {
			return {
				sidebar: {
					title: this.$t('settings.title'),
					icon: '<i class="fa-thin fa-gear"></i>'
				}
			}
		},
	}
</script>

<style scoped>

</style>