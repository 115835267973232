<template>
	<div class="safe-area-top" :style="`height:${height}px`" v-show="showSafeArea"></div>
</template>

<script>
	export default {
		name: "SafeAreaTop",
		props: ['height'],
		computed: {
			showSafeArea() {
				if (this.$route.name === 'ProductDetail') {
					return false;
				}

				return true;
			}
		}
	}
</script>

<style scoped lang="scss">
	.safe-area-top {
		background: var(--background-color, $backgroundColor);
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 99;
	}
</style>