import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { SafeArea } from "capacitor-plugin-safe-area";
import axios from "axios";
import { NativeMarket } from "@capacitor-community/native-market";
import i18n from "../../i18n";

const app = {
	state: {
		appLoading: false,
		showNav: false,
		darkMode: true,
		platform: Capacitor.getPlatform(),
		native: Capacitor.isNativePlatform(),
		version: '',
		appLayout: true,
		appType: process.env.VUE_APP_TYPE,
		currency: process.env.VUE_APP_CURRENCY,
		allowedInvestments: {
			isanon: [
				'EIC', 'IAD', 'Conseq',
			],
			insanon: [
				'EIC', 'Conseq', 'Investika',
			],
			pluska: [
				'EIC', 'IAD', 'Conseq',
			]
		},
		safeArea: {
			top: 0,
			bottom: 0,
		},
	},
	mutations: {
		SET_APP_LOADING(state) {
			state.appLoading = true;
		},
		UNSET_APP_LOADING(state) {
			state.appLoading = false;
		},
		SET_SHOW_NAV(state) {
			state.showNav = true;
		},
		SET_DARK_MODE(state, value) {
			state.darkMode = value;
		},
		UNSET_SHOW_NAV(state) {
			state.showNav = false;
		},
		TOGGLE_SHOW_NAV(state) {
			state.showNav = !state.showNav;
		},
		SET_APP_LAYOUT(state, data) {
			state.appLayout = data;
		},
		SET_APP_VERSION(state, data) {
			state.version = data;
		}
	},
	actions: {
		setSafeArea(context) {
			if (!context.state.native || context.state.platform !== 'ios') {
				return false;
			}

			SafeArea.getSafeAreaInsets().then(data => {
				context.state.safeArea.top = data.insets.top;
				context.state.safeArea.bottom = data.insets.bottom;
			});
		},
		async checkAppVersion(context) {
			if (!context.state.native) {
				return;
			}
			let shouldUpdate = false;
			let info = await App.getInfo();
			context.commit('SET_APP_VERSION', info.version);
			let appVersion = info.version.split('.');
			let remoteVersion;
			try {
				remoteVersion = await axios.get('/api/get-app-version');
			} catch (error) {
				return;
			}
			let splitRemoteVersion = remoteVersion.data.version.split('.');
			appVersion.forEach((item, index) => {
				let segment = parseInt(item);
				if (splitRemoteVersion[index]) {
					let remoteSegment = parseInt(splitRemoteVersion[index]);
					if (segment < remoteSegment) {
						shouldUpdate = true;
					}
				}
			});

			if (!shouldUpdate) {
				return;
			}

			let swalResult = await this._vm.$swal.fire({
				icon: 'info',
				title: i18n.t('application.update_title'),
				text: i18n.t('application.update_text', {
					version: remoteVersion.data.version
				}),
				showConfirmButton: true,
				showCancelButton: true,
				confirmButtonText: i18n.t('application.update_confirm_button'),
				cancelButtonText: i18n.t('application.update_cancel_button')
			});

			if (!swalResult.isConfirmed) {
				return;
			}

			let appId;
			if (context.state.platform === 'ios') {
				appId = process.env.VUE_APP_CAP_ID_APP_STORE;
			} else {
				appId = process.env.VUE_APP_CAP_ID_ANDROID;
			}

			NativeMarket.openStoreListing({
				appId: appId,
			});
		}
	}
};

export default app;