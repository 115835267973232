<template>
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	     x="0px" y="0px"
	     viewBox="0 0 212.8 75.3" style="enable-background:new 0 0 212.8 75.3;" xml:space="preserve">
			<path class="st0" d="M0,42.8V1.4h17.2V41c0,11.8,6,17.9,16.3,17.9c10.3,0,16.3-5.9,16.3-17.4V1.4H67v40.9c0,22-13.1,32.7-33.7,32.7
		C12.6,75.1,0,64.2,0,42.8"/>
		<g>
		<defs>
			<path id="SVGID_7_"
			      d="M165.1,37.7c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10s-4.5-10-10-10C169.6,27.7,165.1,32.2,165.1,37.7"/>
		</defs>
			<clipPath id="SVGID_1_">
			<use xlink:href="#SVGID_7_" style="overflow:visible;"/>
		</clipPath>

			<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-309.3244" y1="331.0601" x2="-308.3244"
			                y2="331.0601" gradientTransform="matrix(19.918 0 0 -19.918 6326.2612 6631.7002)">
			<stop offset="0" class="primary-color"/>
			<stop offset="1" class="end-gradient-color"/>
		</linearGradient>
			<rect x="165.1" y="27.7" class="st1" width="19.9" height="19.9"/>
	</g>
		<path class="st0" d="M128.2,37.7c0-1.6,0.1-3.1,0.2-4.6H96.2v-4c0-7.1,5.8-12.9,12.9-12.9h24.4c3.1-5.9,7.5-11,12.7-15.1h-37.1
		C92.1,1.1,78.2,15,78.2,32v41.9h18V47.5h33C128.6,44.3,128.2,41,128.2,37.7"/>
		<g>
		<defs>
			<path id="SVGID_10_" d="M154.2,37.7c0-11.6,9.4-20.9,20.9-20.9c11.6,0,20.9,9.4,20.9,20.9s-9.4,20.9-20.9,20.9
				C163.5,58.6,154.2,49.2,154.2,37.7 M137.5,37.7c0,20.8,16.9,37.7,37.7,37.7c20.8,0,37.7-16.9,37.7-37.7S195.9,0,175.1,0
				C154.3,0,137.5,16.9,137.5,37.7"/>
		</defs>
			<clipPath id="SVGID_3_">
			<use xlink:href="#SVGID_10_" style="overflow:visible;"/>
		</clipPath>

			<linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="-316.1192" y1="333.9507" x2="-315.1192"
			                y2="333.9507" gradientTransform="matrix(75.3148 0 0 -75.3148 23945.9082 25189.0879)">
			<stop offset="0" class="primary-color"/>
				<stop offset="1" class="end-gradient-color"/>
		</linearGradient>
			<rect x="137.5" y="0" class="st2" width="75.3" height="75.3"/>
	</g>
	</svg>
</template>

<script>
export default {
	name: "UfoLogo"
}
</script>

<style scoped lang="scss">
.st0 {
	fill: $whiteColor;
}

.st1 {
	clip-path: url(#SVGID_1_);
	fill: url(#SVGID_2_);
}

.st2 {
	clip-path: url(#SVGID_3_);
	fill: url(#SVGID_4_);
}
.light-mode {
	.st0 {
		fill: $backgroundColor;
	}
}

.public-footer .logo,
.public-header .logo {
	.st0 {
		fill: $backgroundColor;
	}
}

.logo {
	.primary-color {
		stop-color: var(--primary-color, $primaryColor);
	}

	.end-gradient-color {
		stop-color: var(--end-gradient-color, $endGradientColor);
	}
}
</style>