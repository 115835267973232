<template>
	<div class="partner-boxes-container">
		<partner-box v-for="item in data" :data="item" :key="item.id"/>
	</div>
</template>

<script>
import PartnerBox from "@/components/containers/PartnerBox";

export default {
	name: "PartnerBoxesContainer",
	props: ['data'],
	components: {
		PartnerBox
	}
}
</script>

<style scoped lang="scss">
.partner-boxes-container {
	display: flex;
	flex-wrap: wrap;
	.partner-box {
		@media(min-width: 992px){
			width: calc(33.333% - (70px / 3));
			margin-right: 35px;
			&:nth-child(3n + 3) {
				margin-right: 0;
			}

			&:nth-child(n + 4) {
				margin-top: 35px;
			}
		}
	}

	@media($tabletL){
		justify-content: space-between;
		.partner-box {
			width: calc(50% - 10px);
			&:nth-child(n + 3) {
				margin-top: 20px;
			}
		}
	}

	@media($mobileM){
		.partner-box {
			width: 100%;
			&:nth-child(n + 2){
				margin-top: 20px;
			}
		}
	}
}
</style>