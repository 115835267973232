<template>
	<div class="logo" :class="appType">
		<router-link :to="link" v-if="link">
			<pluska-logo v-if="appType === 'pluska'"/>
			<ufo-logo v-else/>
		</router-link>
		<figure v-else>
			<pluska-logo v-if="appType === 'pluska'"/>
			<ufo-logo v-else/>
		</figure>
	</div>
</template>

<script>
	import UfoLogo from "@/components/partials/UfoLogo";
	import PluskaLogo from "./PluskaLogo";
	import IsanonLogo from "./IsanonLogo";

	export default {
		name: "AppLogo",
		props: ['link'],
		components: {
			UfoLogo,
			PluskaLogo,
			IsanonLogo
		},
		computed: {
			appType() {
				return process.env.VUE_APP_TYPE;
			}
		}
	}
</script>

<style scoped lang="scss">
	.logo {
		line-height: 1;
		max-width: 115px;
		max-height: 35px;
		position: relative;
		&.pluska {
			max-height: 45px;
		}

		svg {
			width: 100%;
			height: auto;
			max-height: inherit;
		}

		a {
			display: inline-block;
			max-height: inherit;
		}

		@media($tabletL) {
			max-width: 85px;
		}
	}
</style>