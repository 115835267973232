<template>
    <default-sidebar :active="$store.state.sidebars.showContractsEditForm">
        <contracts-edit-form :data="$store.state.sidebars.showContractsEditForm" v-if="$store.state.sidebars.showContractsEditForm"/>
    </default-sidebar>
</template>

<script>
    import DefaultSidebar from "./DefaultSidebar";
    import ContractsEditForm from "../forms/ContractsEditForm";

    export default {
        name: "ContractsEditFormSidebar",
        components: {
            DefaultSidebar,
            ContractsEditForm
        },
        data() {
            return {

            }
        },
    }
</script>

<style scoped lang="scss">

</style>