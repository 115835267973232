<template>
    <default-sidebar :active="$store.state.sidebars.showCardsEditForm">
        <cards-edit-form v-if="$store.state.sidebars.showCardsEditForm" :data="$store.state.sidebars.showCardsEditForm"/>
    </default-sidebar>
</template>

<script>
    import DefaultSidebar from "./DefaultSidebar";
    import CardsEditForm from "../forms/CardsEditForm";

    export default {
        name: "CardsEditFormSidebar",
        components: {
            DefaultSidebar,
            CardsEditForm
        },
        data() {
            return {

            }
        },
    }
</script>

<style scoped lang="scss">

</style>