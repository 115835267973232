import Vue from 'vue'
import Vuex from 'vuex'
import router from "../router";
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios';
import VueAxios from "vue-axios";
import lodash from 'lodash';
import VueLodash from "vue-lodash";
import VueMoment from 'vue-moment';
import app from "./modules/app";
import auth from "./modules/auth";
import sidebars from "./modules/sidebars";
import popups from "./modules/popups";
import views from "./modules/views";
import overlays from './modules/ovrelays';
import Dropzone from 'dropzone';
import Echo from 'laravel-echo';
import VueEcho from 'vue-echo-laravel';
import Pusher from "pusher-js";
import 'fslightbox';
import i18n from "@/i18n";
import VueApexCharts from "vue-apexcharts";

const API_URL = `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST_NAME}`;

Vue.use(Vuex);
Vue.use(VueSweetalert2, {
	customClass: {
		confirmButton: 'button style-3',
		cancelButton: 'button style-1',
		denyButton: 'button style-6'
	},
	buttonsStyling: false,
});
Vue.use(VueAxios, axios);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueMoment);
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts)

const store = new Vuex.Store({
	modules: {
		app,
		auth,
		sidebars,
		popups,
		overlays,
		views,
	},
	state: {},
	mutations: {},
	actions: {},

});


Dropzone.autoDiscover = false;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['App-Version'] = '3.1.12';
let userToken = localStorage.getItem('user_token');
if (userToken) {
	axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;
	store.commit('SET_USER_TOKEN', userToken);
}

axios.interceptors.response.use((response) => {
	return response;
}, (error) => {
	if (!error.response) {
		Vue.swal.fire({
			icon: 'error',
			title: 'Oops',
			text: i18n.t('general.no_internet_connection'),
		});
	} else if (error.response.status === 401) {
		store.dispatch('processLogOut');
	} else if (error.response.status === 404) {
		router.push('/404');
	}
	return Promise.reject(error);
});

const echo = new Echo({
	broadcaster: 'reverb',
	key: process.env.VUE_APP_REVERB_APP_KEY,
	wsHost: process.env.VUE_APP_HOST_NAME,
	wsPort: 80,
	wssPort: 443,
	forceTLS: true,
	disableStats: false,
	enabledTransports: ['ws', 'wss'],
	authorizer: (channel) => {
		return {
			authorize: (socketId, callback) => {
				axios.post('/api/broadcasting/auth', {
					socket_id: socketId,
					channel_name: channel.name
				}).then(response => {
					callback(false, response.data);
				}).catch(error => {
					callback(true, error);
				});
			}
		};
	},
});

Vue.use(VueEcho, echo);

export default store;