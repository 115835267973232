<template>
	<main class="landing-main">
		<web-landing-text-box v-if="!$store.state.app.native"/>
		<native-landing-screen v-else/>
		<screens-section v-if="!$store.state.app.native"/>
		<about-section v-if="!$store.state.app.native"/>
		<slider-section v-if="!$store.state.app.native"/>
		<download-section v-if="!$store.state.app.native"/>
		<partners-section v-if="!$store.state.app.native"/>

	</main>
</template>

<script>
	import WebLandingTextBox from "../components/containers/WebLandingTextBox";
	import NativeLandingScreen from "../components/containers/NativeLandingScreen";
	import DownloadSection from "../components/sections/DownloadSection";
	import PartnersSection from "../components/sections/PartnersSection";
	import ScreensSection from "../components/sections/ScreensSection";
	import AboutSection from "../components/sections/AboutSection";
	import SliderSection from "../components/sections/SliderSection";

	export default {
		name: "Landing",
		components: {
			WebLandingTextBox,
			NativeLandingScreen,
			DownloadSection,
			PartnersSection,
			ScreensSection,
			AboutSection,
			SliderSection
		}
	}
</script>

<style scoped>

</style>