<template>
    <main class="app-main">
        <div class="content-wrap">
            <section class="content-section">
                <article>
                    <article-detail-box :data="$store.state.views.articleDetail" v-if="$store.state.views.articleDetail"/>
                </article>
            </section>
        </div>
    </main>
</template>

<script>
    import ArticleDetailBox from "../components/containers/ArticleDetailBox";

    export default {
        name: "ProductDetail",
        components: {
            ArticleDetailBox,
        },
    }
</script>

<style scoped lang="scss">
    .content-wrap {
        max-width: initial;
        //padding: 0;
    }
</style>