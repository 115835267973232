<template>
	<div class="attachment-preview-field" :class="extraClasses" v-show="!removed">
		<figure class="attachment-image fit-image" v-if="type === 'image'">
			<a :href="url" @click.prevent="downloadAttachment">
				<img src="" ref="img">
			</a>
		</figure>
		<a :href="url" class="attachment-file" download="true" target="_blank" v-else
		   @click.prevent="downloadAttachment"><i class="fa-thin fa-file-lines"></i> {{ data.filename }}</a>
		<button class="remove-attachment" v-if="allowRemove" @click.prevent="removeAttachment"><i
				class="fa-regular fa-circle-xmark"></i></button>
		<input type="hidden" v-if="removed" :name="name" :value="data.id">
	</div>
</template>

<script>
	import {Filesystem, Directory, Encoding} from '@capacitor/filesystem';
	import { FileOpener} from "@capacitor-community/file-opener";

	export default {
		name: "AttachmentPreviewField",
		props: ['data', 'allowRemove', 'name'],
		components: {},
		data() {
			return {
				removed: false,
				loading: false,
			}
		},
		computed: {
			url() {
				return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST_NAME}/api/${this.data.url}`;
			},
			type() {
				if (this.data.mime_type.includes('image')) {
					return 'image';
				}
				return 'file';
			},
			extraClasses() {
				let classes = [];
				classes.push(this.type);
				if (this.loading) {
					classes.push('loading');
				}

				return classes.join(' ');
			}
		},
		methods: {
			removeAttachment() {
				this.$swal.fire({
					title: this.$t('general.delete_confirm_title'),
					text: this.$t('general.delete_confirm_text_attachment'),
					icon: 'info',
					showCancelButton: true,
					cancelButtonText: this.$t('general.no'),
					confirmButtonText: this.$t('general.yes'),
				}).then(result => {
					if (result.isConfirmed) {
						this.removed = true;
					}
				});
			},
			fetchImage() {
				this.loading = true;
				this.axios.get(this.url, {
					responseType: 'blob'
				}).then(response => {
					let reader = new FileReader();
					reader.readAsDataURL(response.data);
					reader.onload = () => {
						this.$refs.img.src = reader.result;
						this.loading = false;
					}
				});
			},
			blobToBase64(blob) {
				return new Promise((resolve, _) => {
					const reader = new FileReader();
					reader.onloadend = () => resolve(reader.result);
					reader.readAsDataURL(blob);
				});
			},
			async downloadAttachment() {
				if (this.loading) {
					return false;
				}

				this.loading = true;
				this.axios.get(this.url, {
					responseType: 'blob'
				}).then(response => {
					if (this.$store.state.app.native) {
						this.handleNativeDownload(response);
					} else {
						this.handleWebDownload(response);
					}
				}).catch(() => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: this.$t('general.default_error')
					});
					this.loading = false;
				});
			},
			async handleNativeDownload(response) {
				let data = await this.blobToBase64(new Blob([response.data]));

				const file = await Filesystem.writeFile({
					path: this.data.filename,
					data: data,
					directory: Directory.Cache,
					//encoding: Encoding.UTF8,
				});

				FileOpener.open({
					filePath: file.uri,
					contentType: this.data.mime_type,
					openWithDefault: true,
				}).then(() => {
					this.loading = false;
				}).catch(error => {
					console.log(error);
				});
			},
			handleWebDownload(response) {
				let url = window.URL.createObjectURL(new Blob([response.data]));
				let link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', this.data.filename);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				this.loading = false;
			}
		},
		mounted() {
			if (this.type === 'image') {
				this.fetchImage();
				refreshFsLightbox();
			}
		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.attachment-preview-field {
			&.image {
				background: rgba(var(--background-color-rgb), 0.06);

				&:before {
					background: var(--white-color, $whiteColor);
				}
			}
			&.file {
				a:not(:hover) {
					color: var(--background-color, $backgroundColor);
				}
			}
		}
	}

	.attachment-preview-field {
		position: relative;

		&.image {
			background: #1d2943;
			padding: 4px;
			border-radius: 5px;
			position: relative;

			&:before {
				content: '';
				left: 4px;
				right: 4px;
				top: 4px;
				bottom: 4px;
				pointer-events: none;
				opacity: 0;
				background: $backgroundColor;
				position: absolute;
				display: block;
				z-index: 100;
				transition: $animationDefaultDuration;
			}

			&:after {
				content: "\f3f4";
				font-weight: 400;
				font-family: "Font Awesome 6 Pro";
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				display: var(--fa-display, inline-block);
				font-style: normal;
				font-variant: normal;
				line-height: 1;
				text-rendering: auto;
				position: absolute;
				animation: spinning_animation 1s infinite;
				animation-timing-function: linear;
				font-size: 30px;
				z-index: 101;
				color: var(--white-color, $whiteColor);
				left: calc(50% - 15px);
				top: calc(50% - 15px);
				pointer-events: none;
				opacity: 0;
			}

			.remove-attachment {
				top: 10px;
				right: 10px;
				z-index: 100;
			}

			&.loading {
				&:before {
					opacity: .75;
				}

				&:after {
					opacity: 1;
				}
			}

			&:hover:before {
				opacity: .75;
			}
		}

		&.file {
			padding-right: 20px;

			&.loading {
				.attachment-file {
					i {
						&:before {
							content: "\f3f4";
							animation: spinning_animation 1s infinite;
							animation-timing-function: linear;
							display: inline-block;
						}
					}
				}
			}

			.remove-attachment {
				top: calc(50% - 7px);
				right: 0;
			}
		}

		.remove-attachment {
			background: transparent;
			border: none;
			color: $errorColor;
			cursor: pointer;
			line-height: 1;
			position: absolute;
			font-size: 14px;
			transition: $animationDefaultDuration;

			&:hover {
				color: $whiteColor;
			}
		}

		.attachment-image {
			position: relative;

			&.loading {
				&:after {
					opacity: 1;
				}
			}

			&:before {
				padding-bottom: 138%;
			}

			img {
				border-radius: 3px;
			}
		}

		.attachment-file {
			font-size: 14px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: flex;
			align-items: center;
			line-height: 1;
			color: $fontColor;

			i {
				font-size: 18px;
				margin-right: 10px;
			}

			&:hover {
				color: $primaryColor;
			}
		}
	}
</style>