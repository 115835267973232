<template>
	<default-popup>
		<div class="content" v-if="agent">
			<div class="icon">
				<i class="fa-thin fa-user-tie"></i>
			</div>
			<h3 class="default-popup-title">{{ $t('products.agent_will_contact') }}</h3>
			<div class="actions">
				<button class="button style-1" @click.prevent="closePopup">
					<span class="inner">{{ $t('general.continue') }} ({{ countdownValue }})</span>
				</button>
			</div>
		</div>
		<div class="content" v-else>
			<div class="step" v-if="step === 1">
				<div class="icon">
					<i class="fa-thin fa-user-slash"></i>
				</div>
				<h3 class="default-popup-title">{{ $t('products.no_agent') }}</h3>
				<div class="actions">
					<div class="button style-4" @click.prevent="step = 3">
						<div class="inner">{{ $t('products.want_agent') }}</div>
					</div>
					<button class="button style-1" @click.prevent="step = 2">
						<span class="inner">{{ $t('products.know_agent') }}</span>
					</button>
				</div>
			</div>
			<div class="step" v-if="step === 2">
				<div class="icon">
					<i class="fa-thin fa-user-tie"></i>
				</div>
				<h3 class="default-popup-title">{{ $t('products.know_agent') }}</h3>
				<form @submit.prevent="sendForm">
					<div class="form-row">
						<default-field-box type="text" name="agent_code"
						                   :placeholder="$t('forms.agent_code')"
						                   event="agent-code-changed" :required="true"/>
					</div>
					<div class="form-actions">
						<button type="submit" class="button style-4" :class="loading ? 'loading' : ''">
							<span class="inner">{{ $t('forms.confirm') }}</span>
						</button>
					</div>
				</form>
			</div>
			<div class="step" v-if="step === 3">
				<div class="icon">
					<i class="fa-thin fa-user-tie"></i>
				</div>
				<h3 class="default-popup-title">{{ $t('products.agent_assign_contact') }}</h3>
				<div class="actions">
					<button class="button style-1" @click.prevent="closePopup">
						<span class="inner">{{ $t('general.continue') }} ({{ countdownValue }})</span>
					</button>
				</div>
			</div>
		</div>

	</default-popup>
</template>

<script>
	import DefaultPopup from "./DefaultPopup";
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";

	export default {
		name: "ProductPopup",
		props: ['data'],
		components: {
			DefaultPopup,
			DefaultFieldBox
		},
		data() {
			return {
				loading: false,
				step: 1,
				countdownValue: 5,
				agent: this.$store.state.auth.user.agent_code,
				agentCodeInput: '',
			}
		},
		watch: {
			step(newValue, oldValue) {
				if (newValue === 3) {
					if (oldValue === 1) {
						this.sendAgentRequest();
					}
					this.startCountdown();
				}
			}
		},
		methods: {
			closePopup() {
				this.$store.commit('CLOSE_PRODUCT_POPUP');
			},
			sendAgentRequest(agentCode = null) {
				return new Promise((resolve, reject) => {
					this.axios.post('/api/products/request-contact', {
						product_id: this.data.id,
						agent_code: agentCode,
					}).then((response) => {
						resolve(response);
					}).catch((error) => {
						reject(error);
					});
				});
			},
			sendForm() {
				if (!this.agentCodeInput) {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: this.$t('auth.enter_agent_code')
					});
					return false;
				}
				this.loading = true;
				this.sendAgentRequest(this.agentCodeInput).then(() => {
					this.step = 3;
				});
			},
			startCountdown() {
				setTimeout(() => {
					if (!this.$store.state.popups.showProductPopup) {
						return false;
					}

					this.countdownValue--;
					if (this.countdownValue === 0) {
						this.closePopup();
						return;
					}
					this.startCountdown();
				}, 1000);
			},
			processAgentCodeChanged(data) {
				this.agentCodeInput = data.value;
			}
		},
		mounted() {
			if (this.agent) {
				this.sendAgentRequest();
				this.startCountdown();
			}
			this.$root.$on('agent-code-changed', (data) => {
				this.processAgentCodeChanged(data);
			});
		},
		destroyed() {
			this.$root.$off('agent-code-changed');
		}
	}
</script>

<style scoped>

</style>