<template>
    <div class="actions-panel" :class="extraClass">
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "ActionsPanel",
        props: ['extraClass']
    }
</script>

<style scoped lang="scss">
    .actions-panel {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;
        &.space-between {
            justify-content: space-between;
            @media($tabletS){
                flex-direction: column;
                .documents-filter-form,
                .button {
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 10px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
        &:last-child {
            margin-bottom: 0;
        }

        @media($mobileM){
            margin-bottom: 25px;
            .button {
                width: 100%;
            }
        }
    }
</style>