<template>
    <div class="product-boxes-container" :class="extraClasses">
        <product-box v-for="item in products" :key="item.id" :data="item"/>
    </div>
</template>

<script>
    import ProductBox from "./ProductBox";

    export default {
        name: "ProductBoxesContainer",
        props: ['products', 'columns'],
        components: {
            ProductBox
        },
        data() {
            return {

            }
        },
        computed: {
            extraClasses(){
                let extraClasses = [];

                if (this.$store.state.app.native){
                    extraClasses.push('with-scroll');
                }

                if (this.columns === 4){
                    extraClasses.push('four-columns');
                }

                return extraClasses;
            },
        },
        mounted() {

        }
    }
</script>

<style scoped lang="scss">
    .product-boxes-container.four-columns {
        @media(min-width: 992px){
            justify-content: flex-start;
            .product-box {
                width: calc(25% - (60px / 4));
                margin-right: 20px;
                &:nth-child(4n + 4){
                    margin-right: 0;
                }
                &:nth-child(n + 3){
                    margin-top: 0;
                }
                &:nth-child(n + 5){
                    margin-top: 20px;
                }
            }
        }
    }

    .product-boxes-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .product-box {
            width: calc(50% - 10px);

            &:nth-child(n + 3) {
                margin-top: 20px;
            }
        }

        @media($mobileM) {
            .product-box {
                width: 100%;

                &:nth-child(n +2) {
                    margin-top: 20px;
                }
            }
        }

        &.with-scroll {
            @media($mobileM){
                padding: 0 15px;
                margin-left: -15px;
                margin-right: -15px;
                overflow-x: auto;
                flex-wrap: nowrap;
                .product-box {
                    width: 80%;
                    min-width: 80%;
                    margin-right: 20px;
                    margin-top: 0;
                    min-height: auto;
                    &:last-child {
                        margin-top: 0;
                        margin-right: 0;
                    }
                }
            }
        }
    }
</style>