<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments_eic.title') }}</h2>
				</header>
				<article>
					<actions-panel>
						<router-link class="button style-1" :to="$t('routes.investments.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
					</actions-panel>
					<panel-boxes-container :data="accountsData"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import ActionsPanel from "@/components/panels/ActionsPanel";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";

export default {
	name: "InvestmentsEic",
	components: {
		ActionsPanel,
		PanelBoxesContainer
	},
	computed: {
		disclaimer(){
			const date = new Date().toLocaleDateString('en-GB').split('/').join('.');
			return `Hodnoty platné k ${date} 9:00`;
		},
		investmentsData(){
			return this.$store.state.views.investments;
		},
		accountsData(){
			let data = [];
			if (!this.investmentsData){
				return data;
			}

			this.investmentsData.accounts_eic.forEach(item => {
				data.push({
					id: item.accId,
					name: item.accId,
					description: this.$t('investments.account_balance'),
					category: 'EIC',
					price: this.getAccountTotal(item.portfolio),
					detailUrl: `${this.$t('routes.investments_eic.path')}/${item.accId}`,
					redirectDetail: true,
				});
			});

			return data;
		}
	},
	methods: {
		getAccountTotal(portoflio) {
			let total = 0;
			if (!portoflio){
				return total;
			}

			let validTypes = [
				'Standard',
				'Blocked',
				'Blokované'
			];
			portoflio.forEach(item => {
				if(validTypes.includes(item.type)){
					total += item.refTotalAmount;
				}
			});

			return total.toFixed(2);
		}
	}
}
</script>

<style scoped lang="scss">
	td {
		padding: 5px 10px 5px 0;
	}
</style>