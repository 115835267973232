<template>
    <default-sidebar :active="$store.state.sidebars.showPortfolioDetail">
        <portfolio-form :data="$store.state.sidebars.showPortfolioDetail" v-if="$store.state.sidebars.showPortfolioDetail"/>
    </default-sidebar>
</template>

<script>
	import DefaultSidebar from "@/components/sidebars/DefaultSidebar";
	import PortfolioForm from "@/components/forms/PortfolioForm";
    export default {
        name: "PortfolioSidebar",
        components: {
			DefaultSidebar,
	        PortfolioForm
        },
        data() {
            return {

            }
        },
    }
</script>

<style scoped lang="scss">

</style>