<template>
	<form class="mobility-form" @submit.prevent="submitForm">
		<p>{{ $t('mobility.form_text') }}</p>
		<errors-list :errors="errors"/>
		<div class="form-row two-columns">
			<default-field-box type="text" name="first_name" :placeholder="$t('forms.first_name')"
			                   :value="firstName" :required="true"/>
			<default-field-box type="text" name="last_name" :placeholder="$t('forms.last_name')"
			                   :value="lastName" :required="true"/>
		</div>
		<div class="form-row two-columns">
			<default-field-box type="email" name="email" :placeholder="$t('forms.email')"
			                   :value="email" :required="true"/>
			<default-field-box type="tel" name="phone" :placeholder="$t('forms.phone')"
			                   :value="phone" :required="true"/>
		</div>
		<div class="form-row">
			<default-field-box type="textarea" name="note" :placeholder="$t('forms.note')"/>
		</div>
		<div class="form-actions">
			<router-link :to="$t('routes.services.path')" class="button style-1"><span class="inner">{{ $t('mobility.back') }}</span>
			</router-link>
			<button type="submit" class="button style-4" :class="loading ? 'loading' : ''">
				<span class="inner">{{ $t('forms.submit') }}</span>
			</button>
		</div>
	</form>
</template>

<script>
	import DefaultFieldBox from "../form-fields/DefaultFieldBox";
	import ErrorsList from "../containers/ErrorsList";

	export default {
		name: "MobilityForm",
		props: ['mobilityId'],
		components: {
			DefaultFieldBox,
			ErrorsList
		},
		data() {
			return {
				errors: false,
				loading: false,
				firstName: this.$store.state.auth.user.first_name,
				lastName: this.$store.state.auth.user.last_name,
				email: this.$store.state.auth.user.email,
				phone: this.$store.state.auth.user.phone,
			}
		},
		methods: {
			submitForm(e) {
				if (this.loading) {
					return false;
				}

				let form = e.target;
				let formData = new FormData(form);
				formData.append('mobility_id', this.mobilityId);
				this.loading = true;

				this.axios.post('/api/mobility/send-lead', formData).then(() => {
					this.$swal.fire({
						icon: 'success',
						title: this.$t('general.thank_you'),
						text: this.$t('general.contact_soon')
					});
					form.reset();
					this.loading = false;
				}).catch(error => {
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}

					this.loading = false;
				});
			}
		}

	}
</script>

<style scoped lang="scss">
	.errors-list {
		text-align: left;
	}

	.form-actions {
		display: flex;
		justify-content: space-between;

		.button {
			width: calc(50% - 10px);
			max-width: 320px;
		}

		@media($tabletM) {
			flex-direction: column-reverse;
			.button {
				width: 100%;
				max-width: initial;
				margin-bottom: 10px;

				&:first-child {
					margin-bottom: 0;
				}
			}
		}
	}
</style>