<template>
    <main class="app-main">
        <div class="content-wrap">
            <section class="content-section">
                <article>
                    <product-detail-box :data="product" v-if="product"/>
                    <product-popup v-if="$store.state.popups.showProductPopup" :data="$store.state.popups.showProductPopup"/>
                </article>
            </section>
        </div>
    </main>
</template>

<script>
    import ProductDetailBox from "../components/containers/ProductDetailBox";
    import ProductPopup from "../components/popups/ProductPopup";

    export default {
        name: "ProductDetail",
        components: {
            ProductDetailBox,
            ProductPopup,
        },
        data() {
            return {
                product: this.$store.state.views.productDetail,
            }
        },
        methods: {
            getProduct(){
                //this.$store.dispatch('getProduct', this.$route.params.id).then(product => this.product = product);
            }
        },
        created() {
            this.getProduct();
        }
    }
</script>

<style scoped lang="scss">

</style>