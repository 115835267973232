<template>
	<default-popup>
		<div class="content">
			<h2 class="popup-title">{{ $t('forms.enter_pin') }}</h2>
			<pin-field name="pin" :loading="loading"/>
			<div class="actions">
				<button class="button style-1" @click.prevent="loginWithPassword">
					<span class="inner">{{ $t('auth.password_log_in') }}</span>
				</button>
			</div>
		</div>
	</default-popup>
</template>

<script>
	import {Device} from '@capacitor/device';
	import {Storage} from '@capacitor/storage';
	import DefaultPopup from "./DefaultPopup";
	import PinField from "../form-fields/PinField";

	export default {
		name: "SetupPinPopup",
		props: ['biometric', 'deviceId'],
		components: {
			DefaultPopup,
			PinField,
		},
		data() {
			return {
				loading: false,
				attempt: 0,
			}
		},
		methods: {
			loginWithPassword() {
				this.$store.dispatch('closePopups');
				this.$root.$emit('login-with-password');
			},
			login(pin) {
				this.loading = true;
				let formData = new FormData();
				formData.append('device_id', this.deviceId);
				formData.append('pin', pin);
				formData.append('type', 'pin');

				this.$store.dispatch('nativeLogin', formData).catch(() => {
					this.loading = false;
					this.$root.$emit('pin-value-reset');
					this.attempt++;
					if (this.attempt === 3) {
						this.loginWithPassword();
					}
				});
			}
		},
		mounted() {
			this.$root.$on('pin-value-changed', (data) => {
				this.login(data.value);
			});
		},
		destroyed() {
			this.$root.$off('pin-value-changed');
		}
	}
</script>

<style scoped lang="scss">
	.default-popup {
		.popup-title {
			font-size: 26px;
			margin-bottom: 25px;
			min-height: 62px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.content {
		height: 100%;
		display: flex;
		flex-direction: column;
	}

	.actions {
		margin-top: auto;
	}

	.pin-field {
		margin-bottom: 20px;
	}
</style>