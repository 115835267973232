<template>
	<div class="form-group">
		<label v-if="label">{{ label }}</label>
		<ul class="file-preview-list">
			<li v-for="(file, index) in files" :key="index">
				<a :href="file.url" target="_blank" class="file-preview-item"
				   :class="loading.includes(file.url) ? 'loading' : ''"
				   @click.prevent="downloadFile(file)">
					<i class="fa-thin fa-file"></i>{{ file.filename }}
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
import { Directory, Filesystem } from "@capacitor/filesystem";
import {FileOpener} from "@capacitor-community/file-opener";

export default {
	name: "FilePreview",
	props: ['label', 'files'],
	data() {
		return {
			loading: [],
		}
	},
	computed: {
		appUrl() {
			return `${process.env.VUE_APP_PROTOCOL}://${process.env.VUE_APP_HOST_NAME}/api/`;
		},
	},
	methods: {
		downloadFile(data) {
			if (this.loading.includes(data.url)) {
				return;
			}

			this.loading.push(data.url);
			this.axios.get(this.appUrl + data.url, {
				responseType: 'blob'
			}).then(response => {
				if (this.$store.state.app.native) {
					this.handleNativeDownload(response, data);
				} else {
					this.handleWebDownload(response, data);
				}
			}).catch(() => {
				this.$swal.fire({
					icon: 'error',
					title: 'Oops',
					text: this.$t('general.default_error')
				});
				this.loading = this.loading.filter(item => item !== data.url);
			});
		},
		async handleNativeDownload(response, data) {
			let fileData = await this.blobToBase64(new Blob([response.data]));

			const file = await Filesystem.writeFile({
				path: data.filename,
				data: fileData,
				directory: Directory.Cache,
				//encoding: Encoding.UTF8,
			});

			FileOpener.open({
				filePath: file.uri,
				contentType: this.data.mime_type,
				openWithDefault: true,
			}).then(() => {
				this.loading = false;
			}).catch(error => {
				console.log(error);
			});
		},
		handleWebDownload(response, data) {
			let url = window.URL.createObjectURL(new Blob([response.data]));
			let link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', data.filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			this.loading = this.loading.filter(item => item !== data.url);
		},
    blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
	}
}
</script>

<style scoped lang="scss">
.file-preview-item {
	display: flex;
	align-items: center;
	font-size: 14px;
	color: var(--font-color, $fontColor);

	&:hover {
		color: var(--primary-color, $primaryColor);
	}

	i {
		display: inline-block;
		width: 14px;
		text-align: center;
		margin-right: 10px;
		color: var(--primary-color, $primaryColor);
	}

	&.loading {
		i {
			&:before {
				content: "\f3f4";
				animation: spinning_animation 1s infinite;
				animation-timing-function: linear;
				display: inline-block;
			}
		}
	}
}

label {
	font-size: 14px;
	font-weight: 600;
}
</style>