<template>
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
	     x="0px" y="0px" viewBox="0 0 621.8 203.8" style="enable-background:new 0 0 621.8 203.8;" xml:space="preserve">
		<path class="st0"
		      d="M33.4,75.1c0,9.2-7.5,16.7-16.7,16.7S0,84.4,0,75.1c0-9.2,7.5-16.7,16.7-16.7S33.4,65.9,33.4,75.1"/>
		<rect x="2" y="100.3" class="st0" width="29.6" height="101.3"/>
		<path class="st0" d="M286.1,152.4v49.2h-27.9l-0.6-11.1c-7.9,8.5-19.4,13.3-32.7,13.3c-29.9,0-53-21.3-53-52.5
				c0-31.6,23.8-54.2,57.1-54.2S286.1,119.7,286.1,152.4 M255.3,150.7c0-16.5-10.9-27.4-26.4-27.4c-15.3,0-26.3,10.9-26.3,27.2
				c0,16.5,11.1,27.2,26.4,27.2C244.4,177.7,255.3,167,255.3,150.7"/>
		<path class="st0" d="M326.1,201.6h-29.6v-51.8c0-32.1,20.8-52.7,50.4-52.7c29.8,0,50.6,20.6,50.6,52.7v51.8h-29.6v-56.1
				c0-13.6-8.4-23.1-21-23.1c-12.2,0-20.8,9.5-20.8,23.1V201.6z"/>
		<path class="st0" d="M550.4,201.6h-29.6v-51.8c0-32.1,20.8-52.7,50.4-52.7c29.8,0,50.6,20.6,50.6,52.7v51.8h-29.6v-56.1
				c0-13.6-8.4-23.1-21-23.1c-12.2,0-20.8,9.5-20.8,23.1V201.6z"/>
		<path class="st0" d="M105.3,35.9c-31.3,0-54.8,20.5-54.8,47.7c0,32.6,26.9,41.1,46.6,47.3c0.9,0.3,1.9,0.6,2.8,0.9
				c15.5,4.7,28.9,8.8,28.9,20.1c0,9.3-6.9,19.3-22.1,19.3c-13.6,0-25.3-7.3-33.1-20.6l-1.2-2.1l-30.6,24.2l1.4,1.6
				c17.6,20,37.8,29.3,63.7,29.3c16.9,0,31.4-5.5,42-15.9c9.5-9.4,15-22.4,15-35.6c0-14.4-5.1-25.5-15.7-34
				c-9.2-7.4-21.2-11.7-31.8-15.1c-1.1-0.3-2.1-0.7-3.2-1c-15.6-5.1-27.9-9.1-27.9-19.5c0-9.9,7.7-16,20.2-16
				c11.7,0,20.6,4.7,27.1,14.3l1.2,1.8l28-21.3l-1.6-1.7C148.8,47.9,133.4,35.9,105.3,35.9"/>
		<g>
				<defs>
					<path id="SVGID_4_" d="M444.8,150.2c0,7.9,6.4,14.3,14.3,14.3c7.9,0,14.3-6.4,14.3-14.3s-6.4-14.3-14.3-14.3
						C451.3,135.8,444.8,142.2,444.8,150.2 M428.8,147.4c1.2-14.8,13.1-26.8,27.9-27.9c18.8-1.5,34.4,14.1,32.9,32.9
						c-1.2,14.8-13.1,26.8-27.9,27.9c-0.8,0.1-1.7,0.1-2.5,0.1C441.5,180.4,427.3,165.4,428.8,147.4 M405.7,151.9
						c0.9,28,23.7,50.8,51.7,51.7c30.9,1,56.2-24.3,55.2-55.2c-0.9-28-23.7-50.8-51.7-51.7c-0.6,0-1.2,0-1.8,0
						C429,96.6,404.7,121.6,405.7,151.9 M447.7,88.7c9.1,5.2,20.7,2.1,26-7l6.2-10.7l-16.5-9.5L447.7,88.7z"/>
				</defs>
			<clipPath id="SVGID_1_">
					<use xlink:href="#SVGID_4_" style="overflow:visible;"/>
				</clipPath>

			<linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-188.2168" y1="601.1566"
			                x2="-187.2168" y2="601.1566"
			                gradientTransform="matrix(107.0285 0 0 -107.0285 20550.2148 64473.9961)">
						<stop offset="0" class="primary-color"/>
						<stop offset="1" class="end-gradient-color"/>
				</linearGradient>
			<rect x="404.7" y="61.6" class="st1" width="108.9" height="143.1"/>
				</g>
		<polygon class="st0" points="90.8,0 79.6,0 85.2,9.8 "/>
		<path class="st0" d="M112.3,0l-5.1,8.8c-0.1,0.2-0.2,0.4-0.4,0.6l-0.5,0.9l0-0.1c-3.5,4.9-9,7.7-14.8,7.8v0H90l0.5,0.9
				c7,12.1,24.5,12.1,31.5,0L133,0H112.3z"/>
	</svg>
</template>

<script>
	export default {
		name: "IsanonLogo"
	}
</script>

<style scoped lang="scss">
	.st0 {
		fill: $whiteColor;
	}

	.st1 {
		clip-path: url(#SVGID_1_);
		fill: url(#SVGID_2_);
	}

	.light-mode {
		.logo {
			polygon,
			rect:not(.st1),
			path:not(.dot),
			text,
			.symbol {
				fill: var(--background-color, $backgroundColor);
			}
		}

		.st0 {
			fill: $backgroundColor;
		}
	}

	.public-footer .logo,
	.public-header .logo {
		path:not(.dot),
		text,
		.symbol {
			fill: var(--background-color, $backgroundColor);
		}

		.st0 {
			fill: $backgroundColor;
		}
	}

	.logo {
		.primary-color {
			stop-color: var(--primary-color, $primaryColor);
		}

		.end-gradient-color {
			stop-color: var(--end-gradient-color, $endGradientColor);
		}
	}
</style>