<template>
	<div class="panel-containers-group">
		<div class="panel-container-group" v-for="(item, index) in data" :key="index" v-if="item.data.length">
			<header class="panel-container-header" v-if="item.title">
				<h3 class="panel-container-title">{{ item.title }}</h3>
			</header>
			<panel-boxes-container :data="item.data"/>
		</div>
	</div>
</template>

<script>
	import PanelBoxesContainer from "./PanelBoxesContainer";
	export default {
		name: "PanelContainersGroup",
		props: ['data'],
		components: {
			PanelBoxesContainer
		}
	}
</script>

<style scoped lang="scss">
	.panel-container-group {
		margin-bottom: 50px;
		&:last-child {
			margin-bottom: 0;
		}
		@media($mobileM){
			margin-bottom: 25px;
		}
	}
	.panel-container-header {
		margin-bottom: 20px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.panel-container-title {
		font-size: 21px;
		margin-bottom: 25px;
		text-align: center;
		&:last-child {
			margin-bottom: 0;
		}
	}
</style>