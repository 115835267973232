<template>
	<div class="default-popup" :class="extraClass" @click="closePopup"
	     :style="`padding-top:${sidebarSafeAreaPadding}px`">
		<div class="logo-box" :style="`padding-top:${sidebarSafeAreaPadding}px`">
			<app-logo/>
		</div>
		<div class="popup-actions" v-if="!disableClose" :style="`padding-top:${sidebarSafeAreaPadding}px`">
			<button type="button" class="close-popup" @click="closePopup"><i class="fa-thin fa-x"></i></button>
		</div>
		<div class="popup-inner" :style="`margin-top:${sidebarSafeAreaPadding}px`" @click.stop>
			<slot/>
		</div>
	</div>
</template>

<script>
	import AppLogo from "../partials/AppLogo";

	export default {
		name: "DefaultPopup",
		props: ['extraClass', 'disableClose'],
		components: {
			AppLogo
		},
		methods: {
			closePopup() {
				if (this.disableClose) {
					return false;
				}

				this.$store.dispatch('closePopups');
			}
		},
		computed: {
			sidebarSafeAreaPadding() {
				return this.$store.state.app.safeArea.top;
			}
		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.default-popup {
			background: rgba(240, 240, 242, .95);

			.close-popup {
				color: var(--background-color, $backgroundColor);
			}

			&.no-transparent {
				background: #f0f0f2;
			}

			@media($mobileM) {
				background: #f0f0f2;
			}
		}
	}

	.default-popup.complete-registration-popup {
		background: var(--dark-background-color, $darkBackgroundColor);
		.popup-inner {
			border-radius: 0;
		}
	}

	.default-popup {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		//background: transparentize($darkBackgroundColor, .05);
		background: rgba(var(--dark-background-color-rgb), .95);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		z-index: 1002;

		&.no-transparent {
			background: var(--dark-background-color, $darkBackgroundColor);
		}

		.logo-box {
			position: absolute;
			left: 0;
			right: 0;
			top: 25px;
			display: flex;
			justify-content: center;
		}

		.popup-actions {
			position: absolute;
			right: 25px;
			top: 30px;
		}

		.popup-header {
			text-align: center;
			color: var(--white-color, $whiteColor);
			margin-bottom: 35px;

			&:last-child {
				margin-bottom: 0;
			}

			p {
				font-size: 18px;
				font-weight: 700;

			}
		}

		.popup-title {
			font-weight: 700;
			font-size: 36px;
			margin: 0 0 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.close-popup {
			background: transparent;
			color: var(--white-color, $whiteColor);
			transition: $animationDefaultDuration;
			border: none;
			cursor: pointer;

			&:hover {
				color: var(--primary-color, $primaryColor);
			}
		}
	}

	.button {
		max-width: 320px;
	}

	.complete-registration-popup .popup-inner,
	.user-activation-popup .popup-inner,
	.ocr-popup .popup-inner {
		max-width: 750px;
	}

	.popup-inner {
		max-height: calc(100vh - 60px);
		border-radius: 10px;
		overflow: auto;
		text-align: center;
		max-width: 480px;

		.icon {
			font-size: 129px;
			color: var(--primary-color, $primaryColor);
			margin: 0 0 50px;
			line-height: 1;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.default-popup-title {
			font-size: 36px;
			margin: 0 0 50px;

			&:last-child {
				margin-bottom: 0;
			}

			@media($tabletS) {
				font-size: 30px;
			}
		}

		form {
			max-width: 320px;
			margin: 0 auto;

			.form-row {
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}

			.form-actions {
				margin-top: 0;
			}
		}


		.actions {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.button {
				width: 100%;
				max-width: 320px;
				margin-bottom: 20px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		&::-webkit-scrollbar {
			width: 5px;
			background: transparent;
		}

		&::-webkit-scrollbar-thumb {
			background: var(--primary-color, $primaryColor);
		}

		&::-webkit-scrollbar-button {
			width: 0;
			height: 0;
			display: none;
		}

		&::-webkit-scrollbar-corner {
			background-color: transparent;
			display: none;
		}
	}

	@media($tabletL) {
		.popup-inner {
			max-height: calc(100vh - 115px);
			width: calc(100% - 60px);
		}
		.default-popup.login-popup {
			.popup-inner {
				width: 90%;
			}
		}

		.default-popup.registration-popup {
			.popup-inner {
				width: 90%;
			}
		}
	}

	@media($mobileM) {
		.default-popup {
			background: var(--background-color, $backgroundColor);
		}
		.popup-inner {
			width: 100%;
			padding: 50px 15px;
			max-height: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}

	@media($mobileS) {
		.popup-inner {
			padding: 55px 15px 40px;

			.icon {
				font-size: 100px;
				margin-bottom: 25px;
			}
		}

		.default-popup {
			.logo-box {
				top: 15px;
			}

			.popup-actions {
				top: 20px;
			}

			.default-popup-title {
				font-size: 26px;
				margin-bottom: 25px;
			}

			.popup-header {
				p {
					font-size: 16px;
				}
			}
		}

	}
</style>