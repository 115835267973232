<template>
	<div class="color-picker">
		<label class="field-label">{{ label }}</label>
		<div class="colors-container">
			<label v-for="(color, index) in colors" :key="index" class="color-item">
				<input type="radio" :name="name" :value="color" :checked="selected === color" @change="changeValue">
				<div class="color" :style="`background: ${color};`"></div>
			</label>
		</div>
	</div>
</template>

<script>
	export default {
		name: "ColorPicker",
		props: ['colors', 'name', 'selected', 'label'],
		methods: {
			changeValue(e){
				this.$root.$emit('field-changed', {
					name: this.name,
					value: e.target.value
				});
			}
		},
		mounted() {

		}
	}
</script>

<style scoped lang="scss">
	.light-mode {
		.color-item {
			.color {
				&:before {
					border-color: var(--background-color, $backgroundColor);
				}
			}
		}
	}
	.field-label {
		font-size: 10px;
		display: block;
		margin-bottom: 0;
		font-weight: 400;
		padding-left: 15px;
	}
	.colors-container {
		display: flex;
		flex-wrap: wrap;
		padding: 6px 0;
	}
	.color-item {
		display: block;
		cursor: pointer;
		margin: 5px;
		width: calc(100% / 7 - 10px);
		position: relative;

		input {
			display: none;
			&:checked + .color:before {
				opacity: 1;
			}
		}
		.color {
			width: 100%;
			height: 30px;
			border-radius: 2px;
			&:before {
				content: '';
				left: -3px;
				right: -3px;
				top: -3px;
				bottom: -3px;
				border: 1px solid var(--white-color, $whiteColor);
				position: absolute;
				display: block;
				border-radius: 3px;
				opacity: 0;
			}
		}
	}

</style>