<template>
	<fieldset :data-group-name="replaceRepeaterIndex(section.name)"
	          v-if="!section.hidden_section || showHiddenSections.includes(replaceRepeaterIndex(section.name))">
		<legend v-if="section.title">{{ section.title }}</legend>
		<div class="form-row" :class="getRowClass(row)" v-for="(row, rowIndex) in section.fields"
		     :key="rowIndex">
			<template v-for="(field, fieldIndex) in row"
			          v-if="!field.hidden_field || showHiddenFields.includes(field.name)">
				<p class="text" v-if="field.type === 'paragraph'">{{ field.value }}</p>
				<file-preview v-else-if="field.type === 'file_preview'" :label="field.label"
				              :files="field.files"/>
				<checkbox-switcher v-else-if="field.type === 'switcher'"
				                   :label="field.label"
				                   :name="getFieldName(field, section, page)"
				                   extra-class="column-style"
				                   @change="toggleWithSwitcher($event, field)"/>
				<choice-field v-else-if="field.type === 'radio' || field.type === 'checkbox'"
				              :label="field.label"
				              :name="getFieldName(field, section, page)"
				              :type="field.type"
				              :options="field.options"
				              :extra-class="errorFields.includes(getFieldName(field, section, page, false)) ? 'error' : ''"
				              @change="toggleWithChoice($event, field)"/>
				<default-field-box v-else
				                   :type="field.type"
				                   :name="getFieldName(field, section, page)"
				                   :options="field.options"
				                   :placeholder="field.placeholder"
				                   :label="field.label"
				                   :readonly="field.readonly === true && !enableReadonlyFields.includes(field.name)"
				                   :value="field.value"
				                   :extra-class="errorFields.includes(getFieldName(field, section, page)) ? 'error' : ''"
				                   :key="fieldIndex"/>
			</template>
		</div>
	</fieldset>
</template>

<script>
import FilePreview from "@/components/form-fields/FilePreview";
import CheckboxSwitcher from "@/components/form-fields/CheckboxSwitcher";
import ChoiceField from "@/components/form-fields/ChoiceField";
import DefaultFieldBox from "@/components/form-fields/DefaultFieldBox";

export default {
	name: "DynamicFormFieldset",
	components: {
		FilePreview,
		CheckboxSwitcher,
		ChoiceField,
		DefaultFieldBox
	},
	props: ['section', 'page', 'errorFields', 'repeaterIndex', 'showHiddenSections', 'showHiddenFields', 'enableReadonlyFields'],
	methods: {
		replaceRepeaterIndex(item){
			if (!this.page.type || this.page.type !== 'repeater'){
				return item;
			}

			return item.replace('{i}', this.repeaterIndex);
		},
		getRowClass(row) {
			if (!row.length || row.length === 1) {
				return '';
			}

			let words = [
				'two',
				'three',
				'four',
				'five',
				'six'
			];

			return `${words[row.length - 2]}-columns`;
		},
		getFieldName(field, section, page, checkboxArray = true) {
			let name;
			if (!section.fields_group) {
				name = field.name;
			} else {
				let group = section.fields_group.split('.').map((item, index) => {
					if (index === 0) {
						return item;
					}

					return `[${item}]`;
				});

				name = `${group.join('')}[${field.name}]`;
			}

			if (field.type === 'checkbox' && field.options.length > 1 && checkboxArray) {
				name = `${name}[]`;
			}

			name = this.replaceRepeaterIndex(name);

			return name;
		},
		emitDynamicItem(value, type, method){
			this.$emit('toggle-dynamic-item', {
				value: this.replaceRepeaterIndex(value),
				type,
				method
			});
		},
		toggleWithSwitcher(event, field) {
			if (!field.toggle_section && !field.toggle_field) {
				return;
			}

			if (field.toggle_section) {
				field.toggle_section.forEach(section => {
					if (event.value === false) {
						//this.showHiddenSections = this.showHiddenSections.filter((item) => item !== section);
						this.emitDynamicItem(section, 'section', 'remove');
					} else {
						//this.showHiddenSections.push(section);
						this.emitDynamicItem(section, 'section', 'add');
					}
				});
			}
		},
		toggleWithChoice(event, field) {
			if (!field.toggle_section && !field.toggle_field && !field.toggle_readonly) {
				return;
			}

			if (field.toggle_field) {
				let checkedOption = field.options.find(item => event.value === item.value);
				field.toggle_field.forEach(item => {
					if (!checkedOption.toggle_field) {
						//this.showHiddenFields = this.showHiddenFields.filter((fieldName) => fieldName !== item);
						this.emitDynamicItem(item, 'field', 'remove');
					} else {
						if (checkedOption.toggle_field.includes(item)) {
							//this.showHiddenFields.push(item);
							this.emitDynamicItem(item, 'field', 'add');
						} else {
							//this.showHiddenFields = this.showHiddenFields.filter((fieldName) => fieldName !== item);
							this.emitDynamicItem(item, 'field', 'remove');
						}
					}
				});
			}

			if (field.toggle_readonly) {
				let checkedOption = field.options.find(item => event.value === item.value);
				field.toggle_readonly.forEach(item => {
					if (!checkedOption.toggle_readonly) {
						//this.enableReadonlyFields = this.enableReadonlyFields.filter((fieldName) => fieldName !== item);
						this.emitDynamicItem(item, 'readonly', 'remove');
					} else {
						if (checkedOption.toggle_readonly.includes(item)) {
							//this.enableReadonlyFields.push(item);
							this.emitDynamicItem(item, 'readonly', 'add');
						} else {
							//this.enableReadonlyFields = this.enableReadonlyFields.filter((fieldName) => fieldName !== item);
							this.emitDynamicItem(item, 'readonly', 'remove');
						}
					}
				});
			}

			if (field.toggle_section) {
				let checkedOption = field.options.find(item => event.value === item.value);
				field.toggle_section.forEach(item => {
					if (!checkedOption.toggle_section) {
						//this.showHiddenSections = this.showHiddenSections.filter((fieldName) => fieldName !== item);
						this.emitDynamicItem(item, 'section', 'remove');
					} else {
						if (checkedOption.toggle_section.includes(item)) {
							//this.showHiddenSections.push(item);
							this.emitDynamicItem(item, 'section', 'add');
						} else {
							//this.showHiddenSections = this.showHiddenSections.filter((fieldName) => fieldName !== item);
							this.emitDynamicItem(item, 'section', 'remove');
						}
					}
				});
			}
		}
	}
}
</script>

<style scoped lang="scss">

p.text {
	font-size: 14px;
}

fieldset {
	margin: 0 0 25px;
	display: block;
	padding: 0;
	border: none;

	&:last-child {
		margin-bottom: 0;
	}

	legend {
		display: block;
		margin-bottom: 20px;
		color: var(--white-color);
		font-size: 21px;
		text-align: center;
		font-weight: 600;

		&:last-child {
			margin-bottom: 0;
		}
	}
}
</style>