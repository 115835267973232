<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $store.state.views.currentDocumentCategory.name }}</h2>
				</header>
				<article>
					<actions-panel extra-class="space-between">
						<router-link class="button style-1" :to="$t('routes.documents.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
						<documents-filter-form :category="false"/>
					</actions-panel>
					<panel-containers-group :data="allDocuments"
					                        v-if="allDocuments[0].data.length || allDocuments[1].data.length"/>
					<p class="no-data" v-else>{{ $t('documents.no_documents_in_category') }}</p>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
	import ActionsPanel from "../components/panels/ActionsPanel";
	import PanelContainersGroup from "../components/containers/PanelContainersGroup";
	import DocumentsFilterForm from "../components/forms/DocumentsFilterForm";

	export default {
		name: "DocumentCategory",
		components: {
			ActionsPanel,
			PanelContainersGroup,
			DocumentsFilterForm
		},
		data() {
			return {
				category: null,
				intranet_user: '',
			}
		},
		computed: {
			allDocuments() {
				let allDocuments = [
					{
						title: this.$t('documents.internal_documents'),
						data: this.contracts
					},
					{
						title: this.$t('documents.external_documents'),
						data: this.documents
					}
				];

				return allDocuments;
			},
			contracts() {
				let contracts = [];
				this.$store.state.views.currentDocumentCategory.contracts.forEach(item => {
					if (this.intranet_user && this.intranet_user !== item.intranet_user){
						return;
					}
					let price =  item.total_payment || item.payment;
					let paymentFrequency;
					switch (item.payment_frequency) {
						case 'mesačne':
							paymentFrequency = this.$t('documents.payment_monthly');
							break;
						case 'ročne':
							paymentFrequency = this.$t('documents.payment_yearly');
							break;
						case 'jednorazovo':
							paymentFrequency = this.$t('documents.payment_once');
							break;
						default:
							paymentFrequency = item.payment_frequency;
							break;
					}

					let name;
					if (item.product && item.product.provider){
						name = item.product.provider.name;
					} else {
						name = item.name;
					}

					contracts.push({
						id: item.id,
						category: item.document_category ? item.document_category.name : '',
						name: name,
						date: this.$moment(item.created_at).format('D.M.YYYY'),
						description: paymentFrequency,
						price: price,
						detail: true,
						remove: false,
						type: 'contract',
						status: item.status,
					});
				});

				contracts.reverse();

				return contracts;
			},
			documents() {
				let documents = [];
				this.$store.state.views.currentDocumentCategory.documents.forEach(item => {
					let paymentFrequency;
					let price;
					if (item.payment_frequency) {
						switch (item.payment_frequency) {
							case 'monthly':
								paymentFrequency = this.$t('documents.payment_monthly');
								break;
							case 'quarterly':
								paymentFrequency = this.$t('documents.payment_quarterly');
								break;
							case 'yearly':
								paymentFrequency = this.$t('documents.payment_yearly');
								break;
							case 'once':
								paymentFrequency = this.$t('documents.payment_once');
								break;
							default:
								paymentFrequency = item.payment_frequency;
								break;
						}
						price = item.recurring_amount;
					} else {
						paymentFrequency = this.$t('documents.payment_once');
						price = item.total_amount;
					}

					documents.push({
						id: item.id,
						category: item.document_category ? item.document_category.name : '',
						name: item.name,
						date: this.$moment(item.created_at).format('D.M.YYYY'),
						description: paymentFrequency,
						price: price,
						//image: item.document_category ? item.document_category.image : '',
						detail: true,
						remove: true,
						type: 'document'
					});
				});

				documents.reverse();

				return documents;
			}
		},
		methods: {
			setIntranetUser(data){
				this.intranet_user = data.value;
			}
		},
		mounted() {
			this.$root.$on('field-changed', (data) => {
				if (data.name === 'intranet_user'){
					this.setIntranetUser(data);
				}
			});
		},
		destroyed() {
			this.$root.$off('field-changed');
		},
		created() {
			this.category = this.$route.params.category;
		}
	}
</script>

<style scoped>

</style>