<template>
    <div class="article-containers-group" :class="scrollClass ? 'with-scroll' : ''">
        <div class="featured-article">
            <article-box :data="featuredArticle"/>
        </div>
        <article-boxes-container :articles="topPartArticles"/>
        <article-boxes-container :articles="bottomPartArticles" :columns="4" v-if="bottomPartArticles.length"/>
    </div>
</template>

<script>
    import ArticleBox from "./ArticleBox";
    import ArticleBoxesContainer from "./ArticleBoxesContainer";

    export default {
        name: "ProductContainersGroup",
        props: ['featuredArticle', 'articles'],
        components: {
            ArticleBox,
            ArticleBoxesContainer
        },
        data() {
            return {
                width: window.innerWidth,
            }
        },
        computed: {
            topPartArticles(){
                if (this.width < 992){
                    return this.articles;
                }

                return this.articles.slice(0, 4);
            },
            bottomPartArticles(){
                if (this.width < 992){
                    return [];
                }
                return this.articles.slice(4);
            },
            scrollClass(){
                return !!this.$store.state.app.native;
            },
        },
    }
</script>

<style scoped lang="scss">
    .article-containers-group {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        .featured-article,
        .article-boxes-container {
            width: calc(50% - 10px);
        }
        .article-boxes-container.four-columns {
            margin-top: 20px;
            width: 100%;
        }
        .featured-article {
            display: flex;
            flex-direction: column;
            .article-box {
                flex-grow: 1;
            }
        }
        @media($desktopL){
            .featured-article,
            .article-boxes-container {
                width: calc(50% - 10px);
            }
        }
        @media($tabletL){
            flex-direction: column;
            .featured-article {
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .featured-article,
            .article-boxes-container {
                width: 100%;
            }
        }

        @media($mobileM){
            &.with-scroll {
                flex-direction: column;
                overflow-x: visible;
                padding: 0 15px;
                margin: 0 -15px;
                .featured-article {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 20px;
                    &:last-child {
                        margin-right: 0;
                    }
                    .article-box {
                        min-height: initial;
                        flex-grow: initial;
                    }
                }
                .article-boxes-container {
                    width: auto;
                    overflow-x: scroll;
                    min-width: calc(100% + 15px);
                    padding-right: 15px;
                    padding-left: 0;
                    margin: 0;
                }
            }
        }
    }
</style>