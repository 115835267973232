<template>
	<footer class="public-footer">
		<div class="content-wrap">
			<div class="container">
				<div class="left">
					<app-logo/>
					<p class="credits">{{ $t('general.credits') }} © 2018 - 2023</p>
				</div>
				<div class="right">
					<ul class="pages-list">
						<li>
							<router-link :to="$t('routes.general_terms.path')">{{ $t('routes.general_terms.title') }}</router-link>
						</li>
						<li>
							<router-link :to="$t('routes.privacy_policy.path')">{{ $t('routes.privacy_policy.title') }}</router-link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
	import AppLogo from "@/components/partials/AppLogo";

	export default {
		name: "PublicFooter",
		components: {
			AppLogo
		}
	}
</script>

<style scoped lang="scss">
	.public-footer {
		padding: 60px 0;
		background: var(--footer-color, $footerColor);
		box-shadow: 0 20px 40px transparentize($blackColor, .95) inset;

		.logo {
			width: 100px;
			bottom: 5px;
		}
	}

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.left {
		display: flex;
		align-items: center;
	}

	.credits {
		font-size: 14px;
		margin-left: 30px;
		padding-left: 30px;
		position: relative;
		color: var(--secondary-font-color, $secondaryFontColor);

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 1px;
			background: var(--background-color, $backgroundColor);
			opacity: .1;
		}
	}

	.pages-list {
		display: flex;
		font-size: 14px;

		a {
			color: var(--background-color, $backgroundColor);

			&:hover {
				color: var(--primary-color, $primaryColor);
			}
		}

		li + li {
			margin-left: 30px;
			padding-left: 30px;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				width: 1px;
				background: var(--background-color, $backgroundColor);
				opacity: .1;
			}
		}
	}

	@media($tabletL) {
		.container {
			flex-direction: column-reverse;

		}
		.right {
			margin-bottom: 10px;
		}
		.left {
			flex-direction: column-reverse;
		}
		.public-footer {
			.logo {
				bottom: 0;
			}
		}

		.credits {
			padding-left: 0;
			margin-left: 0;
			margin-bottom: 10px;
			text-align: center;

			&:before {
				content: none;
			}
		}
		.pages-list {
			flex-direction: column;
			text-align: center;

			li + li {
				margin-top: 10px;
				margin-left: 0;
				padding-left: 0;

				&:before {
					content: none;
				}
			}
		}
	}
</style>