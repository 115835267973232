<template>
	<div class="form-group" :class="extraClass">
		<label class="field-label" v-if="label">{{ label }}</label>
		<div class="options-group">
			<label :class="`${type}-box`" v-for="(option, index) in options" :key="index">
				<input :type="type" :name="name" :value="option.value" :checked="option.checked" @change="handleChange">
				<span class="status"></span>
				<span class="field-title">{{ option.title }}</span>
			</label>
		</div>
	</div>
</template>

<script>
export default {
	name: "ChoiceField",
	props: ['name', 'label', 'options', 'type', 'extraClass'],
	methods: {
		handleChange(e) {
			this.$emit('change', {
				name: this.name,
				value: e.target.value
			});
		}
	}
}
</script>

<style scoped lang="scss">
.error {
	.field-label {
		color: var(--error-color, $errorColor);
	}
}
.field-label {
	font-size: 14px;
	font-weight: 600;
}

.radio-box {
	.status {
		border-radius: 100%;

		&:before {
			border-radius: inherit;
		}
	}
}
</style>