<template>
	<section class="public-content-section">
		<article>
			<div class="content-wrap">
				<div class="container">
					<div class="slider-text-box">
						<div class="slider-box">
							<div class="slides">
								<figure class="image">
									<img :src="getLocalAsset(`img/slider-box/${slide}`)" alt=""
									     v-for="(slide, index) in slider.slides"
									     :key="index" :class="index === slider.currentSlide ? 'active' : ''">
								</figure>
							</div>
							<nav class="slider-nav">
								<ul class="slider-dots">
									<li v-for="(slide, index) in slider.slides">
										<a href="#" class="dot"
										   :class="index === slider.currentSlide ? 'active' : ''"
										   @click.prevent="changeSlide(index)"></a>
									</li>
								</ul>
							</nav>
						</div>
						<article class="text-box">
							<h3 class="public-pre-section-title">{{ $t('sections.slider.pre_title') }}</h3>
							<h3 class="public-section-title">{{ $t('sections.slider.title') }}</h3>
							<p>{{ $t('sections.slider.text') }}</p>
							<ul class="benefits-list">
								<li>{{ $t('sections.slider.benefits.benefit_1') }}</li>
								<li>{{ $t('sections.slider.benefits.benefit_2') }}</li>
								<li>{{ $t('sections.slider.benefits.benefit_3') }}</li>
								<li>{{ $t('sections.slider.benefits.benefit_4') }}</li>
							</ul>
						</article>
					</div>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";

	export default {
		name: "SliderSection",
		mixins: [pageFunctionsMixin],
		data() {
			return {
				slider: {
					currentSlide: 0,
					slides: [
						`slider-image-01-${this.$store.state.app.appType}.png`,
						`slider-image-02-${this.$store.state.app.appType}.png`,
						`slider-image-03-${this.$store.state.app.appType}.png`,
					],
					currentTimeout: null,
				}

			}
		},
		methods: {
			initSlider() {
				this.autoChangeSlide();
			},
			changeSlide(index){
				this.slider.currentSlide = index;
				window.clearTimeout(this.slider.currentTimeout);
				this.autoChangeSlide();
			},
			autoChangeSlide() {
				this.slider.currentTimeout = setTimeout(() => {
					this.slider.currentSlide++;
					if (this.slider.currentSlide === this.slider.slides.length) {
						this.slider.currentSlide = 0;
					}
					this.autoChangeSlide();
				}, 3000);
			},
		},
		mounted() {
			this.initSlider();
		}
	}
</script>

<style scoped lang="scss">
	.public-content-section {
		padding-bottom: 180px;
		@media($tabletL){
			padding-bottom: 100px;
		}
		@media($mobileM){
			padding-bottom: 60px;
		}
	}

	.slider-text-box {
		display: flex;
		align-items: center;
		.slider-box {
			width: 45%;
		}
		.text-box {
			width: 50%;
		}

		@media($tabletM){
			flex-direction: column-reverse;
			align-items: flex-start;
			.slider-box {
				width: 100%;
				max-width: 400px;
			}
			.text-box {
				width: 100%;
				margin-left: 0;
				margin-bottom: 50px;
				max-width: 450px;
			}
		}
	}

	.text-box {
		max-width: 580px;
		margin-left: 11%;

		p {
			color: var(--secondary-font-color, $secondaryFontColor);
		}
	}

	.benefits-list {
		text-align: left;
		li {
			position: relative;
			color: var(--background-color, $backgroundColor);
			display: flex;
			align-items: center;
			margin-bottom: 35px;
			font-size: 22px;
			font-weight: 600;
			line-height: 1.4;

			&:last-child {
				margin-bottom: 0;
			}

			&:before {
				content: "✓";
				display: flex;
				align-items: center;
				justify-content: center;
				width: 54px;
				min-width: 54px;
				height: 54px;
				border-radius: 50%;
				background: $whiteColor;
				color: $successColor;
				font-family: "Font Awesome 6 Pro";
				font-weight: 900;
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				font-style: normal;
				font-variant: normal;
				line-height: 1;
				text-rendering: auto;
				box-shadow: 0 20px 30px transparentize($blackColor, .95);
				margin-right: 35px;
			}

		}

		@media($tabletL){
			li {
				font-size: 18px;
			}
		}
	}

	.slider-box {
		position: relative;

		.image {
			border-radius: 15px;
			overflow: hidden;

			img {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				transition: opacity 1s;

				&.active {
					opacity: 1;
					position: relative;
				}
			}
		}

		.slider-nav {
			position: absolute;
			left: 15px;
			right: 15px;
			bottom: 45px;
			z-index: 10;

			ul {
				display: flex;
				align-items: center;
				justify-content: center;

				li + li {
					margin-left: 23px;
				}
			}
		}

		.dot {
			width: 9px;
			height: 9px;
			border: 1px solid $whiteColor;
			display: block;
			border-radius: 50%;
			transition: none;

			&.active {
				width: 15px;
				height: 15px;
				background: $whiteColor;
			}
		}
	}
</style>